import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { TextInputWithIcon } from "../../form/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Progress } from "antd";
import { ArrowForward } from "@material-ui/icons";
import {
  CloseIcon,
  AndroidIcon,
  IOSIcon,
  LoaderIcon,
  SuccessIcon,
} from "../../../assets";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../../config";
import PinModal from "../../modals/pinModal";
import { ultaPayload } from "../components/constant";

const schema = yup
  .object({
    bvn: yup
      .string()
      .matches(/.{11}/, {
        excludeEmptyString: true,
        message: "Must be 11 characters",
      })
      .max(11)
      .required("BVN is required"),
    nin: yup
      .string()
      .matches(/.{11}/, {
        excludeEmptyString: true,
        message: "Must be 11 characters",
      })
      .max(11)
      .required("NIN is required"),
  })
  .required();

export const ContactVerification = ({
  navigateForward,
  formValues,
  initialData,
}) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      bvn: initialData?.userBvnNumber,
      nin: initialData?.userNinNumber,
    },
    resolver: yupResolver(schema),
  });
  const [userData, setUserData] = useState({});
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [showpin, setShowpin] = useState(false);
  const [otp, setOtp] = useState();
  const [isEmail, setIsEmail] = useState(false);
  //   const authorization = JSON.parse(sessionStorage.getItem("token"));

  const getKycData = async () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'application/json',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    // Fetch KYC info
    const userId = JSON.parse(sessionStorage.getItem("userData")).id;
    axios
      .post(
        `${config.BASE_URL}/kyc-servicev2/api/v1/kyc/requirementData/${userId}`,
        {},
        header
      )
      .then((resp) => {
        const email1 = resp?.data?.data?.tier1?.filter(
          (e) => e?.requirementType === "EMAIL"
        )[0];
        const phone1 = resp?.data?.data?.tier1?.filter(
          (e) => e?.requirementType === "PHONE_NUMBER"
        )[0];
        setEmail(email1);
        setPhone(phone1);
      })
      .catch((err) => {});
  };
  const handleUpdateEmail = async () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'application/json',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    // Fetch KYC info
    const _email = JSON.parse(sessionStorage.getItem("userData")).email;
    const _phoneNumber = JSON.parse(
      sessionStorage.getItem("userData")
    ).phoneNumber;
    const businesId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
    axios
      .get(
        `${config.BASE_URL}/auth-service/api/v1/auth/resend-otp-mail/${_email}?businessId=${businesId}`,
        header
      )
      .then((resp) => {
        if (resp.data.status) {
          setShowpin(true);
        }
      })
      .catch((err) => {});
  };
  const handleUpdateEmailKyc = async () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'application/json',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    // Fetch KYC info
    axios
      .put(
        `${config.BASE_URL}/kyc-servicev2/api/v1/kyc/updateCustomerTierData/${email.id}/${email.customerId}?attachment=${email.verificationReference}&reference=${email.verificationReference}`,
        {},
        header
      )
      .then((resp) => {
        getKycData();
      })
      .catch((err) => {});
  };
  const handleUpdateEmailOtp = async () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'application/json',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    // Fetch KYC info
    const _email = JSON.parse(sessionStorage.getItem("userData")).email;
    const _phoneNumber = JSON.parse(
      sessionStorage.getItem("userData")
    ).phoneNumber;
    const businessId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
    axios
      .post(
        `${config.BASE_URL}/auth-service/api/v1/auth/verify-email`,
        {
          phoneOrEmail: _email,
          otp,
          businessId,
        },
        header
      )
      .then((resp) => {
        if (resp.data.status) {
          setShowpin(false);
          handleUpdateEmailKyc();
          setOtp("");
        }
        toast.success(resp.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleUpdatePhone = async () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'application/json',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    // Fetch KYC info
    //  const _email = JSON.parse(sessionStorage.getItem("userData")).email;
    const _phoneNumber = JSON.parse(
      sessionStorage.getItem("userData")
    ).phoneNumber;
    const businesId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
    axios
      .get(
        `${config.BASE_URL}/auth-service/api/v1/auth/resend-otp/${_phoneNumber}?businessId=${businesId}`,
        header
      )
      .then((resp) => {
        if (resp.data.status) {
          setShowpin(true);
        }
      })
      .catch((err) => {});
  };
  const handleUpdatePhoneKyc = async () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'application/json',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    // Fetch KYC info
    axios
      .put(
        `${config.BASE_URL}/kyc-servicev2/api/v1/kyc/updateCustomerTierData/${phone.id}/${phone.customerId}?attachment=${phone.verificationReference}&reference=${email.verificationReference}`,
        {},
        header
      )
      .then((resp) => {
        getKycData();
      })
      .catch((err) => {});
  };
  const handleUpdatePhoneOtp = async () => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'application/json',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    // Fetch KYC info
    const _email = JSON.parse(sessionStorage.getItem("userData")).email;
    const _phoneNumber = JSON.parse(
      sessionStorage.getItem("userData")
    ).phoneNumber;
    const businessId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
    axios
      .post(
        `${config.BASE_URL}/auth-service/api/v1/auth/verify-phone`,
        {
          phoneOrEmail: _phoneNumber,
          otp,
          businessId,
        },
        header
      )
      .then((resp) => {
        console.log({ rfff: resp.data });
        if (resp.data.status) {
          setShowpin(false);
          handleUpdatePhoneKyc();
          setOtp("");
        }
        toast.success(resp.data?.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const onSubmit = (data) => {
    const userInfo = JSON.parse(sessionStorage.getItem("userData"));
    // setUserData(userInfo);
    const payload = {
      firstName: formValues?.personalDetails?.firstName,
      surname: formValues?.personalDetails?.surname,
      dateOfBirth: formValues?.personalDetails?.dateOfBirth,
      gender: formValues?.personalDetails?.gender,
      email: formValues?.personalDetails?.email,
      phoneNumber: "234" + formValues?.personalDetails?.phoneNumber,
      country: formValues?.personalDetails?.country,
      state: formValues?.personalDetails?.state,
      lga: formValues?.personalDetails?.lga,
      city: formValues?.personalDetails?.city,
      streetAddress: formValues?.personalDetails?.streetAddress,
      userBvnNumber: data?.bvn,
      userNinNumber: data?.nin,
      approvalStatus: "PENDING",
      appLink: "",
      bvnApproved: "",
      createdAt: new Date(),
    };
    const sendPayload = {
      //   ...userInfo,
      ...ultaPayload,
      ...payload,
      profileId: userData?.profileSubscription?.profileId,
      merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
      draft: true,
    };
    navigateForward(payload);
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };

    axios
      .post(
        `${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`,
        sendPayload,
        header
      )
      .then((resp) => {
        toast.success("Data Stored successfully");
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };

  return (
    <Wrapper>
      {/* <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center" /> */}
      <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
            <h4 className="font-semibold text-lg text-gray-600">
              Contact Verification
            </h4>

            <table className="table-auto">
              <thead>
                <tr>
                  <th>Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-3">Phone Number Verification</td>
                  <td>
                    <div
                      onClick={() => {
                        // setShowAlertType('verifyPhone');
                        setIsEmail(false);
                        handleUpdatePhone();
                      }}
                      className={`action flex flex-row ${
                        phone?.status === "APPROVED" ? "btn disabled" : "btn"
                      } my-2 cursor-pointer`}
                    >
                      <ArrowForward />
                      <span>
                        {phone?.status === "APPROVED" ? "Verified" : "Verify"}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        phone?.status === "APPROVED"
                          ? "text-success px-2"
                          : "status-pending px-2"
                      }
                    >
                      <span>{phone?.status}</span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="p-3">Email Verification</td>
                  <td>
                    <div
                      onClick={() => {
                        setIsEmail(true);
                        handleUpdateEmail();
                        // setShowAlertType('verifyPhone');
                        // setShowAlert(true);
                      }}
                      style={{
                        pointerEvents:
                          email?.status === "APPROVED" ? "none" : "",
                      }}
                      className={`action cursor-pointer ${
                        email?.status === "APPROVED" ? "btn disabled" : "btn"
                      } my-2`}
                    >
                      <ArrowForward />
                      <span>
                        {email?.status === "APPROVED" ? "Verified" : "Verify"}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        email?.status === "APPROVED"
                          ? "text-success px-2"
                          : "status-pending px-2"
                      }
                    >
                      <span>{email?.status}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="p-3">NIN Verification</td>
                  <td>
                    <Controller
                      name="nin"
                      control={control}
                      render={({ field }) => (
                        <TextInputWithIcon
                          {...field}
                          type={"number"}
                          isRequired
                          label={"NIN Verification"}
                          onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                          placeholder={"21234567890"}
                          error={errors?.nin?.message}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Bank Verification Number(BVN)</td>
                  <td>
                    <Controller
                      name="bvn"
                      control={control}
                      render={({ field }) => (
                        <TextInputWithIcon
                          {...field}
                          type={"number"}
                          isRequired
                          label={"BVN Verification"}
                          onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                          placeholder={"22233344556"}
                          error={errors?.bvn?.message}
                        />
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            type="submit"
            className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm"
          >
            Save & Continue
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z"
                fill="white"
              />
            </svg>
          </button>
        </Form>
      </div>

      <StepperInfo>
        <div className="w-full col-span-2 flex flex-col items-center">
          <Flex vertical gap="small">
            <Progress
              strokeColor="#ff6700"
              showInfo={false}
              strokeLinecap="butt"
              type="circle"
              percent={40}
            />
            <h6>2 of 5 complete</h6>
          </Flex>

          <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
            <button className="absolute -right-3 -top-3">
              <CloseIcon />
            </button>
            <h5 className="text-xl font-semibold">Download our App</h5>
            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">
              Download our mobile application for better experience and to
              receive custom notifications in real time.{" "}
            </p>
            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
              <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                <button className="mx-2">
                  <AndroidIcon />
                </button>
                <button className="mx-2">
                  <IOSIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </StepperInfo>

      {showpin ? (
        <PinModal
          center
          showModal={showpin}
          hideModal={setShowpin}
          otp={otp}
          setOtp={setOtp}
          numInputs={6}
          handleSubmit={isEmail ? handleUpdateEmailOtp : handleUpdatePhoneOtp}
          separator=""
          buttonLabel="verify"
          title="Input your 6 digit PIN"
          description={`Please input your 6 digit OTP sent to ${
            isEmail
              ? email?.verificationReference
              : phone?.verificationReference
          } to Verify`}
        />
      ) : (
        ""
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`;

const Form = styled.form`
  width: 70%;
`;

const StepperInfo = styled.div`
  width: 30%;
  margin-left: 10%;
`;
