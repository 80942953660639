import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { TextInputWithIcon } from "../form/TextInput";
import { SelectInputWithIcon } from "../form/SelectInput";
import emailIcon from "../../assets/email.svg";
import settingIcon from "../../assets/setting.svg";
import phoneIcon from "../../assets/phone.svg";
import userIcon from "../../assets/single-user.svg";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import {
  getCurrentMerchant,
  getMerchantLoginId,
} from "../../services/requests/customers";
import { createNewTeamMember } from "../../services/requests/roles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    gender: yup.object().required("Gender is required"),
    role: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          role: yup.string(),
        })
      )
      .required("Role(s) is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup
      .string()
      .matches(/.{10,}/, {
        excludeEmptyString: true,
        message: "Must be 10 characters",
      })
      .max(10)
      .required("Phone is required"),
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

export const CreateNewTeamMember = ({
  availableRoles,
  setPopup,
  setShowOverlay,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const [currentMerchant, setCurrentMerchant] = useState();
  const [merchantUserId, setMerchantUserId] = useState();
  const [merchantLoginId, setMerchantLoginId] = useState();

  useEffect(() => {
    getCurrentMerchant().then((data) => {
      setCurrentMerchant(data?.data?.merchantId);
      setMerchantUserId(data?.data?.userId);
    });
  }, []);

  useEffect(() => {
    getMerchantLoginId(merchantUserId)
      .then((data) => {
        setMerchantLoginId(data?.data?.loginId);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }, [merchantUserId]);

  const onSubmit = (data) => {
    const selectedRoles = data?.role?.map((data) => data?.value);
    const selectedGender = data?.gender?.value;
    const createPayload = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: "+234" + data?.phone,
      password: data?.password,
      confirmPassword: data?.confirmPassword,
      roleIds: selectedRoles,
      gender: selectedGender,
      merchantId: currentMerchant,
      loginId: merchantLoginId,
    };
    console.log("create", createPayload);
    // Uncomment this when backend gives go ahead
    // createNewTeamMember(createPayload)
    //   .then((data) => {
    //     toast.success(data.message);
    //     setPopup("successful");
    //   })
    //   .catch((error) => {
    //     toast.error("Unable to create");
    //   });
  };

  return (
    <Wrapper>
      <HeadContainer>
        <p className="role-header-text">Create New Team Member </p>
      </HeadContainer>
      <p className="text-sm text-center text-gray-400 w-3/5 mx-auto">
        Please enter information for new team member to have access to your
        dashboard
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"First Name"}
                  placeholder={"Janet"}
                  icon={userIcon}
                  error={errors?.firstName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"Last Name"}
                  placeholder={"Doe"}
                  icon={userIcon}
                  error={errors?.lastName?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  type={"email"}
                  label={"Email Address"}
                  placeholder={"Enter team member’s Email"}
                  icon={emailIcon}
                  error={errors?.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  type={"phone"}
                  label={"Phone Number"}
                  placeholder={"8012345678"}
                  icon={phoneIcon}
                  pretext={"+234"}
                  error={errors?.phone?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <SelectInputWithIcon
                  {...field}
                  label={"Choose Gender"}
                  placeholder={"Select team member’s Gender"}
                  icon={userIcon}
                  options={[
                    { value: "female", label: "Female" },
                    { value: "male", label: "Male" },
                  ]}
                  error={errors?.gender?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <SelectInputWithIcon
                  {...field}
                  label={"Choose Role"}
                  isMulti
                  placeholder={"Select team member’s Role"}
                  icon={settingIcon}
                  className="basic-multi-select"
                  options={availableRoles?.map((data) => ({
                    label: data.label,
                    value: data.value,
                  }))}
                  error={errors?.role?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  type={"password"}
                  label={"Create Password"}
                  placeholder={"Password"}
                  icon={userIcon}
                  error={errors?.password?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  type={"password"}
                  label={"Confirm Password"}
                  placeholder={"Confirm Password"}
                  icon={userIcon}
                  error={errors?.confirmPassword?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <button
            type="submit"
            className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded"
          >
            Create New Team Member
          </button>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-top: 1rem;
  }
`;

const Form = styled.form`
  padding: 1rem;
`;
