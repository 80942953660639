import React, { useState } from "react";
import styled from "styled-components";
import { numberedSteps } from "./ActiveScreenHeader";

export const ActiveStepper = ({ navigateByStep, activeStep }) => {

  return (
    <Wrapper>
      <div className="w-full sm:w-3/4 mx-auto grid grid-cols-6 px-5 pt-5 pb-10 rounded-xl shadow border border-gray-50 relative ...">
        <div
          onClick={() => navigateByStep(numberedSteps?.STEP_ONE)}
          className={`${
            activeStep >= 0 ? "active" : ""
          } kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}
        >
          <span
            onClick={() => navigateByStep(numberedSteps?.STEP_ONE)}
            className="hidden sm:flex cursor-pointer"
          >
            Step 1{" "}
          </span>
        </div>
        <div
          onClick={() => navigateByStep(numberedSteps?.STEP_TWO)}
          className={`${
            activeStep > 0 ? "active" : ""
          } kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}
        >
          <span
            onClick={() => navigateByStep(numberedSteps?.STEP_TWO)}
            className="hidden sm:flex cursor-pointer"
          >
            Step 2{" "}
          </span>
        </div>
        <div
          onClick={() => navigateByStep(numberedSteps?.STEP_THREE)}
          className={`${
            activeStep > 1 ? "active" : ""
          } kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}
        >
          <span
            onClick={() => navigateByStep(numberedSteps?.STEP_THREE)}
            className="hidden sm:flex cursor-pointer"
          >
            Step 3
          </span>
        </div>
        <div
          onClick={() => navigateByStep(numberedSteps?.STEP_FOUR)}
          className={`${
            activeStep > 2 ? "active" : ""
          } kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}
        >
          <span
            onClick={() => navigateByStep(numberedSteps?.STEP_FOUR)}
            className="hidden sm:flex cursor-pointer"
          >
            Step 4
          </span>
        </div>
        <div
          onClick={() => navigateByStep(numberedSteps?.STEP_FIVE)}
          className={`${
            activeStep > 3 ? "active" : ""
          } kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}
        >
          <span
            onClick={() => navigateByStep(numberedSteps?.STEP_FIVE)}
            className="hidden sm:flex cursor-pointer"
          >
            Step 5
          </span>
        </div>
        <div
          onClick={() => navigateByStep(numberedSteps?.STEP_SIX)}
          className={`${
            activeStep > 4 ? "active" : ""
          } kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}
        >
          <span
            onClick={() => navigateByStep(numberedSteps?.STEP_SIX)}
            className="hidden sm:flex cursor-pointer"
          >
            Summary
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
