import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./output.css";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {ProtectedRouteProvider} from "./context/AuthContext";

ReactDOM.render(
    <ProtectedRouteProvider>
    <BrowserRouter>
    <App/>
  </BrowserRouter>
  </ProtectedRouteProvider>,
    document.getElementById('root')
);

reportWebVitals();
