import React, {useState} from 'react'
import KYC from '../components/KYC';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { KYCForm } from '../components/KYCForm';

function SubmitKyc() {
    const [sidebarState, setSidebarState] = useState("hidden");
    const [approvalStatus, setApprovalStatus] = useState("DRAFT");
    const toggleSidebar = () => {
        // alert(0)
        setSidebarState(sidebarState === "hidden" ? "block w-3/4 top-8 ..." : "hidden");
    }
    return (
        <div className="body-container">
            <Sidebar toggleSidebar={toggleSidebar} sidebarState={sidebarState}/>
            <div className="">
                <Navbar approvalStatus={approvalStatus} pageName="Submit KYC" toggleSidebar={toggleSidebar}/>
                <KYCForm setApprovalStatus={setApprovalStatus}/>
                {/* <KYC setApprovalStatus={setApprovalStatus}/> */}
            </div>
        </div>
    )
}

export default SubmitKyc;
