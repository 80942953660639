import React, { useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions } from "@mui/material";
import styled from "styled-components";
function Overlay({ children, showOverlay, setShowOverlay }) {
  const hideOverlay = () => {
    setShowOverlay(false);
  };
  return (
    <>
      {showOverlay && (
        <div
          className="w-full h-screen bg-gray-800 bg-opacity-50 fixed top-0 left-0 backdrop-filter backdrop-blur-sm flex justify-center items-center"
          style={{ zIndex: 100 }}
        >
          <div
            style={{ minWidth: "420px" }}
            className="w-2/5 h-auto bg-white rounded-lg pb-10 relative"
          >
            <button onClick={hideOverlay} className="absolute right-6 top-6">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                  fill="#D9D9D9"
                />
                <path
                  d="M19.5 10.5L10.5 19.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 10.5L19.5 19.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Overlay;

export const DialogWrapper = styled.div``;
