import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  PrivacyIcon,
  HomeIcon,
  TransactionIcon,
  SettlementIcon,
  CustomerIcon,
  SubscriptionIcon,
  PaymentLinkIcon,
  DisputeIcon,
  NotificationIcon,
  WayabankIcon,
  SupportIcon,
  ActivityLogIcon,
  SettingIcon,
} from "../assets";
import { availableRoles } from "../utils/roleAccess";
import { ApikeysContext } from "../context/ApikeysContext";

const sidebarItems = [
  // { path: "/", label: "Get Started", icon: GetstartedIcon, permission: null },
  // { path: "/submit-kyc", label: "Submit KYC", icon: PrivacyIcon, permission: "MANAGE_KYC" },
  {
    path: "/overview",
    label: "Overview",
    icon: HomeIcon,
    permission: "VIEW_DASHBOARD",
  },
  {
    path: "/transactions",
    label: "Transactions",
    icon: TransactionIcon,
    permission: "VIEW_TRANSACTIONS",
  },
  {
    path: "/aggregator-dashboard",
    label: "AG Dashboard",
    icon: WayabankIcon,
    permission: "VIEW_DASHBOARD",
  },
  {
    path: "/settlements",
    label: "Settlements",
    icon: SettlementIcon,
    permission: "VIEW_SETTLEMENT",
  },
  {
    path: "/customers",
    label: "Customers",
    icon: CustomerIcon,
    permission: "VIEW_CUSTOMERS",
  },
  {
    path: "/subscription",
    label: "Subscriptions",
    icon: SubscriptionIcon,
    permission: null,
  },
  {
    path: "/payment-link",
    label: "Payment Link",
    icon: PaymentLinkIcon,
    permission: "VIEW_PAYMENT_LINKS",
  },
  {
    path: "/dispute",
    label: "Dispute",
    icon: DisputeIcon,
    permission: "VIEW_DISPUTES",
  },
  {
    path: "/notification",
    label: "Notifications",
    icon: NotificationIcon,
    permission: null,
  },
  { path: "/setting", label: "Settings", icon: SettingIcon, permission: null },
  { path: "/support", label: "Support", icon: SupportIcon, permission: null },
  {
    path: "/activity-log",
    label: "Activity Log",
    icon: ActivityLogIcon,
    permission: null,
  },
];

let userAssignedRoles =
  JSON.parse(sessionStorage.getItem("userData"))?.roles || [];

export const RoleAccessSideBar = () => {
  const { approvalStatus } = useContext(ApikeysContext);

  const [assignedRoles, setAssignedRoles] = useState(userAssignedRoles);

  const getUserPermissions = (roles) => {
    console.log("getting here");
    const userPermissions = new Set();
    roles?.forEach((role) => {
      console.log("found here");
      const userRole = availableRoles?.find((r) => r.role === role);
      console.log(`Checking role: ${role}, Found:`, userRole); //
      if (userRole) {
        userRole.permissions.forEach((permission) =>
          userPermissions.add(permission)
        );
      }
    });
    return Array.from(userPermissions);
  };

  const userPermissions = getUserPermissions(assignedRoles);

  // console.log('here working', userPermissions)

  useEffect(() => {
    getUserPermissions(assignedRoles);
  }, [assignedRoles]);

  useEffect(() => {
    let userAssignedRoles =
      JSON.parse(sessionStorage.getItem("userData"))?.roles || [];
    setAssignedRoles(userAssignedRoles);
  }, []);

  return (
    <ul className="sidebar-nav-container border-b border-gray-100">
      {(userPermissions.includes('MANAGE_KYC')) &&
        (!approvalStatus ||
          approvalStatus === "DRAFT" ||
          approvalStatus === "REJECTED") && (
          <li className="">
            <NavLink
              to="/submit-kyc"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
            >
              <PrivacyIcon />
              Submit KYC
            </NavLink>
          </li>
        )}

      {sidebarItems.map(({ path, label, icon: Icon, permission }) => {
        if (permission && !userPermissions.includes(permission)) return null;
        // Allow default access if permission is null
        if (!permission || userPermissions.includes(permission)) {
          return (
            <li key={path}>
              <NavLink
                to={path}
                className={`${(navData) =>
                  navData.isActive
                    ? "active  text-primary-theme"
                    : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
              >
                <Icon />
                {label}
              </NavLink>
            </li>
          );
        }
      })}
    </ul>
  );
};
