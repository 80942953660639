import React, { useState } from "react";
import { ProfileInformation } from "./steps/ProfileInformation";
import { numberedSteps } from "./components/ActiveScreenHeader";
import { ContactVerification } from "./steps/ContactVerification";
import { BusinessDetails } from "./steps/BusinessDetails";
import { DirectorDetails } from "./steps/DirectorDetails";
import { CertificateUpload } from "./steps/CertificateUpload";
import { KYCSummary } from "./steps/KYCSummary";

export const ActiveScreen = ({ activeStep, handleComplete, handleToStep1 }) => {
  const [formValues, setFormValues] = useState({
    personalDetails: null,
    contactVerification: null,
    businessDetails: null,
    directorDetails: null,
    certificateUpload: null,
  });
  const navigateToStepOne = (data) => {
    setFormValues((prevState) => ({
      ...prevState,
      personalDetails: data,
    }));

    handleComplete();
  };

  const navigateToStepTwo = (data) => {
    setFormValues((prevState) => ({
      ...prevState,
      contactVerification: data,
    }));
    handleComplete();
  };
  const navigatetoStepThree = (data) => {
    setFormValues((prevState) => ({
      ...prevState,
      businessDetails: data,
    }));
    handleComplete();
  };
  const navigatetoStepFour = (data) => {
    setFormValues((prevState) => ({
      ...prevState,
      directorDetails: data,
    }));
    handleComplete();
  };
  const navigatetoStepFive = (data) => {
    setFormValues((prevState) => ({
      ...prevState,
      certificateUpload: data,
    }));
    handleComplete();
  };

  switch (activeStep) {
    case numberedSteps.STEP_ONE:
      return (
        <ProfileInformation
          initialData={formValues.personalDetails}
          navigateForward={navigateToStepOne}
        />
      );

    case numberedSteps.STEP_TWO:
      return (
        <ContactVerification
          formValues={formValues}
          initialData={formValues.contactVerification}
          navigateForward={navigateToStepTwo}
        />
      );

    case numberedSteps.STEP_THREE:
      return (
        <BusinessDetails
          formValues={formValues}
          initialData={formValues.businessDetails}
          navigateForward={navigatetoStepThree}
        />
      );
    case numberedSteps.STEP_FOUR:
      return (
        <DirectorDetails
          formValues={formValues}
          initialData={formValues.directorDetails}
          navigateForward={navigatetoStepFour}
        />
      );
    case numberedSteps.STEP_FIVE:
      return (
        <CertificateUpload
          formValues={formValues}
          initialData={formValues.certificateUpload}
          navigateForward={navigatetoStepFive}
        />
      );
    case numberedSteps.STEP_SIX:
      return (
        <KYCSummary formValues={formValues} handleToStep1={handleToStep1} />
      );
    default:
      return (
        <ProfileInformation
          initialData={formValues.profileInformation}
          navigateForward={navigateToStepOne}
        />
      );
  }
};
