export const ultaPayload = {
  appLink: "",
  approvalStatus: "PENDING",
  businessAccountName: "",
  businessAccountNumber: "",
  businessAccountProvider: "",
  businessAddressProveType: "UTILITY_BILL",
  businessAddressProveUr: "",
  businessDescription: "",
  businessEmail: "",
  businessIndustry: 1,
  businessLogoUrl: "",
  businessName: "",
  businessNameCertificateUrl: null,
  businessNationality: "",
  businessPhoneNumber: "",
  businessRegistrationNumber: "",
  businessRegistrationType: "INCORPORATED_COMPANY",
  businessType: "REGISTERED_BUSINESS",
  bvnApproved: false,
  bvnNumber: null,
  cacBnCertificateUrl: null,
  cacItCertificateUrl: null,
  city: null,
  country: null,
  createdAt: "",
  dateDeleted: null,
  deleted: false,
  deletedBy: null,
  directorBvnNumber: null,
  directorFirstName: "",
  directorLastName: "",
  directorNinImageUrl: "",
  directorNinNumber: null,
  directorPhotoUrl: "",
  draft: true,
  email: "",
  facebookUrl: "",
  firstName: "",
  identificationType: "NATIONAL_ID",
  identificationUrl: "",
  incorporationCertificateUrl: "",
  instagramUrl: "",
  lastName: "",
  lga: "",
  memartCertificateUrl: "",
  merchantId: "",
  ninApproved: false,
  ninNumber: null,
  reasonForRejection: null,
  state: "",
  statusLastModifiedOn: null,
  statusModifiedBy: "",
  streetAddress: "",
  tin: "",
  twitterUrl: "",
  updatedAt: "",
  usePaymentLink: false,
  userBvnNumber: null,
  userId: null,
  userNinNumber: null,
  websiteLink: "",
};
