import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../../assets";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.js";
import { updateProfile } from "../../services/requests//profile";
import {
  getCurrentMerchant,
  getMerchantLoginId,
} from "../../services/requests/customers.js";

function EditProfile() {
  const dpField = useRef();
  const [pix, setPix] = useState("");
  const location = useNavigate();
  const [userData, setUserData] = useState(null);
  const [account, setAccount] = useState(null);
  const [acronym, setNameAcronym] = useState(null);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [isImageToUploadAvailable, setIsImageToUploadAvailable] =
    useState(false);

  const [firstName, setFirstname] = useState("");
  const [surname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");

  const [currentMerchant, setCurrentMerchant] = useState();


  useEffect(() => {
    getCurrentMerchant().then((data) => {
      setCurrentMerchant(data?.data);
    });
  }, []);


  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem("userData"));
    const accountInfo = JSON.parse(sessionStorage.getItem("accountDetails"));
    if (userInfo.profileImage) {
      setIsImageToUploadAvailable(true);
      setPix(userInfo.profileImage);
    }
    setUserData(userInfo);
    setFirstname(userInfo ? userInfo?.firstName : "");
    setLastname(userInfo ? userInfo?.surname : "");
    setDateOfBirth(userInfo ? userInfo?.dateOfBirth : "");
    setGender(userInfo ? userInfo?.gender : "");
    setEmail(userInfo ? userInfo?.email : "");
    setPhoneNumber(userInfo ? userInfo?.phoneNumber : "");
    setState(userInfo ? userInfo?.state : "");
    setAddress(userInfo ? userInfo?.address : "");

    const nameBreak = accountInfo?.acct_name?.split(" ");
    if (nameBreak) {
      const acronymn = `${nameBreak[0][0]}${nameBreak[1][0]}`;
      setNameAcronym(acronymn);
    }
  }, []);

  const selectImage = () => {
    dpField.current.click();
  };
  const uploadImage = () => {
    const imagefile = dpField.current;
    var reader = new FileReader();
    reader.readAsDataURL(imagefile.files[0]);

    reader.onload = function () {
      setPix(reader.result);
      setIsImageToUploadAvailable(true);
      setImageToUpload(imagefile.files[0]);
    };
  };

  const SaveProfileImageHandler = () => {
    var formData = new FormData();
    const userId = JSON.parse(sessionStorage.getItem("userData")).id;
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    formData.append("file", imageToUpload);
    const ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/profile/update-profile-image/${userId}`;
    axios
      .post(ENDPOINT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      })
      .then((response) => {
        const ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/profile/${userId}`;
        axios
          .get(ENDPOINT, {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: authorization,
              "CLIENT-ID": "WAYAQUICK",
              "CLIENT-TYPE": "CORPORATE",
            },
          })
          .then((response2) => {
            toast(response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            toast(JSON.stringify(error));
          });
      })
      .catch((error) => {
        toast(JSON.stringify(error));
      });
  };

  const updateProfileInfo = () => {
    const otherInfo = JSON.parse(sessionStorage.getItem("otherInfo"));

    const payload = {
      address: address,
      dateOfBirth: dateOfBirth,
      email: email,
      firstName: firstName,
      gender: gender,
      phoneNumber: phoneNumber,
      state: state,
      surname: surname,
      profileId: userData?.profileSubscription?.profileId,
    };

    if (dateOfBirth && email && firstName && gender && phoneNumber && surname) {
      const userId = JSON.parse(sessionStorage.getItem("userData")).id;
      if (isImageToUploadAvailable) {
        SaveProfileImageHandler();
      }
      updateProfile(userId, payload)
        .then((response) => {
          if (response.status) {
            let userData = JSON.parse(sessionStorage.getItem("userData"));
            userData.firstName = firstName;
            userData.surname = surname;
            userData.dateOfBirth = dateOfBirth;
            userData.gender = gender;
            userData.email = email;
            userData.phoneNumber = phoneNumber;
            userData.state = state;
            userData.address = address;

            sessionStorage.setItem("userData", JSON.stringify(userData));
            // console.log(userData);
            toast("Profile information saved");
          } else {
            toast(response.message);
          }
        })
        .catch((error) => {
          toast("Error Occured: ");
        });
    } else {
      toast("All fields are required!");
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="flex justify-center flex-col items-center w-full px-10">
        <div className="flex w-full items-center justify-between pt-6">
          <button
            className="text-sm flex items-center gap-2"
            onClick={() => location("/setting/profile")}
          >
            <BackIcon />
            Back
          </button>
          <input
            type="file"
            onChange={uploadImage}
            accept="image/png, image/jpeg"
            ref={dpField}
            className="hidden"
          />
          <p>Edit Profile</p>
          <button
            onClick={updateProfileInfo}
            className="font-semibold text-primary-theme"
          >
            Save
          </button>
        </div>
        <div className="w-20 overflow-hidden h-20 mt-5 mb-5 rounded-full bg-primary-theme text-white font-semibold flex items-center justify-center">
          {isImageToUploadAvailable ? (
            <img className="w-full h-full rounded-full" src={pix} />
          ) : (
            acronym
          )}
        </div>
        <p
          className="cursor-pointer py-2 px-4 bg-primary-theme text-white text-xs rounded"
          onClick={selectImage}
        >
          Upload profile image
        </p>
        <p>
          Acct Number:{" "}
          {currentMerchant?.settlementBankDetail?.settlementBankAccount}
        </p>

        <div className="w-full pt-8">
          <div className="grid grid-cols-2 gap-8">
            <input
              type=""
              disabled
              onChange={(e) => setFirstname(e.target.value)}
              defaultValue={userData ? userData?.firstName : ""}
              placeholder="First Name"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
            <input
              type=""
              disabled
              onChange={(e) => setLastname(e.target.value)}
              defaultValue={userData ? userData?.surname : ""}
              placeholder="Last Name"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
          </div>
          <div className="grid grid-cols-2 gap-8 mt-6">
            <input
              type="date"
              onChange={(e) => setDateOfBirth(e.target.value)}
              defaultValue={userData ? userData?.dateOfBirth : ""}
              placeholder="Date of birth"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
            <input
              type=""
              onChange={(e) => setGender(e.target.value)}
              defaultValue={userData ? userData?.gender : ""}
              placeholder="Gender"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
          </div>
          <div className="grid grid-cols-2 gap-8 mt-6">
            <input
              type=""
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={userData ? userData?.email : ""}
              placeholder="Email address"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
            <input
              type=""
              onChange={(e) => setPhoneNumber(e.target.value)}
              defaultValue={userData ? userData?.phoneNumber : ""}
              placeholder="Phone number"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
          </div>
          <div className="grid grid-cols-2 gap-8 mt-6">
            <input
              type=""
              onChange={(e) => setState(e.target.value)}
              defaultValue={userData ? userData?.state : ""}
              placeholder="District/State"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
            <input
              type=""
              onChange={(e) => setAddress(e.target.value)}
              defaultValue={userData ? userData?.address : ""}
              placeholder="Address"
              className="w-full bg-transparent px-2 text-sm border-b-2 border-gray-300 h-10"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
