import React from "react";
import styled from "styled-components";
import searchIcon from "../../assets/search.svg";

export const Search = ({ placeholder, value, onChange }) => {
  return (
    <Wrapper>
      <div className="search-container">
        <img src={searchIcon} alt="search" className="search-icon" />
        <SearchInput
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .search-icon {
    margin-right: 5px;
  }
  .search-container {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 4px;
    padding: 10px;
    background-color: white;
  }
  input:focus + .search-container {
    border: transparent;
  }
`;
const SearchInput = styled.input`
  border: transparent;
  width: 100%;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;
