import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  FilterIcon,
  ExportIcon,
  ChevronDownIcon,
  BackIcon,
  CopyIcon,
  UserAvatar,
  ForwardArrow,
  ReplayIcon,
} from "../assets";
import { Card, Table } from "./index.js";
import CardTwo from "./CardTwo";
import FetchApi from "../utils/FetchApi";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import {
  getAllTransactions,
  getRevenueStats,
  getRevenueSummary,
  getCustomerTransactions,
  filterTransactions,
} from "../services/requests/transactions";
import { FormatDatetime, formatShortDate } from "../utils/FormatDatetime";
import { getBranches } from "../services/requests/banks.js";
import styled from "styled-components";

function TransactionInner() {
  const [searchParams] = useSearchParams();

  const [transaction, setTransaction] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [allTrxn, setAllTrxn] = useState(null);
  const [revenueSummary, setRevenueSummary] = useState(null);
  const [allFilteredData, setFilteredData] = useState(null);
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [revenueStats, setRevenueStats] = useState([]);
  const [branches, setBranches] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentChannel, setPaymentChannel] = useState("");
  const [paymentBranch, setPaymentBranch] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");

  const tableColumnStructure = [
    { field: "id", headerName: "Reference ID", width: 175 },
    { field: "customerName", headerName: "Customer Name", width: 170 },
    { field: "paymentChannel", headerName: "Payment Channel", width: 150 },
    { field: "branch", headerName: "Branch/Category", width: 200 },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "createdAt", headerName: "Created At", width: 160 },
    { field: "status", headerName: "Payment Status", width: 120 },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const data = allTrxn.filter((elem) => elem.refNo === params.row.id);
          if (data.length === 1) {
            setShowPopup(true);
            setTransactionDetails(data[0]);
            console.log(data);
          }
        };
        return (
          <button
            className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white"
            onClick={onClick}
          >
            View More
          </button>
        );
      },
    },
  ];
  const getBranch = async () => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const response = await getBranches(merchantId);
    setBranches(response.content);
  };

  useEffect(() => {
    getBranch();
    const customerID = searchParams.get("cid");
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));

    if (customerID) {
      getCustomerTransactions(customerID).then((response) => {
        const transactionResponse = response?.data?.content?.map(
          (elem, index) => ({
            id: elem.refNo,
            customerName: elem.customerName,
            paymentChannel: elem.channel,
            branch: elem.branchCategory || "N/A",
            amount: `NGN ${elem.amount}`,
            createdAt: elem.tranDate,
            status: elem.status,
          })
        );
        setTransaction(transactionResponse);
        setFilteredData(transactionResponse);
        setAllTrxn(response?.data?.content);
      });
    } else {
      getAllTransactions(merchantId).then((response) => {
        const transactionResponse = response?.data?.content?.map(
          (elem, index) => ({
            id: elem.refNo,
            customerName: elem.customerName,
            paymentChannel: elem.channel,
            branch: elem.branchCategory || "N/A",
            amount: `NGN ${elem.amount}`,
            createdAt: FormatDatetime(elem.tranDate),
            status: elem.status,
          })
        );
        setTransaction(transactionResponse);
        setFilteredData(transactionResponse);
        setAllTrxn(response?.data?.content);
      });
    }
    getRevenueStats(merchantId).then((response) => {
      setRevenueSummary(response?.data);
    });
    getRevenueSummary(merchantId).then((response) => {
      setRevenueStats(response?.data);
    });
  }, []);

  let sortedDataDESC = transaction ? [...transaction] : null;
  const tableRowData = sortedDataDESC;
  const hideOverlay = () => {
    setShowPopup(false);
  };

  const handleSearchFilter = () => {
    let filteredList = transaction;
    const newList = transaction?.map((data) => data);
    filteredList = newList?.filter((data) => data?.id === filterSearchValue);
    const activeList = filterSearchValue ? filteredList : transaction;
    const tableRowData = activeList?.map((transaction) => {
      return {
        id: transaction.id,
        customerName: transaction.customerName,
        paymentChannel: transaction.paymentChannel,
        branch: transaction.branch || "N/A",
        amount: `NGN ${transaction.amount}`,
        createdAt: FormatDatetime(transaction.createdAt),
        status: transaction.status,
      };
    });
    setFilteredData(tableRowData);
  };

  const handleFilter = () => {
    let filteredList = transaction;
    const newList = transaction?.map((data) => data);

    const filteredDateList = newList?.filter(
      (data) => formatShortDate(data?.createdAt) === formatShortDate(startDate)
    );

    const filteredTransactionStatus = filteredDateList?.filter(
      (data) =>
        data.status?.toLocaleLowerCase() === paymentStatus.toLocaleLowerCase()
    );

    const filteredTransactionChannel = filteredTransactionStatus?.filter(
      (data) =>
        data.paymentChannel?.toLocaleLowerCase() ===
        paymentChannel.toLocaleLowerCase()
    );
    if (paymentBranch && paymentBranch !== "N/A") {
      filteredList = filteredTransactionChannel?.filter(
        (data) =>
          data.branch &&
          data.branch.toLocaleLowerCase() === paymentBranch.toLocaleLowerCase()
      );
    } else {
      filteredList = filteredTransactionChannel;
    }

    filteredList = filteredList || transaction;

    const tableRowData = filteredList.map((transaction) => ({
      id: transaction.id,
      customerName: transaction.customerName,
      paymentChannel: transaction.paymentChannel,
      branch: transaction.branch || "N/A",
      amount: `NGN ${transaction.amount}`,
      createdAt: FormatDatetime(transaction.createdAt),
      status: transaction.status,
    }));

    setFilteredData(tableRowData);
  };

  const ClearFilter = () => {
    setValue(() => "");
    setStartDate(() => "");
    setPaymentStatus(() => "");
    setPaymentChannel(() => "");
    setFilteredData(tableRowData);
    hideFilterDropdown();
  };

  const showFilterDropdown = () => setFilterDropdown(true);
  const hideFilterDropdown = () => setFilterDropdown(false);

  return (
    <>
      <ToastContainer />
      {showPopup && (
        <div className="w-full">
          <button
            className="mx-8 my-4 flex items-center text-sm gap-2"
            onClick={hideOverlay}
          >
            <BackIcon /> Back
          </button>
          <div className="grid grid-cols-1 md:grid-cols-2 h-auto px-8">
            <div className="bg-white w-full h-auto border px-6 border-gray-200 rounded-xl py-5">
              <div className="flex justify-between items-start px-8 py-3">
                <div className="">
                  <span className="text-xs text-gray-500">Amount</span>
                  <h3 className="text-xl font-semibold">
                    {transactionDetails && transactionDetails.amount}.00
                  </h3>
                </div>
                <button
                  className={`py-3 px-6 mt-3 text-sm rounded-lg ${
                    transactionDetails?.status === "DECLINED"
                      ? "bg-red-500"
                      : transactionDetails?.status === "PENDING"
                      ? "bg-yellow-700"
                      : "bg-green-600"
                  } text-white`}
                >
                  {transactionDetails?.status}
                </button>
              </div>
              <div className="flex justify-between items-start px-8 py-5">
                <span className="text-xs text-gray-500">Reference ID</span>
                <span
                  className="text-xs font-semibold flex items-center gap-2 cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(transactionDetails?.refNo);
                    toast("Transaction ID Copied");
                  }}
                >
                  {transactionDetails?.refNo} <CopyIcon />
                </span>
              </div>
              <hr />
              <div className="flex justify-between items-start px-8 py-5">
                <span className="text-xs text-gray-500">Channel</span>
                <span className="text-xs font-semibold flex items-center gap-2">
                  {transactionDetails?.channel}{" "}
                </span>
              </div>
              <hr />
              <div className="flex justify-between items-start px-8 py-5">
                <span className="text-xs text-gray-500">Wayapay Fees</span>
                <span className="text-xs font-semibold flex items-center gap-2">
                  {transactionDetails?.fee}
                </span>
              </div>
              <hr />
              <div className="flex justify-between items-start px-8 py-5">
                <span className="text-xs text-gray-500">Date Paid</span>
                <span className="text-xs font-semibold flex items-center gap-2">
                  {FormatDatetime(transactionDetails?.rcre_time)}{" "}
                </span>
              </div>
              <hr />
              <div className="flex justify-between items-start px-8 py-5">
                <span className="text-xs text-gray-500">Message</span>
                <span className="text-xs font-semibold flex items-center gap-2">
                  {transactionDetails?.status}
                </span>
              </div>
              <hr />
              <div className="flex justify-between items-center h-20 px-5 mt-5 border border-gray-200 rounded-xl">
                <div className="text-xs text-gray-500 flex items-center gap-2">
                  <UserAvatar />
                  <div>
                    <span className="text-xs block font-medium text-black">
                      {transactionDetails?.customerName}
                    </span>
                    <span className="text-xs block font-thin">
                      {transactionDetails?.customerEmail}
                    </span>
                  </div>
                </div>
                <Link
                  to={`/customers?customerId=${transactionDetails?.customerId}`}
                  className="text-xs font-semibold flex items-center gap-2 shadow rounded py-3 px-5"
                >
                  View Customer <ForwardArrow />
                </Link>
              </div>
            </div>
            <div className="bg-red-50 w-full h-auto border px-6 border-gray-200 rounded-xl py-5">
              <div className="border border-red-400 px-3 rounded-xl bg-white h-14 flex items-center">
                <button className="py-2 px-5 bg-red-400 text-white text-xs flex items-center rounded">
                  <ReplayIcon />
                  Refund Customer
                </button>
              </div>
              <div className="flex justify-between items-start px-4 py-3">
                <span className="text-xs text-black">Analytics</span>
              </div>
              <hr />
              <div className="flex justify-between items-start px-4 py-5">
                <div>
                  <span className="text-sm block text-black">Card Type</span>
                  <span className="text-xs block text-gray-500">
                    {transactionDetails?.scheme}
                  </span>
                </div>
                <div>
                  <span className="text-sm block text-black">Card Number</span>
                  <span className="text-xs block text-gray-500">
                    {transactionDetails?.maskedPan}
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-start px-4 py-5">
                <div>
                  <span className="text-sm block text-black">
                    Authorization
                  </span>
                  <span className="text-xs block text-gray-500"> - </span>
                </div>
                <div>
                  <span className="text-sm block text-black">
                    Issuing Bank & Country
                  </span>
                  <span className="text-xs block text-gray-500">-</span>
                </div>
              </div>
              <div className="flex justify-between items-start px-4 py-5">
                <div>
                  <span className="text-sm block text-black">IP Adress</span>
                  <span className="text-xs block text-gray-500">
                    {transactionDetails?.customerIpAddress}
                  </span>
                </div>
                {/* <div>
                                        <span className="text-sm block text-black">Payment Processor</span>
                                        <span className="text-xs block text-gray-500">Mastercard</span>
                                    </div> */}
              </div>
              <hr />
            </div>
          </div>
        </div>
      )}
      {!showPopup && (
        <>
          <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
            <div className="flex items-center gap-6">
              <div className="flex items-center relative">
                <button
                  className="flex items-center"
                  onClick={showFilterDropdown}
                >
                  <FilterIcon />
                  <span className="text-sm">Filter</span>
                </button>
                {filterDropdown && (
                  <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                    <span
                      onClick={hideFilterDropdown}
                      className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm"
                    >
                      close
                    </span>
                    <h5 className="font-semibold mt-2">Date Range</h5>
                    <div className="flex items-center gap-4">
                      <input
                        placeholder="Search by reference ID"
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                        type="date"
                      />
                      {/* <input placeholder="Search by reference ID" onChange={(e)=>FilterHandler(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" type="date"/> */}
                    </div>
                    <h5 className="font-semibold mt-2">Branch</h5>
                    <div className="flex items-center gap-4">
                      <select
                        type=""
                        onChange={(e) => setPaymentBranch(e.target.value)}
                        className="w-full h-10 rounded border border-gray-200"
                      >
                        <option value="">Choose Branch</option>
                        {branches?.map((e) => (
                          <option value={e?.name}>
                            {e?.name?.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                    <h5 className="font-semibold mt-2">Payment Channel</h5>
                    <div className="flex items-center gap-4">
                      <select
                        type=""
                        onChange={(e) => setPaymentChannel(e.target.value)}
                        className="w-full h-10 rounded border border-gray-200"
                      >
                        <option value="">Choose channel</option>
                        <option value="CARD">Card</option>
                        <option value="wallet">Wallet</option>
                        <option value="QR">QR</option>
                        <option value="payattitude">Pay Attitude</option>
                      </select>
                    </div>

                    <h5 className="font-semibold mt-2">Payment Status</h5>
                    <div className="flex items-center gap-4">
                      <select
                        type=""
                        onChange={(e) => setPaymentStatus(e.target.value)}
                        className="w-full h-10 rounded border border-gray-200"
                      >
                        <option value="">Choose status</option>
                        <option value="abandoned">Abandoned</option>
                        <option value="PENDING">Pending</option>
                        <option value="failed">Failed</option>
                        <option value="successful">Successful</option>
                      </select>
                    </div>

                    <div className="flex items-center gap-4 mt-5">
                      <button
                        onClick={ClearFilter}
                        className="py-3 w-full text-xs rounded shadow"
                      >
                        Clear filter
                      </button>
                      <SubmitBtn
                        disabled={
                          !startDate || !paymentChannel || !paymentStatus
                        }
                        onClick={handleFilter}
                        className="py-3 w-full rounded bg-primary-theme text-white text-xs"
                      >
                        Apply filter
                      </SubmitBtn>
                    </div>
                  </div>
                )}
              </div>
              <div className="1/5 flex gap-1 ...">
                <input
                  placeholder="Search by reference ID"
                  onChange={(e) => setFilterSearchValue(e.target.value)}
                  className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                />
                <SubmitBtn
                  disabled={!filterSearchValue}
                  onClick={handleSearchFilter}
                  className="bg-primary-theme py-2 px-5 text-white text-sm"
                >
                  Search
                </SubmitBtn>
              </div>
            </div>
            {allFilteredData?.length > 0 ? (
              <CSVLink data={allFilteredData}>
                <button className="flex shadow gap-2 h-10 items-center text-sm px-5">
                  <ExportIcon />
                  Export
                  <ChevronDownIcon />
                </button>
              </CSVLink>
            ) : (
              <button
                onClick={() => toast("You can't export empty data.")}
                className="flex shadow gap-2 h-10 items-center text-sm px-5"
              >
                <ExportIcon />
                Export
                <ChevronDownIcon />
              </button>
            )}
          </div>
          <Grid spacing={2} container className="px-6 pt-5">
            <Grid item xs={12} sm={12} md={3}>
              <Card
                name="Gross Revenue"
                amount={revenueSummary?.grossRevenue? revenueSummary?.grossRevenue : '0'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Card name="Net Revenue" amount={revenueSummary?.netRevenue ? revenueSummary?.netRevenue : '0'} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <CardTwo
                failed={revenueStats?.totalFailed? revenueStats?.totalFailed : '0'}
                pending={revenueStats?.totalPending ? revenueStats?.totalPending : "0"}
                sucessful={revenueStats?.totalSuccess ? revenueStats?.totalSuccess : "0"}
                abandoned={revenueStats?.totalAbandoned ? revenueStats?.totalAbandoned : "0"}
                settled={revenueStats?.totalSettled ? revenueStats?.totalSettled : "0"}
                refunded={revenueStats?.totalRefunded ? revenueStats?.totalRefunded : "0"}
              />
            </Grid>
          </Grid>
          <Table
            tableColumnStructure={tableColumnStructure}
            tableRowData={
              allFilteredData && allFilteredData.length > 0
                ? allFilteredData
                : null
            }
            pageName="Transaction"
          />
        </>
      )}
    </>
  );
}

export default TransactionInner;

const SubmitBtn = styled.button`
  &[disabled] {
    opacity: 0.5;
  }
`;
