import React, { useState } from "react";
import PermissionCheckbox from "./PermissionCheckbox";
import styled from "styled-components";


function CreateCustomRole({
  handleNewRoleInputChange,
  createCustomRole,
  newRolePayload,
  setNewRolePayload,
  permissions,
  setPermissions,
}) {
  return (
    <Wrapper>
      <HeadContainer>
        <p className="role-header-text">
        Create Custom Role
        </p>
      </HeadContainer>

      <Form>
        <input
          value={newRolePayload?.roleName}
          name="roleName"
          onChange={handleNewRoleInputChange}
          type="text"
          placeholder="Role name"
          className="w-full h-12 my-3 border border-gray-300 rounded px-3 text-md"
        />

        <input
          value={newRolePayload?.roleDescription}
          name="roleDescription"
          onChange={handleNewRoleInputChange}
          type="text"
          placeholder="Role description"
          className="w-full h-12 my-3 border border-gray-300 rounded px-3 text-md"
        />

        <PermissionCheckbox
          permissions={permissions}
          setPermissions={setPermissions}
        />
        <button
          type="submit"
          onClick={createCustomRole}
          className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded"
        >
         Create Role
        </button>
      </Form>
    </Wrapper>
  );
}

export default CreateCustomRole;

const Wrapper = styled.div``;

export const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-top: 1rem;
  }
`;

const Form = styled.form`
  width: 90%;
  margin: auto;
`;
