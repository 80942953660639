

import axios from "axios";
import {httpGet} from "../https";
import {identityService, paymentGateway} from "../index";
import config from '../../config.js';

export const getAllCustomers = () => {
    return httpGet(`${identityService}/webpos/customer/filter-search?size=1000000&order=ASC`);
}

export const getCustomerStats = () => {
    return httpGet(`${identityService}/webpos/customer/fetch/stats`);
}

export const getEachCustomerStats = (customerId) => {
    return httpGet(`${paymentGateway}/transactions/${customerId}`)
}

export const getCustomer = (customerId) => {
    return httpGet(`${identityService}/webpos/customer/${customerId}`)
}

export const getCurrentMerchant = async () => {
    return httpGet(`${identityService}/waya-merchant/auth-user/merchant-account`)
  };


  export const getMerchantLoginId = async (userId) => {
    return httpGet(`${config.BASE_URL}/auth-service/api/v1/profile/${userId}`);
  };
  