import React from 'react';
import styled from 'styled-components';

export const numberedSteps = {
  STEP_ONE: 0,
  STEP_TWO: 1,
  STEP_THREE: 2,
  STEP_FOUR: 3,
  STEP_FIVE: 4,
  STEP_SIX: 5,
};

export const Header = ({ title, subtitle }) => {
  return (
    <HeaderContainer>
      <h3 className="title">{title}</h3>
      <p className="subtitle">{subtitle}</p>
    </HeaderContainer>
  );
};

export const HeaderContainer = styled.div`
  margin-left: 10px;
`;
