import crypto from "crypto-browserify";

export const findArrayData = (arr=[], key='value', expectedValue) => {
  return arr.find((item) => item[key] === expectedValue)
}

const key = {
  type: 'Buffer',
  data: [
    51, 152, 235, 68, 51, 42, 62, 50, 40, 136, 55, 165, 108, 253, 6, 132, 33,
    161, 12, 35, 102, 209, 72, 212, 33, 225, 74, 14, 109, 149, 149, 227,
  ],
};

export const decrypt = (text) => {
  // console.log('.>>>', text);
  const iv = Buffer.from(text.iv, 'hex');
  const encryptedText = Buffer.from(text.encryptedData, 'hex');
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};
