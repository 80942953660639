import { httpGet, httpPost, httpPut, httpDelete } from "../https";
import { roleService } from "../index";

export const getAvailableRoles = () => {
  return httpGet(`${roleService}/merchant/list-roles`);
};

export const getPermissionIdFromRoleId = (roleId) => {
  return httpGet(`${roleService}/api/v1/roles/${roleId}/permissions`);
};

export const sendInviteService = (email, payload) => {
  return httpPost(`${roleService}/merchant/invite-user/${email}`, payload);
};

export const createCustomRoleRequest = (payload) => {
  return httpPost(`${roleService}/merchant/create-roles`, payload);
};

export const getAllUsers = (userId) => {
  return httpGet(`${roleService}/merchant/list-users`);
};

export const getAllMerchantTeamMembers = (merchantUserId) => {
  return httpGet(`${roleService}/merchant/team/members/${merchantUserId}`);
};

export const updateUserRole = (payload) => {
  return httpPut(`${roleService}/merchant/edit`, payload);
};

export const deleteUser = (userId) => {
  return httpDelete(`${roleService}/api​/v1​/user-role​/delete​/${userId}`);
};

export const getAllPermissions = () => {
  return httpGet(`${roleService}/merchant/list-permissions`);
};

// export const getPermissionIdFromRoleId = () => {
// 	return httpGet(`${roleService}/api/v1/corporate/user/role/find/all`);
// };

export const getRole = (roleId) => {
  return httpGet(`${roleService}/merchant/list/role-permissions/${roleId}`);
};

export const changeRole = (payload) => {
  return httpPost(`${roleService}/merchant/update-user`, payload);
};

export const deleteRole = (roleId) => {
  return httpDelete(`${roleService}/api/v1/role/delete/${roleId}`);
};

export const completeRegisteration = (payload) => {
  return httpPost(`${roleService}/merchant/register-user`, payload);
};

export const inviteNewTeamMember = (payload) => {
  return httpPost(`${roleService}/merchant/team/invite`, payload);
};
export const createNewTeamMember = (payload) => {
  return httpPost(`${roleService}/merchant/team/register`, payload);
};

export const getAccessType = () => {
  return httpGet(`${roleService}/merchant/access-types
`);
};

export const updateTeamMemberRole = (payload) => {
  return httpPut(`${roleService}/merchant/team/member/roles`, payload);
};


export const deleteTeamMemberRole = (TeamMemberUserId) => {
  return httpDelete(`${roleService}/merchant/team/member/${TeamMemberUserId}`);
};


