import React, { useEffect, useState } from "react";
import Select from "react-select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import {
  getAccessType,
  updateTeamMemberRole,
} from "../../services/requests/roles";
import { SelectInputWithIcon } from "../form/SelectInput";
import settingIcon from "../../assets/setting.svg";
import { getCurrentMerchant } from "../../services/requests/customers";
import { toast } from "react-toastify";

function UpdateUserInvite({
  availableRoles,
  changeUserId,
  setPopup,
  activeUserName,
}) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      select: {},
    },
  });
  const [allAccessType, setAllAccessType] = useState([]);
  const [currentMerchant, setCurrentMerchant] = useState();
  const [merchantUserId, setMerchantUserId] = useState();

  useEffect(() => {
    getCurrentMerchant().then((data) => {
      setCurrentMerchant(data?.data?.merchantId);
      setMerchantUserId(data?.data?.userId);
      getAccessType().then((data) => {
        if (data?.length > 0) {
          setAllAccessType(data);
        } else {
          setAllAccessType([]);
        }
      });
    });
  }, []);

  useEffect(() => {
    getAccessType().then((data) => {
      if (data?.length > 0) {
        setAllAccessType(data);
      } else {
        setAllAccessType([]);
      }
    });
  }, []);

  const onSubmit = (data) => {
    const selectedRoles = data?.role?.map((data) => data?.value);
    const selectedAccessType = data?.accessType?.map((data) => data?.value);
    const updateRolePayload = {
      merchantUserId: merchantUserId,
      teamMemberUserId: changeUserId,
      roleIds: selectedRoles,
      accessTypeIds: selectedAccessType,
    };
    updateTeamMemberRole(updateRolePayload)
      .then((data) => {
        if (data?.status === false) {
          toast.error("Unable to Update");
        } else {
          toast.success(data.message);
          setPopup("successful");
        }
      })
      .catch((error) => {
        toast.error("Unable to Update");
      });
    // console.log(updateRolePayload);
  };

  return (
    <>
      <HeadContainer>
        <p className="role-header-text">Change Role </p>
      </HeadContainer>
      <p className="text-sm text-center text-black my-4 w-4/5 mx-auto">
        This action will change the role of{" "}
        <span className="username" style={{fontWeight: '700'}}>{activeUserName ? activeUserName : "this user"}</span>, and {" "}
        <span className="username" style={{fontWeight: '700'}}>{activeUserName ? activeUserName : "This user"}</span> will no
        longer have the access to the previous role.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="accessType"
          control={control}
          render={({ field }) => (
            <SelectInputWithIcon
              {...field}
              label={"Choose Access Type"}
              isMulti
              placeholder={"Select team member’s Access"}
              icon={settingIcon}
              className="basic-multi-select"
              options={allAccessType?.map((data) => ({
                label: data?.type,
                value: data?.id,
              }))}
            />
          )}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <SelectInputWithIcon
              {...field}
              label={"Choose Role"}
              isMulti
              placeholder={"Select team member’s Role"}
              icon={settingIcon}
              className="basic-multi-select"
              options={availableRoles?.map((data) => ({
                label: data.label,
                value: data.value,
              }))}
            />
          )}
        />
        <button
          type="submit"
          className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded"
        >
          Transfer
        </button>
      </Form>
    </>
  );
}

export default UpdateUserInvite;

const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-top: 1rem;
  }
`;

const Form = styled.form`
  padding: 1rem;
`;
