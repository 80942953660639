import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InnerOption from './InnerOption';
import { QuestiontagIcon, MedalIcon, FailedIcon } from '../../assets';
import styles from './styles.module.css';
import InnerOption2 from './InnerOption2';
import FetchApi from '../../utils/FetchApi';
import axios from 'axios';
import { toggle2FA, check2FA } from '../../services/requests/auth';
import { ToastContainer, toast } from 'react-toastify';

function ProfileTab({ status }) {
    const [accountDetails, setAccountDetails] = useState(null);
    const [ user, setUser ] = useState({})
    const [ tfa, setTfa ] = useState(false);
    const [nameAcronym, setNameAcronym] = useState(null);
    const [balVisible, setBalVisible] = useState(false);
    const [deletePopupState, setDeletePopupState] = useState(false);
    const [deleteNow, setDeleteNow] = useState(false);
    const [loader, setLoader] = useState(true);
    const [pix, setPix] = useState("");
    const [imageAvail, setImageAvail] = useState(false);
    const [goodbyeMsg, setGoodbyeMsg]=useState("Not goodbye but see you again...");

    const showDeletePopup = () => {
        setDeletePopupState(true);
    }
    const hideDeletePopup = (e) => {
        if(e.target.classList.contains("close-overlay")){
            setDeletePopupState(false);
        }
    }

    useEffect(() => {
        const userData = JSON.parse((sessionStorage.getItem("userData")));
        setUser(userData);
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        if(userData.profileImage){
            setImageAvail(true);
            setPix(userData.profileImage)
        }

        const endpoint = `/temporal-service/api/v1/wallet/default/${userData.id}`;
        const response = FetchApi({ endpoint: endpoint, authorization: authorization, method: "GET" });
        response.then(resp => {
            // const nameBreak = JSON.parse(sessionStorage.getItem("userData")).acct_name.split(" ");
            // const acronymn = `${nameBreak[0][0]}${nameBreak[1][0]}`;
            const nameBreak = resp.acct_name.split(" ");
            const acronymn = `${nameBreak[0][0]}${nameBreak[1][0]}`;
            setAccountDetails(resp);
            sessionStorage.setItem("accountDetails", JSON.stringify(resp));
            setNameAcronym(acronymn)
        }).catch(err => {
            console.log(err)
        });
    }, []);

    useEffect(() => {
        if(user.id) {
            check2fa();
        }
    }, [user])

    const toggleVisibility = () => {
        setBalVisible(!balVisible);
    }

    const toggle2fa = async () => {
        let id
        try {
            id = toast.loading('Switching 2fa...', {
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			})
            const res = await toggle2FA(user.id);
            await check2fa();
            toast.update(id, {
				render: res?.data,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
			})
        } catch (err) {
            toast.update(id, {
				render: 'Error Switching 2fa',
				type: 'error',
				isLoading: false,
				autoClose: 2000,
			})
        }
    }

    const check2fa = async () => {
        try {
            const res = await check2FA(user.id);
            setTfa(res?.data['2fa']);
        } catch (err) {
            console.log(err);
        }
    }
    const deleteAccount= () => {
        
        const id = JSON.parse(sessionStorage.getItem("userData")).id;
        const token = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                authorization: token,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        const endpoint= `https://services.wayapay.ng/auth-service/api/v1/user/delete/${id}`
        axios.delete(endpoint, header).then(response=>{
            setLoader(false);
            setGoodbyeMsg("Account successfully deleted!");
            window.sessionStorage.clear();
            setTimeout(() => {
                window.reload();
            }, 3500);
        }).catch(err=>{
            setGoodbyeMsg("Unable to delete your account");
            toast.error('Unable to delete your account');
        });
    }
    return (
        <React.Fragment>
            <ToastContainer />
            {
                deletePopupState && (
                    <div onClick={hideDeletePopup} style={{backgroundColor: "#00000090", zIndex: 100}} className="close-overlay w-full flex items-center justify-center h-screen fixed top-0 left-0">
                        {
                            !deleteNow && (
                                <div className="w-2/5 flex flex-col items-center py-10 h-auto rounded-lg shadow bg-white ...">
                            
                                    <FailedIcon />
                                    <p className="mt-5">You are about to delete your account.</p>
                                    <p className="text-primary-theme">Are  you sure about this?</p>
                                    <div className="flex justify-between gap-5 mt-5">
                                        <button onClick={hideDeletePopup} className="close-overlay py-2 rounded px-4 bg-gray-100 border border-gray-200">Cancel</button>
                                        <button onClick={deleteAccount} className="py-2 rounded px-4 bg-primary-theme text-white">Delete</button>
                                    </div>
                                </div>
                            )
                        }{
                            deleteNow && (
                                <div className="w-2/5 flex flex-col items-center py-10 h-auto rounded-lg shadow bg-white ...">        
                                    <p style={{borderTop: "16px solid transparent"}} className="w-12 h-12 rounded-full border-4 border-primary-theme animate-spin flex"></p>
                                    <p className="text-primary-theme mt-5">{goodbyeMsg}</p>
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div className="relative z-10">
                <h5 className="text-center font-medium my-5">Account Settings</h5>
                <div className="w-full relative h-auto pb-20 bg-white rounded grid grid-cols-2">
                    <div className="flex px-4 justify-center items-center flex-col pt-12">
                        {/* <span className="avatar w-24 h-24 rounded-full flex text-gray-600 text-3xl font-semibold items-center justify-center">
                            {nameAcronym}
                        </span> */}

                        <div className="w-20 overflow-hidden h-20 mt-5 mb-5 rounded-full bg-primary-theme text-white font-semibold flex items-center justify-center">
                            {imageAvail ? <img className="w-full h-full rounded-full" src={pix}/> : nameAcronym}
                        </div>

                        <h5 className="text-lg font-medium mt-2">{accountDetails?.acct_name}</h5>
                        <p className="text-gray-400 text-md">Acct: {accountDetails?.accountNo}</p>
                        <p className="text-gray-700 text-xs mb-5">{accountDetails?.bankName}</p>
                        <InnerOption title="Edit Profile" link="/setting/profile/edit" />
                        <InnerOption title="View Transaction" link="/transactions" />
                        <InnerOption title="Change Pin" link="/setting/profile/change-pin" />
                        <div className="w-full flex justify-between">
                            <p className="flex items-center gap-2">
                                <QuestiontagIcon />
                                <span className="font-semibold text-sm">Two-factor Auth</span>
                            </p>
                            <div className="flex items-center gap-1">
                                <label className={`${styles.custom_switch} relative w-12 h-6 rounded-full bg-gray-400`}>
                                    <input type="checkbox" onChange={toggle2fa} checked={tfa} className="hidden" />
                                    <span className={`${styles.circle} w-4 h-4 left-1 bg-black rounded-lg block absolute`}></span>
                                </label>
                                <span className={`text-sm ${tfa ? 'text-green-500' : 'text-gray-400'}`}>{tfa ? 'Enabled' : 'Disabled'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-col items-end px-5 pt-8">
                        <p className="flex">
                            <MedalIcon />
                            <span className="text-xs text-right">T1: <Link to="/submit-kyc" className="text-primary-theme">Upgrade</Link> your account to
                                <br /> do larger transactions</span>
                        </p>
                        <div className="card w-full px-4 py-5 mt-2 h-32 rounded border border-gray-200 mb-3 ...">
                            <div className="flex justify-between">
                                <h3 className="text-lg font-semibold">Invite & Earn</h3>
                                <h5 className="text-sm font-medium">Amount Earn:</h5>
                            </div>
                            <div className="flex justify-between items-start mt-2">
                                <Link to="/setting/refferal" className="bg-primary-theme py-3 px-2 text-white rounded-lg text-xs">
                                    View Refferal Code
                                </Link>
                                <h3 className="text-lg w-32 font-semibold">₦{!balVisible ? accountDetails?.clr_bal_amt >0 ? accountDetails?.clr_bal_amt: "0.00" : "****"}</h3>
                            </div>
                        </div>
                        <InnerOption2 status={!balVisible} action={toggleVisibility} title="Hide Balance" />
                        <InnerOption title="Change Password" link="/setting/profile/change-password" />
                    </div>

                    <p className="absolute bottom-4 left-1/2 -ml-12 text-primary-theme cursor-pointer text-sm ..." onClick={showDeletePopup}>Delete my account</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProfileTab
