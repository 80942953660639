import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { deleteTeamMemberRole } from "../../services/requests/roles";
import { toast } from "react-toastify";
import { getCurrentMerchant } from "../../services/requests/customers";

function DeleteInvitedUser({ selectedDataToDelete, setPopup }) {
  const [currentMerchant, setCurrentMerchant] = useState();
  const [merchantUserId, setMerchantUserId] = useState();

  console.log('selected',selectedDataToDelete)

 useEffect(() => {
    getCurrentMerchant().then((data) => {
      setCurrentMerchant(data?.data?.merchantId);
      setMerchantUserId(data?.data?.userId);
    });
  }, []);


  const handleDeleteUser = ()=>{
    const TeamMemberUserId = selectedDataToDelete?.id;
    deleteTeamMemberRole(TeamMemberUserId)
      .then((data) => {
            if (data?.status === false) {
              toast.error("Unable to Update");
            } else {
              toast.success(data.message);
              setPopup("successful");
            }
          })
          .catch((error) => {
            toast.error("Unable to Update");
          });
  }
  return (
    <Wrapper>
      <HeadContainer>
        <p className="role-header-text">Remove Team Member</p>
      </HeadContainer>
      <Container>
        {" "}
        <p className="text-sm text-center mb-3 text-black w-full mx-auto">
          By doing this, <span className="team-member">{selectedDataToDelete?.fullName} </span>will no longer be able to
          access this business anymore. Do you want to continue?
        </p>
        <button
          onClick={handleDeleteUser}
          className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded"
        >
          Yes, Remove
        </button>
      </Container>
    </Wrapper>
  );
}

export default DeleteInvitedUser;

export const Wrapper = styled.div``;
const Container = styled.div`
  width: 90%;
  margin: auto;
  .team-member{
    font-weight: 700;
  }
`;

export const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-top: 1rem;
  }
`;
