import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { TextInputWithIcon } from "../../form/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SelectInputWithIcon } from "../../form/SelectInput";
import { updateProfile } from "../../../services/requests/profile";
import { Flex, Progress } from "antd";
import { ArrowForward } from "@material-ui/icons";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../../config";
import {
  CloseIcon,
  AndroidIcon,
  IOSIcon,
  LoaderIcon,
  SuccessIcon,
} from "../../../assets";
import { ultaPayload } from "../components/constant";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const registrationTypes = [
  { label: "Incorporated Company", value: "INCORPORATED_COMPANY" },
  { label: "Incorporated Trustees", value: "INCORPORATED_TRUSTEES" },
  { label: "Business name registration", value: "BUSINESS_NAME_REGISTRATION" },
];

const businessTypes = [
  { value: "REGISTERED_BUSINESS", label: "REGISTERED BUSINESS" },
  { value: "NON_REGISTERED_BUSINESS", label: "NON REGISTERED BUSINESS" },
];

const schema = yup.object({
  businessName: yup.string().required("Business Name is required"),
  businessEmail: yup.string().email().required("Business Email is required"),
  businessPhoneNumber: yup
    .string()
    .matches(/.{10,}/, {
      excludeEmptyString: true,
      message: "Must be 10 characters",
    })
    .max(10)
    .required("Business Phone is required"),
  businessDescription: yup
    .string()
    .required("Business Description is required"),
  businessIndustry: yup.object().required("Industry is required"),
  businessType: yup.object().required("Business Type is required"),
  // businessRegistrationType: yup
  //   .object()
  //   .nullable()
  //   .notRequired()
  //   .when("businessType", {
  //     is: (value) => {
  //       return value?.value === "REGISTERED_BUSINESS";
  //     },
  //     then: () =>
  //       yup
  //         .object({
  //           value: yup.string().required("Value is required"),
  //           label: yup.string().required("Label is required"),
  //         })
  //         .required("Business Registration Type is required"),
  //     otherwise: () =>
  //       yup
  //         .object({
  //           value: yup.string().nullable(),
  //           label: yup.string().nullable(),
  //         })
  //         .nullable()
  //         .notRequired(),
  //   }),


  businessRegistrationNumber: yup
    .string()
    .nullable()
    .notRequired()
    .when("businessType", {
      is: (value) => {
        return value?.value === "REGISTERED_BUSINESS";
      },
      then: (rule) => {
        return rule.length(6);
      },
    }),
});
// .required();

export const BusinessDetails = ({
  formValues,
  navigateForward,
  initialData,
}) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      businessName: initialData?.businessName ?? "",
      businessEmail: initialData?.businessEmail ?? "",
      businessPhoneNumber: initialData?.businessPhoneNumber ?? "",
      businessDescription: initialData?.businessDescription ?? "",
      businessIndustry: initialData?.businessIndustry ?? "",
      businessType: initialData?.businessType ?? "",
      businessRegistrationType: initialData?.businessRegistrationType ?? "",
      businessRegistrationNumber: initialData?.businessRegistrationNumber ?? "",
    },
    resolver: yupResolver(schema),
  });
  const uploadLogo = useRef();
  const [businessLogo, setBusinessLogo] = useState("");
  const [uploadedBusinessLogoUrl, setUploadedBusinessLogoUrl] = useState();
  const [industryListObj, setIndustryListObj] = useState(null);
  const [industryList, setIndustryList] = useState([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState(
    "REGISTERED BUSINESS"
  );
  const [userData, setUserData] = useState();
  const [kycInfo, setKYCInfo] = useState({
    appLink: "",
    websiteLink: "",
    usePaymentLink: true,
  });

  const uploadImage = () => {
    uploadLogo.current.click();
  };
  const loadLogo = () => {
    const logo = uploadLogo.current.files[0];
    if (logo.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (uploadLogo.current.files && uploadLogo.current.files.length) {
      setBusinessLogo(URL.createObjectURL(logo));

      // upload the business logo
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", logo);
      payload.append("fileName", "BUSINESS_LOGO");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );

      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        // ${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/upload
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("New credentials generated.");
            setUploadedBusinessLogoUrl(resp.data.data);
            // setKYCInfo({
            //   ...KYCInfo,
            //   businessLogoUrl: resp.data.data,
            // });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const getIndustryList = async () => {
    try {
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const userInfo = JSON.parse(sessionStorage.getItem("userData"));
      setUserData(userInfo);

      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };

      // Fetch industry list
      const industryResponse = await axios.get(
        `${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/business-industry`,
        header
      );
      let lists = [];
      setIndustryListObj(industryResponse.data.data);
      industryResponse.data.data.forEach((elem, index) => {
        lists.push({
          label: elem.name,
          value: index + 1,
        });
      });
      setIndustryList(lists);
    } catch (error) {
      console.log("Error fetching data:", error);
      toast.error("An error occurred while fetching data.");
    }
  };

  useEffect(() => {
    getIndustryList();
  }, []);

  const onSubmit = (data) => {
    const payload = {
      ...kycInfo,
      ...formValues?.personalDetails,
      ...formValues?.contactVerification,
      businessName: data?.businessName,
      businessEmail: data?.businessEmail,
      businessPhoneNumber: "234" + data?.businessPhoneNumber,
      businessDescription: data?.businessDescription,
      businessIndustry: data?.businessIndustry?.value,
      businessType: data?.businessType?.value,
      businessRegistrationType: data?.businessRegistrationType?.value,
      businessRegistrationNumber: data?.businessRegistrationNumber,
    };
    const sendPayload = {
      ...ultaPayload,
      ...payload,
      merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
    };
    navigateForward(payload);
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };

    axios
      .post(
        `${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`,
        sendPayload,
        header
      )
      .then((resp) => {
        toast.success("Data Stored successfully");
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };
  return (
    <Wrapper>
      <BusinessLogoContainer>
        <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center">
          <div className="w-28 h-28 flex items-center justify-center text-white relative bg-gray-200 font-semibold text-lg rounded-full">
            {businessLogo ? (
              <img
                src={businessLogo}
                alt=""
                className="w-full h-full rounded-full border border-gray-100"
              />
            ) : (
              "RD"
            )}
          </div>
          <h5 className="text-sm mt-3 text-gray-500">Upload business logo</h5>
          <input
            type="file"
            onChange={loadLogo}
            className="hidden"
            ref={uploadLogo}
          />
          <button
            onClick={uploadImage}
            className="px-5 py-2 rounded hover:shadow border border-gray-100 text-sm font-medium mt-2"
          >
            Upload Image
          </button>
        </div>
      </BusinessLogoContainer>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
          <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
            <h4 className="font-semibold text-lg text-gray-600">
              Business Information
            </h4>
            <p className="text-gray-500 text-sm">
              We need a few more details about you and your business to give you
              the best service.
            </p>
            <Controller
              name="businessName"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"Business Name"}
                  isRequired
                  placeholder={"Austin Autos"}
                  error={errors?.businessName?.message}
                />
              )}
            />
            <Controller
              name="businessEmail"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"Business Email"}
                  isRequired
                  placeholder={"austin@example.com"}
                  error={errors?.businessEmail?.message}
                />
              )}
            />

            <Controller
              name="businessPhoneNumber"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  type={"number"}
                  isRequired
                  label={"Business Phone Number"}
                  placeholder={"8012345678"}
                  onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                  pretext={"+234"}
                  error={errors?.businessPhoneNumber?.message}
                />
              )}
            />
            <Controller
              name="businessDescription"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"Business Description"}
                  isRequired
                  placeholder={"Austin Autos"}
                  error={errors?.businessDescription?.message}
                />
              )}
            />
            <Controller
              name="businessIndustry"
              control={control}
              render={({ field }) => (
                <SelectInputWithIcon
                  {...field}
                  label={"Type of Industry"}
                  isRequired
                  placeholder={"Select Industry Type"}
                  options={industryList}
                  error={errors?.businessIndustry?.message}
                />
              )}
            />
            <Controller
              name="businessType"
              control={control}
              render={({ field }) => (
                <SelectInputWithIcon
                  {...field}
                  label={"Type of Business"}
                  isRequired
                  placeholder={"Select Business Type"}
                  onChange={(e) => {
                    field.onChange(e);
                    setSelectedBusinessType(e.value);
                  }}
                  options={businessTypes}
                  error={errors?.businessType?.message}
                />
              )}
            />
            <p className="text-gray-400 text-xs mt-3">
              Upload valid business registration documents and get access to
              full features.{" "}
              <span className="text-primary-theme underline">Learn more.</span>
            </p>
          </div>

          {selectedBusinessType === "REGISTERED_BUSINESS" && (
            //{/* Conditional Tax */}
            <>
              <Controller
                name="businessRegistrationType"
                control={control}
                render={({ field }) => (
                  <SelectInputWithIcon
                    {...field}
                    label={"Business Registration Type"}
                    isRequired
                    placeholder={"Select Business Registration Type"}
                    options={registrationTypes}
                    error={errors?.businessRegistrationType?.message}
                  />
                )}
              />

              {/* <TextInputWithIcon
                value={businessRegNum ?? ""}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (inputValue.length <= 6) {
                    setBusinessRegNum(e.target.value);
                  }
                }}
                label={"Business Registration Number"}
                type="number"
                pattern="\d{6}"
                placeholder="123456"
              /> */}
              <Controller
                name="businessRegistrationNumber"
                control={control}
                render={({ field }) => (
                  <TextInputWithIcon
                    {...field}
                    label={"Business Registration Number"}
                    type={"number"}
                    isRequired
                    onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                    placeholder={"Enter Business Registration Number"}
                    error={errors?.businessRegistrationNumber?.message}
                  />
                )}
              />

              <h4 className="font-semibold text-lg text-gray-600 mt-8">
                How you wish to accept payment
              </h4>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={kycInfo?.usePaymentLink === true ? "yes" : "no"}
                  onChange={(e) => {
                    setKYCInfo({
                      ...kycInfo,
                      usePaymentLink: e.target.value === "yes" ? true : false,
                    });
                  }}
                >
                  <FormControlLabel
                    value={"no"}
                    control={<Radio />}
                    label="Without Website/App"
                  />
                  {/* ********* */}
                  {!kycInfo?.usePaymentLink && (
                    <>
                      <div className="my-3 pl-4 flex items-center gap-2">
                        <input
                          type="checkbox"
                          className="rounded-lg px-2 border border-gray-200 text-sm"
                        />
                        <label className="text-sm font-medium">
                          {" "}
                          Receive payments from your customers in under 5
                          minutes using Wayapaychats payment Links
                          <span className="text-red-500"></span>
                        </label>
                      </div>
                      <div className="my-3 pl-4 flex items-center gap-2">
                        <input
                          type="checkbox"
                          className="rounded-lg px-2 border border-gray-200 text-sm"
                        />
                        <label className="text-sm font-medium">
                          {" "}
                          You can subimt your website/app later if you wish to
                          use it to accept payments.
                          <span className="text-red-500"></span>
                        </label>
                      </div>
                    </>
                  )}

                  {/* ********* */}

                  <FormControlLabel
                    value={"yes"}
                    control={<Radio />}
                    label="With Website/App"
                  />

                  {/* ******** */}
                  {kycInfo?.usePaymentLink && (
                    <>
                      <div className="my-3 pl-6">
                        <label className="text-sm font-medium">
                          {" "}
                          Accept payment on website.
                          <span className="text-red-500"></span>
                        </label>
                        <input
                          value={kycInfo?.websiteLink}
                          onChange={(e) => {
                            setKYCInfo({
                              ...kycInfo,
                              websiteLink: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Enter website URL/Link"
                          className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm"
                        />
                      </div>
                      <div className="my-3 pl-6">
                        <label className="text-sm font-medium">
                          {" "}
                          Accept payment on App.
                          <span className="text-red-500"></span>
                        </label>
                        <input
                          value={kycInfo?.appLink}
                          onChange={(e) => {
                            setKYCInfo({
                              ...kycInfo,
                              appLink: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Enter website Link"
                          className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm"
                        />
                      </div>
                    </>
                  )}
                  {/* ****** */}
                </RadioGroup>
              </FormControl>

              <p className="pl-6 text-sm">
                We need to verify your website/app to provide you the live API
                KEYS. It should contain:
              </p>

              <div className="flex pl-6 flex-wrap justify-between">
                <li className="text-xs">About Us</li>
                <li className="text-xs">Contact Us</li>
                <li className="text-xs">Pricing</li>
                <li className="text-xs">Privacy policy</li>
                <li className="text-xs">Terms & conditions</li>
                <li className="text-xs">Cancellation/Refund policy</li>
              </div>

              <h4 className="font-semibold text-xl text-gray-600 mt-8">
                Social Media URL
              </h4>
              <div className="my-3">
                <label className="text-sm font-medium">
                  {" "}
                  Facebook<span className="text-red-500"></span>
                </label>
                <input
                  value={kycInfo?.facebookUrl}
                  onChange={(e) => {
                    setKYCInfo({
                      ...kycInfo,
                      facebookUrl: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="https://www.facebook.com"
                  className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm"
                />
              </div>
              <div className="my-3">
                <label className="text-sm font-medium">
                  {" "}
                  Instagram<span className="text-red-500"></span>
                </label>
                <input
                  value={kycInfo?.instagramUrl}
                  onChange={(e) => {
                    setKYCInfo({
                      ...kycInfo,
                      instagramUrl: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="https://www.instagram.com"
                  className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm"
                />
              </div>
              <div className="my-3">
                <label className="text-sm font-medium">
                  {" "}
                  Twitter<span className="text-red-500"></span>
                </label>
                <input
                  value={kycInfo?.twitterUrl}
                  onChange={(e) => {
                    setKYCInfo({
                      ...kycInfo,
                      twitterUrl: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="https://www.twitter.com"
                  className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm"
                />
              </div>
            </>
          )}

          <button
            type="submit"
            className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm"
          >
            Save & Continue
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Form>

      <StepperInfo>
        <div className="w-full col-span-2 flex flex-col items-center">
          <Flex vertical gap="small">
            <Progress
              strokeColor="#ff6700"
              showInfo={false}
              strokeLinecap="butt"
              type="circle"
              percent={60}
            />
            <h6>3 of 5 complete</h6>
          </Flex>

          <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
            <button className="absolute -right-3 -top-3">
              <CloseIcon />
            </button>
            <h5 className="text-xl font-semibold">Download our App</h5>
            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">
              Download our mobile application for better experience and to
              receive custom notifications in real time.{" "}
            </p>
            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
              <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                <button className="mx-2">
                  <AndroidIcon />
                </button>
                <button className="mx-2">
                  <IOSIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </StepperInfo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;
`;

const BusinessLogoContainer = styled.div`
  width: 30%;
  margin-right: 10%;
`;

const Form = styled.form`
  width: 70%;
`;

const StepperInfo = styled.div`
  width: 30%;
  margin-left: 10%;
`;
