import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Flex, Progress } from "antd";
import { CloseIcon, AndroidIcon, IOSIcon } from "../../../assets";
import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { SelectInputWithIcon } from "../../form/SelectInput";
import { TextInputWithIcon } from "../../form/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// .required();

const identificationTypes = [
  //   {
  //     value: "VOTERS_CARD",
  //     label: "VOTERS_CARD",
  //   },
  {
    value: "NATIONAL_ID",
    label: "NATIONAL_ID",
  },
  //   {
  //     value: "DRIVER_LICENCE",
  //     label: "DRIVER_LICENCE",
  //   },
  //   {
  //     value: "INTERNATIONAL_PASSPORT",
  //     label: "INTERNATIONAL_PASSPORT",
  //   },
];

const businessProofTypes = [
  {
    value: "UTILITY_BILL",
    label: "UTILITY_BILL",
  },
];

export const CertificateUpload = ({
  formValues,
  navigateForward,
  initialData,
}) => {
  const schema = yup.object({
    businessAddressProveType: yup
      .object()
      .required("Business Address Proof is required"),
    identificationType: yup.object().required("Identification is required"),
    tin: yup
      .string()
      .nullable()
      .notRequired()
      .when("businessDetails.businessType", {
        is: (value) => {
          return formValues?.businessDetails?.businessType === "REGISTERED_BUSINESS"
        },
        then: (rule) => {
          return rule.length(9);
        },
      }),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      businessAddressProveType: "UTILITY_BILL",
      identificationType: "NATIONAL_ID",
      tin: initialData?.tin ?? "",
      memartCertificateUrl: initialData?.memartCertificateUrl ?? "",
      businessAddressProveUrl: initialData?.businessAddressProveUrl ?? "",
      identificationUrl: initialData?.identificationUrl ?? "",
      incorporationCertificateUrl:
        initialData?.incorporationCertificateUrl ?? "",
    },
    resolver: yupResolver(schema),
  });
  const identificationTypeRef = useRef();
  const businessAddressProveTypeRef = useRef();
  const businessCertificateTypeRef = useRef();
  const businessRegistrationTypeRef = useRef();
  const uploadCertOfIncRef = useRef();
  const uploadCertOfBusNameRef = useRef();
  const uploadCacLtRef = useRef();
  const uploadBNCacRef = useRef();
  const memartRef = useRef();
  const [kycInfo, setKycInfo] = useState();
  const [tinNumber, setTinNumber] = useState();

  const uploadMemart = () => {
    const file = memartRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (memartRef.current.files && memartRef.current.files.length) {
      // upload memart document
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "MEMART");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");
            setKycInfo({
              ...kycInfo,
              memartCertificateUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const uploadCertOfInc = () => {
    const file = uploadCertOfIncRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (
      uploadCertOfIncRef.current.files &&
      uploadCertOfIncRef.current.files.length
    ) {
      // upload the business logo
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "INCORPORATION_CERTIFICATE");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");

            setKycInfo({
              ...kycInfo,
              incorporationCertificateUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const uploadCacLt = () => {
    const file = uploadCacLtRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (
      uploadCacLtRef.current.files &&
      uploadCacLtRef.current.files.length
    ) {
      // upload the business logo
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "CAC_IT");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");

            setKycInfo({
              ...kycInfo,
              cacltCertificateUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const uploadCertOfBusName = () => {
    const file = uploadCertOfBusNameRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (
      uploadCertOfBusNameRef.current.files &&
      uploadCertOfBusNameRef.current.files.length
    ) {
      // upload the business logo
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "BUSINESS_NAME_CERTIFICATE");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");

            setKycInfo({
              ...kycInfo,
              businessNameCertificateUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const uploadBNCac = () => {
    const file = uploadBNCacRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (
      uploadBNCacRef.current.files &&
      uploadBNCacRef.current.files.length
    ) {
      // upload the business logo
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "CAC_BN");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");

            setKycInfo({
              ...kycInfo,
              cacBnCertificateUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  // *****************************

  const uploadAndUpdateBusinessAddr = () => {
    const file = businessAddressProveTypeRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (
      businessAddressProveTypeRef.current.files &&
      businessAddressProveTypeRef.current.files.length
    ) {
      // upload the business logo
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "BUSINESS_ADDRESS_PROVE");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");

            setKycInfo({
              ...kycInfo,
              businessAddressProveUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const uploadAndUpdateID = () => {
    const file = identificationTypeRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (
      identificationTypeRef.current.files &&
      identificationTypeRef.current.files.length
    ) {
      // upload the business logo
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "ID_CARD");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_WAYA}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");

            setKycInfo({
              ...kycInfo,
              identificationUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const newPayload = {
    appLink: formValues?.businessDetails?.appLink || "",
    approvalStatus: "PENDING",
    businessAccountName: formValues?.businessDetails?.businessAccountName || "",
    businessAccountNumber:
      formValues?.businessDetails?.businessAccountNumber || "",
    businessAccountProvider:
      formValues?.businessDetails?.businessAccountProvider || "",
    businessAddressProveType: "UTILITY_BILL",
    businessAddressProveUr: "",
    businessDescription: formValues?.businessDetails?.businessDescription || "",
    businessEmail: formValues?.businessDetails?.businessEmail || "",
    businessIndustry: formValues?.businessDetails?.businessIndustry || "",
    businessLogoUrl: "",
    businessName: formValues?.businessDetails?.businessName || "",
    businessNameCertificateUrl: null,
    businessNationality: "",
    businessPhoneNumber: formValues?.businessDetails?.businessPhoneNumber || "",
    businessRegistrationNumber:
      formValues?.businessDetails?.businessRegistrationNumber || "",
    businessRegistrationType:
      formValues?.businessDetails?.businessRegistrationType || "",
    businessType: formValues?.businessDetails?.businessType || "",
    bvnApproved: false,
    bvnNumber: formValues?.contactVerification?.userBvnNumber || "",
    cacBnCertificateUrl: null,
    cacItCertificateUrl: null,
    city: formValues?.personalDetails?.city || "",
    country: formValues?.personalDetails?.country || "",
    createdAt: "",
    dateDeleted: null,
    deleted: false,
    deletedBy: null,
    directorFirstName: formValues?.directorDetails?.directorFirstName,
    directorLastName: formValues?.directorDetails?.directorLastName,
    directorNinNumber: formValues?.directorDetails?.directorNinNumber,
    directorBvnNumber: formValues?.directorDetails?.directorBvnNumber,
    directorNinImageUrl: formValues?.directorDetails?.directorNinImageUrl,
    directorPhotoUrl: formValues?.directorDetails?.directorPhotoUrl,
    draft: false,
    email: formValues?.personalDetails?.email || "",
    facebookUrl: formValues?.businessDetails?.facebookUrl || "",
    firstName: formValues?.personalDetails?.firstName || "",
    identificationType: "NATIONAL_ID",
    identificationUrl: "",
    incorporationCertificateUrl: "",
    //businessDetails
    instagramUrl: formValues?.businessDetails?.instagramUrl || "",
    lastName: formValues?.personalDetails?.lastName || "",
    lga: formValues?.personalDetails?.lga || "",
    memartCertificateUrl: "",
    merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
    ninApproved: false,
    ninNumber: formValues?.contactVerification?.userNinNumber || "",
    reasonForRejection: null,
    state: formValues?.personalDetails?.state || "",
    statusLastModifiedOn: null,
    statusModifiedBy: "",
    streetAddress: formValues?.personalDetails?.streetAddress || "",
    tin: "",
    twitterUrl: formValues?.businessDetails?.twitterUrl || "",
    updatedAt: "",
    usePaymentLink: formValues?.businessDetails?.usePaymentLink || "",
    userBvnNumber: formValues?.contactVerification?.userBvnNumber || "",
    userId: null,
    userNinNumber: formValues?.contactVerification?.userNinNumber || "",
    websiteLink: formValues?.businessDetails?.websiteLink || "",
  };
  const onSubmit = (data) => {
    const uploadData = {
      ...newPayload,
      tin: data?.tin,
      identificationType: data?.identificationType?.value,
      businessAddressProveType: data?.businessAddressProveType?.value,
      businessAddressProveUrl: kycInfo?.businessAddressProveUrl,
      identificationUrl: kycInfo?.identificationUrl,
      incorporationCertificateUrl: kycInfo?.incorporationCertificateUrl,
      memartCertificateUrl: kycInfo?.memartCertificateUrl,
    };
    const finalUploadData = {
      ...uploadData,
      merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
    };

    navigateForward(uploadData);
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    axios
      .post(
        `${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`,
        finalUploadData,
        header
      )
      .then((resp) => {
        toast.success("Data Stored successfully");
        // Since we are now on the final tab, Setting the draft status to false is key
        // setKYCInfo({
        //   ...KYCInfo,
        //   draft: false,
        // });
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };
  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
          <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
            <h4 className="font-semibold text-lg text-gray-600">
              Upload Relevant Certificate
            </h4>
            <p className="text-gray-500 text-sm">
              We need the following information to verify your account (File
              format supported pdf only).
            </p>

            {formValues?.businessDetails?.businessRegistrationType ===
              "INCORPORATED_COMPANY" &&
              formValues?.businessDetails?.businessType ===
                "REGISTERED_BUSINESS" && (
                <>
                  <div className="mt-6">
                    <label className="text-sm font-medium">
                      Memart <span className="text-red-500">*</span>
                    </label>
                    <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                      <input
                        onChange={uploadMemart}
                        ref={memartRef}
                        type="file"
                        className="hidden"
                      />
                      <button
                        type="button"
                        className="text-xs px-3 py-2 rounded bg-white"
                        onClick={() => memartRef.current.click()}
                      >
                        Upload a file
                      </button>

                      {kycInfo?.memartCertificateUrl && (
                        <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                          Document uploaded
                          <p>
                            <a
                              href={kycInfo?.memartCertificateUrl}
                              target="_blank"
                              className="cursor-pointer text-blue-500 text-xs mx-4"
                              rel="noreferrer noopener"
                            >
                              View
                            </a>
                            <span
                              onClick={() => {
                                setKycInfo({
                                  ...kycInfo,
                                  memartCertificateUrl: "",
                                });
                              }}
                              className="cursor-pointer text-red-500 text-xs mx-4"
                            >
                              X
                            </span>
                          </p>
                        </label>
                      )}
                    </div>
                  </div>

                  {formValues?.businessDetails?.businessType ===
                    "REGISTERED_BUSINESS" && (
                    <div className="mt-6">
                      <label className="text-sm font-medium">
                        Certficate of Incorporation{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                        <input
                          onChange={uploadCertOfInc}
                          ref={uploadCertOfIncRef}
                          type="file"
                          className="hidden"
                        />
                        <button
                          type="button"
                          className="text-xs px-3 py-2 rounded bg-white"
                          onClick={() => uploadCertOfIncRef.current.click()}
                        >
                          Upload a file
                        </button>

                        {kycInfo?.incorporationCertificateUrl && (
                          <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                            Document uploaded
                            <p>
                              <a
                                href={kycInfo?.incorporationCertificateUrl}
                                target="_blank"
                                className="cursor-pointer text-blue-500 text-xs mx-4"
                                rel="noreferrer noopener"
                              >
                                View
                              </a>
                              <span
                                onClick={() => {
                                  setKycInfo({
                                    ...kycInfo,
                                    incorporationCertificateUrl: "",
                                  });
                                }}
                                className="cursor-pointer text-red-500 text-xs mx-4"
                              >
                                X
                              </span>
                            </p>
                          </label>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

            {formValues?.businessDetails?.businessRegistrationType ===
              "INCORPORATED_TRUSTEES" && (
              <div className="mt-6">
                <label className="text-sm font-medium">
                  Form CAC IT/1 <span className="text-red-500">*</span>
                </label>
                <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                  <input
                    onChange={uploadCacLt}
                    ref={uploadCacLtRef}
                    type="file"
                    className="hidden"
                  />
                  <button
                    type="button"
                    className="text-xs px-3 py-2 rounded bg-white"
                    onClick={() => uploadCacLtRef.current.click()}
                  >
                    Upload a file
                  </button>

                  {kycInfo?.cacltCertificateUrl && (
                    <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                      Document uploaded
                      <p>
                        <a
                          href={kycInfo?.cacltCertificateUrl}
                          rel="noreferrer"
                          target="_blank"
                          className="cursor-pointer text-blue-500 text-xs mx-4"
                        >
                          View
                        </a>
                        <span
                          onClick={() => {
                            setKycInfo({
                              ...kycInfo,
                              cacltCertificateUrl: "",
                            });
                          }}
                          className="cursor-pointer text-red-500 text-xs mx-4"
                        >
                          X
                        </span>
                      </p>
                    </label>
                  )}
                </div>
              </div>
            )}

            {formValues?.businessDetails?.businessRegistrationType ===
              "BUSINESS_NAME_REGISTRATION" && (
              <>
                <div className="mt-6">
                  <label className="text-sm font-medium">
                    Certificate of Business Name{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                    <input
                      onChange={uploadCertOfBusName}
                      ref={uploadCertOfBusNameRef}
                      type="file"
                      className="hidden"
                    />
                    <button
                      type="button"
                      className="text-xs px-3 py-2 rounded bg-white"
                      onClick={() => uploadCertOfBusNameRef.current.click()}
                    >
                      Upload a file
                    </button>

                    {kycInfo?.businessNameCertificateUrl && (
                      <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                        Document uploaded
                        <p>
                          <a
                            href={kycInfo?.businessNameCertificateUrl}
                            target="_blank"
                            className="cursor-pointer text-blue-500 text-xs mx-4"
                            rel="noreferrer noopener"
                          >
                            View
                          </a>
                          <span
                            onClick={() => {
                              setKycInfo({
                                ...kycInfo,
                                businessNameCertificateUrl: "",
                              });
                            }}
                            className="cursor-pointer text-red-500 text-xs mx-4"
                          >
                            X
                          </span>
                        </p>
                      </label>
                    )}
                  </div>
                </div>

                <div className="mt-6">
                  <label className="text-sm font-medium">
                    Form CAC BN 1 <span className="text-red-500">*</span>
                  </label>
                  <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                    <input
                      onChange={uploadBNCac}
                      ref={uploadBNCacRef}
                      type="file"
                      className="hidden"
                    />
                    <button
                      type="button"
                      className="text-xs px-3 py-2 rounded bg-white"
                      onClick={() => uploadBNCacRef.current.click()}
                    >
                      Upload a file
                    </button>

                    {kycInfo?.cacBnCertificateUrl && (
                      <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                        Document uploaded
                        <p>
                          <a
                            href={kycInfo?.cacBnCertificateUrl}
                            target="_blank"
                            className="cursor-pointer text-blue-500 text-xs mx-4"
                            rel="noreferrer noopener"
                          >
                            View
                          </a>
                          <span
                            onClick={() => {
                              setKycInfo({
                                ...kycInfo,
                                cacBnCertificateUrl: "",
                              });
                            }}
                            className="cursor-pointer text-red-500 text-xs mx-4"
                          >
                            X
                          </span>
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* Conditional Tax */}
            {formValues?.businessDetails?.businessType ===
              "REGISTERED_BUSINESS" && (
              <>
                {/* <TextInputWithIcon
                  value={tinNumber ?? ""}
                  label={"Tax Identification Number (TIN)"}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (inputValue.length <= 9) {
                      setTinNumber(e.target.value);
                    }
                  }}
                  type="number"
                  pattern="\d{9}"
                  placeholder="123456789"
                /> */}

                <Controller
                  name="tin"
                  control={control}
                  render={({ field }) => (
                    <TextInputWithIcon
                      {...field}
                      label={"Tax Identification Number"}
                      type={"number"}
                      isRequired
                      onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                      placeholder={"123456789"}
                      error={errors?.tin?.message}
                    />
                  )}
                />
              </>
            )}
            <div className="mt-2">
              <Controller
                name="identificationType"
                control={control}
                render={({ field }) => (
                  <SelectInputWithIcon
                    {...field}
                    label={"Select any Govenrment Approved ID"}
                    isRequired
                    placeholder={"Select ID Type"}
                    options={identificationTypes}
                    error={errors?.identificationType?.message}
                  />
                )}
              />

              <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                <input
                  onChange={uploadAndUpdateID}
                  ref={identificationTypeRef}
                  type="file"
                  className="hidden"
                />
                <button
                  type="button"
                  className="text-xs px-3 py-2 rounded bg-white"
                  onClick={() => identificationTypeRef.current.click()}
                >
                  Upload a file
                </button>

                {kycInfo?.identificationUrl && (
                  <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                    Document uploaded
                    <p>
                      <a
                        href={kycInfo?.identificationUrl}
                        target="_blank"
                        className="cursor-pointer text-blue-500 text-xs mx-4"
                        rel="noreferrer noopener"
                      >
                        View
                      </a>
                      <span
                        onClick={() => {
                          setKycInfo({
                            ...kycInfo,
                            identificationUrl: "",
                          });
                        }}
                        className="cursor-pointer text-red-500 text-xs mx-4"
                      >
                        X
                      </span>
                    </p>
                  </label>
                )}
              </div>
            </div>

            <div className="mt-2">
              <Controller
                name="businessAddressProveType"
                control={control}
                render={({ field }) => (
                  <SelectInputWithIcon
                    {...field}
                    label={
                      "Proof of Business Operating address for the Company"
                    }
                    isRequired
                    placeholder={"Select Business Registration Type"}
                    options={businessProofTypes}
                    error={errors?.businessAddressProveType?.message}
                  />
                )}
              />
              <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                <input
                  onChange={uploadAndUpdateBusinessAddr}
                  ref={businessAddressProveTypeRef}
                  type="file"
                  className="hidden"
                />
                <button
                  type="button"
                  className="text-xs px-3 py-2 rounded bg-white"
                  onClick={() => businessAddressProveTypeRef.current.click()}
                >
                  Upload a file
                </button>

                {kycInfo?.businessAddressProveUrl && (
                  <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                    Document uploaded
                    <p>
                      <a
                        href={kycInfo?.businessAddressProveUrl}
                        target="_blank"
                        className="cursor-pointer text-blue-500 text-xs mx-4"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                      <span
                        onClick={() => {
                          setKycInfo({
                            ...kycInfo,
                            businessAddressProveUrl: "",
                          });
                        }}
                        className="cursor-pointer text-red-500 text-xs mx-4"
                      >
                        X
                      </span>
                    </p>
                  </label>
                )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm"
          >
            Save & Continue
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Form>

      <StepperInfo>
        <div className="w-full col-span-2 flex flex-col items-center">
          <Flex vertical gap="small">
            <Progress
              strokeColor="#ff6700"
              showInfo={false}
              strokeLinecap="butt"
              type="circle"
              percent={100}
            />
            <h6>5 of 5 complete</h6>
          </Flex>

          <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
            <button className="absolute -right-3 -top-3">
              <CloseIcon />
            </button>
            <h5 className="text-xl font-semibold">Download our App</h5>
            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">
              Download our mobile application for better experience and to
              receive custom notifications in real time.{" "}
            </p>
            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
              <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                <button className="mx-2">
                  <AndroidIcon />
                </button>
                <button className="mx-2">
                  <IOSIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </StepperInfo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
`;

const Form = styled.form`
  width: 70%;
`;

const StepperInfo = styled.div`
  width: 30%;
  margin-left: 10%;
`;
