import React, { useEffect, useState } from "react";
import {
  getAccessType,
  inviteNewTeamMember,
} from "../../services/requests/roles";
import { useForm, Controller } from "react-hook-form";
import { TextInputWithIcon } from "../form/TextInput";
import { SelectInputWithIcon } from "../form/SelectInput";
import emailIcon from "../../assets/email.svg";
import settingIcon from "../../assets/setting.svg";
import userIcon from "../../assets/single-user.svg";
import styled from "styled-components";
import {
  getCurrentMerchant,
  getMerchantLoginId,
} from "../../services/requests/customers";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";

function UserInviteForm({ availableRoles, setPopup }) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      select: {},
    },
  });
  const [currentMerchant, setCurrentMerchant] = useState();
  const [merchantUserId, setMerchantUserId] = useState();
  const [merchantLoginId, setMerchantLoginId] = useState();
  const [allAccessType, setAllAccessType] = useState([]);
  useEffect(() => {
    getCurrentMerchant().then((data) => {
      setCurrentMerchant(data?.data?.merchantId);
      setMerchantUserId(data?.data?.userId);
      getAccessType().then((data) => {
        if (data?.length > 0) {
          setAllAccessType(data);
        } else {
          setAllAccessType([]);
        }
      });
    });
  }, []);

  useEffect(() => {
    getMerchantLoginId(merchantUserId)
      .then((data) => {
        setMerchantLoginId(data?.data?.loginId);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }, [merchantUserId]);

  const onSubmit = (data) => {
    const selectedRoles = data?.role?.map((data) => data?.value);
    const selectedAccessType = data?.accessType?.map((data) => data?.value);
    const inVitePayload = {
      email: data?.email,
      roleIds: selectedRoles,
      accessTypeIds: selectedAccessType,
      firstName: data?.firstName,
      lastName: data?.lastName,
    };
    inviteNewTeamMember(inVitePayload)
      .then((data) => {
        if (data?.status === false) {
          toast.error("Unable to invite");
        } else {
          toast.success(data.message);
          setPopup("successful");
        }
      })
      .catch((error) => {
        toast.error("Unable to invite");
      });
  };

  return (
    <Wrapper>
      <HeadContainer>
        <p className="role-header-text">Invite New Team Member </p>
      </HeadContainer>
      <p className="text-sm text-center text-gray-400 w-3/5 mx-auto">
        Please enter email to invite team members to have access to your
        dashboard
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"First Name"}
                  placeholder={"Janet"}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"Last Name"}
                  placeholder={"Doe"}
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              type={"email"}
              label={"Email Address"}
              placeholder={"Enter team member’s email address"}
              icon={emailIcon}
            />
          )}
        />

        <Controller
          name="accessType"
          control={control}
          render={({ field }) => (
            <SelectInputWithIcon
              {...field}
              label={"Choose Access Type"}
              isMulti
              placeholder={"Select team member’s Access"}
              icon={settingIcon}
              className="basic-multi-select"
              options={allAccessType?.map((data) => ({
                label: data?.type,
                value: data?.id,
              }))}
            />
          )}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <SelectInputWithIcon
              {...field}
              label={"Choose Role"}
              isMulti
              placeholder={"Select team member’s Role"}
              icon={settingIcon}
              className="basic-multi-select"
              options={availableRoles?.map((data) => ({
                label: data.label,
                value: data.value,
              }))}
            />
          )}
        />

        <button
          // onClick={sendInviteHandler}
          className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded"
        >
          Invite Team Member
        </button>
      </Form>
    </Wrapper>
  );
}

export default UserInviteForm;

export const Wrapper = styled.div``;

export const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-top: 1rem;
  }
`;

const Form = styled.form`
  padding: 1rem;
`;
