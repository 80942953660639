import { format } from 'date-fns';
function FormatDatetime(d) {
  if (!d) {
    return '.....'
  }
  let date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return `${date.getFullYear()}-${date.getMonth() + 1 < 9 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate()} ${strTime}`;
}

export {FormatDatetime}


export const formatDate = (dateObj) => {
  const date = new Date(
    dateObj.year,
    dateObj.month - 1, // Months are 0-indexed in JavaScript
    dateObj.day,
    dateObj.hour,
    dateObj.minute,
    dateObj.second
  );

  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};



export const formatShortDate = (dateString, dateFormat = 'yyyy-MM-dd') => {
  if (!dateString) return '';
  return format(new Date(dateString), dateFormat);
};