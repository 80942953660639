import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import config from "../../../config";
import { LoaderIcon, SuccessIcon } from "../../../assets";
import { Link } from "react-router-dom";

export const KYCSummary = ({ handleToStep1, formValues }) => {
  const [loadState, setLoadState] = useState(false);
  const [isBrowseModalOpen, setIsBrowseModalOpen] = useState(false);
  const newPayload = {
    appLink: formValues?.businessDetails?.appLink || "",
    approvalStatus: "PENDING",
    businessAccountName: formValues?.businessDetails?.businessAccountName || "",
    businessAccountNumber:
      formValues?.businessDetails?.businessAccountNumber || "",
    businessAccountProvider:
      formValues?.businessDetails?.businessAccountProvider || "",
    businessAddressProveType:
      formValues?.certificateUpload?.businessAddressProveType || "",
    businessAddressProveUr:
      formValues?.certificateUpload?.businessAddressProveUr || "",
    businessDescription: formValues?.businessDetails?.businessDescription || "",
    businessEmail: formValues?.businessDetails?.businessEmail || "",
    businessIndustry: formValues?.businessDetails?.businessIndustry || "",
    businessLogoUrl: formValues?.businessDetails?.businessLogoUrl || "",
    businessName: formValues?.businessDetails?.businessName || "",
    businessNameCertificateUrl:
      formValues?.certificateUpload?.businessNameCertificateUrl || "",
    businessNationality: "",
    businessPhoneNumber: formValues?.businessDetails?.businessPhoneNumber || "",
    businessRegistrationNumber:
      formValues?.businessDetails?.businessRegistrationNumber || "",
    businessRegistrationType:
      formValues?.businessDetails?.businessRegistrationType || "",
    businessType: formValues?.businessDetails?.businessType || "",
    bvnApproved: false,
    bvnNumber: formValues?.contactVerification?.userBvnNumber || "",
    cacBnCertificateUrl: null,
    cacItCertificateUrl: null,
    city: formValues?.personalDetails?.city || "",
    country: formValues?.personalDetails?.country || "",
    createdAt: "",
    dateDeleted: null,
    deleted: false,
    deletedBy: null,
    directorFirstName: formValues?.directorDetails?.directorFirstName || "",
    directorLastName: formValues?.directorDetails?.directorLastName || "",
    directorNinNumber: formValues?.directorDetails?.directorNinNumber || "",
    directorBvnNumber: formValues?.directorDetails?.directorBvnNumber || "",
    directorNinImageUrl: formValues?.directorDetails?.directorNinImageUrl || "",
    directorPhotoUrl: formValues?.directorDetails?.directorPhotoUrl,
    email: formValues?.personalDetails?.email || "",
    facebookUrl: formValues?.businessDetails?.facebookUrl || "",
    firstName: formValues?.personalDetails?.firstName || "",
    identificationType: formValues?.certificateUpload?.identificationType || "",
    identificationUrl: formValues?.certificateUpload?.identificationUrl || "",
    incorporationCertificateUrl:
      formValues?.certificateUpload?.incorporationCertificateUrl || "",
    instagramUrl: formValues?.businessDetails?.instagramUrl || "",
    lastName: formValues?.personalDetails?.surname || "",
    lga: formValues?.personalDetails?.lga || "",
    memartCertificateUrl:
      formValues?.certificateUpload?.memartCertificateUrl || "",
    merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
    ninApproved: false,
    ninNumber: formValues?.contactVerification?.userNinNumber || "",
    reasonForRejection: null,
    state: formValues?.personalDetails?.state || "",
    statusLastModifiedOn: null,
    statusModifiedBy: "",
    streetAddress: formValues?.personalDetails?.streetAddress || "",
    surname: formValues?.personalDetails?.surname || "",
    tin: formValues?.certificateUpload?.tin || "",
    twitterUrl: formValues?.businessDetails?.twitterUrl || "",
    updatedAt: "",
    usePaymentLink: formValues?.businessDetails?.usePaymentLink || "",
    userBvnNumber: formValues?.contactVerification?.userBvnNumber || "",
    userId: JSON.parse(sessionStorage.getItem("userData")).id,
    userNinNumber: formValues?.contactVerification?.userNinNumber || "",
    websiteLink: formValues?.businessDetails?.websiteLink || "",
  };

  // const personalValues = {
  //   city: formValues?.personalDetails?.city || "",
  //   country: formValues?.personalDetails?.country || "",
  //   email: formValues?.personalDetails?.email || "",
  //   firstName: formValues?.personalDetails?.firstName || "",
  //   lastName: formValues?.personalDetails?.surname || "",
  //   lga: formValues?.personalDetails?.lga || "",
  //   state: formValues?.personalDetails?.state || "",
  //   streetAddress: formValues?.personalDetails?.streetAddress || "",
  //   surname: formValues?.personalDetails?.surname || "",
  // };
  // const contactValues = {
  //   bvnNumber: formValues?.contactVerification?.userBvnNumber || "",
  //   ninNumber: formValues?.contactVerification?.userNinNumber || "",
  //   userBvnNumber: formValues?.contactVerification?.userBvnNumber || "",
  //   userNinNumber: formValues?.contactVerification?.userNinNumber || "",
  // };
  // const businessValues = {
  //   appLink: formValues?.businessDetails?.appLink || "",
  //   businessAccountName: formValues?.businessDetails?.businessAccountName || "",
  //   businessAccountNumber:
  //     formValues?.businessDetails?.businessAccountNumber || "",
  //   businessAccountProvider:
  //     formValues?.businessDetails?.businessAccountProvider || "",
  //   businessDescription: formValues?.businessDetails?.businessDescription || "",
  //   businessEmail: formValues?.businessDetails?.businessEmail || "",
  //   businessIndustry: formValues?.businessDetails?.businessIndustry || "",
  //   businessLogoUrl: formValues?.businessDetails?.businessLogoUrl || "",
  //   businessName: formValues?.businessDetails?.businessName || "",
  //   businessPhoneNumber: formValues?.businessDetails?.businessPhoneNumber || "",
  //   businessRegistrationNumber:
  //     formValues?.businessDetails?.businessRegistrationNumber || "",
  //   businessRegistrationType:
  //     formValues?.businessDetails?.businessRegistrationType || "",
  //   businessType: formValues?.businessDetails?.businessType || "",
  //   facebookUrl: formValues?.businessDetails?.facebookUrl || "",
  //   instagramUrl: formValues?.businessDetails?.instagramUrl || "",
  //   twitterUrl: formValues?.businessDetails?.twitterUrl || "",
  //   usePaymentLink: formValues?.businessDetails?.usePaymentLink || "",
  //   websiteLink: formValues?.businessDetails?.websiteLink || "",
  // };
  // const directorValues = {
  //   directorFirstName: formValues?.directorDetails?.directorFirstName,
  //   directorLastName: formValues?.directorDetails?.directorLastName,
  //   directorNinNumber: formValues?.directorDetails?.directorNinNumber,
  //   directorBvnNumber: formValues?.directorDetails?.directorBvnNumber,
  //   directorNinImageUrl: formValues?.directorDetails?.directorNinImageUrl,
  //   directorPhotoUrl: formValues?.directorDetails?.directorPhotoUrl,
  // };
  // const certValues = {
  //   businessAddressProveType:
  //     formValues?.certificateUpload?.businessAddressProveType || "",
  //   businessAddressProveUr:
  //     formValues?.certificateUpload?.businessAddressProveUr || "",
  //   businessNameCertificateUrl:
  //     formValues?.certificateUpload?.businessNameCertificateUrl || "",
  //   identificationType: formValues?.certificateUpload?.identificationType || "",
  //   identificationUrl: formValues?.certificateUpload?.identificationUrl || "",
  //   incorporationCertificateUrl:
  //     formValues?.certificateUpload?.incorporationCertificateUrl || "",
  //   memartCertificateUrl:
  //     formValues?.certificateUpload?.memartCertificateUrl || "",
  //   tin: formValues?.certificateUpload?.tin || "",
  // };

  // const submitedData = {
  //   personalDetails: {
  //     ...personalValues,
  //   },
  //   contactVerification: {
  //     ...contactValues,
  //   },
  //   businessDetails: {
  //     ...businessValues,
  //   },
  //   directorDetails: {
  //     ...directorValues,
  //   },
  //   certificateUpload: {
  //     ...certValues,
  //   },
  // };

  const finalKYCSubmitHandler = () => {
    const finalPayload = {
      draft: false,
      ...newPayload,
    };

    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    axios
      .post(
        `${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/submit?merchantId=${merchantId}`,
        finalPayload,
        header
      )
      .then((resp) => {
        toast.success("Data Stored successfully");
        setLoadState(true);
        setTimeout(() => {
          setLoadState(false);
          setIsBrowseModalOpen(true);
        }, 5000);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.details[0]);
      });
  };
  return (
    <Wrapper>
      <SummaryDetails>
        <>
          <div className="w-4/5 border border-gray-200 h-auto rounded-lg col-span-7 p-1 mx-auto ...">
            <div className="w-full border border-gray bg-white rounded-lg px-3 py-2 pb-4">
              <h4 className="font-semibold text-lg text-gray-600">
                Profile Information
              </h4>
              <SummaryValue>
                <SummaryHead>
                  Legal Business Name <span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.businessDetails?.businessName ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>
                  Business Description <span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.businessDetails?.businessDescription ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>
                  Types of Industry <span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.businessDetails?.businessIndustry ?? ""}
                </SummaryText>
              </SummaryValue>
              <SummaryValue>
                <SummaryHead>
                  Type of Business <span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.businessDetails?.businessType ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>Social Media URL</SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.businessDetails?.facebookUrl ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>Instagram</SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.businessDetails?.instagramUrl ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>Twitter</SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.businessDetails?.twitterUrl ?? ""}
                </SummaryText>
              </SummaryValue>
              <h4 className="font-semibold text-lg text-gray-600 mt-8">
                Registered Addresses
              </h4>
              <SummaryValue>
                <SummaryHead>
                  Country<span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.personalDetails?.country ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>
                  State<span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.personalDetails?.state ?? ""}
                </SummaryText>
              </SummaryValue>
              <SummaryValue>
                <SummaryHead>
                  Local Government Area<span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.personalDetails?.lga ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>
                  City<span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.personalDetails?.city ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>
                  Street Address<span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.personalDetails?.streetAddress ?? ""}
                </SummaryText>
              </SummaryValue>

              <h4 className="font-semibold text-lg text-gray-600 mt-8">
                Document Uploads
              </h4>
              {formValues?.businessDetails?.businessType ===
                "REGISTERED_BUSINESS" && (
                <SummaryValue>
                  <SummaryHead>
                    Certificate of Incorporation
                    <span className="text-red-500">*</span>
                  </SummaryHead>
                  <SummaryText>
                    {" "}
                    {formValues?.businessDetails?.businessCertificateType ?? ""}
                  </SummaryText>
                </SummaryValue>
              )}
              <SummaryValue>
                <SummaryHead>
                  Proof of business operating address for the company
                  <span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.certificateUpload?.businessAddressProveType ??
                    ""}
                  {formValues?.certificateUpload?.businessAddressProveUrl && (
                    <a
                      href={
                        formValues?.certificateUpload?.businessAddressProveUrl
                      }
                      style={{ fontWeight: "500", fontSize: "18px" }}
                      target="_blank"
                      className="cursor-pointer text-blue-500 text-xs mx-4"
                      rel="noreferrer noopener"
                    >
                      View
                    </a>
                  )}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>
                  Bank Verification Number (BVN)
                  <span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.contactVerification?.userBvnNumber ?? ""}
                </SummaryText>
              </SummaryValue>

              <SummaryValue>
                <SummaryHead>
                  Valid Id card
                  <span className="text-red-500">*</span>
                </SummaryHead>
                <SummaryText>
                  {" "}
                  {formValues?.certificateUpload?.identificationType ?? ""}
                </SummaryText>
              </SummaryValue>

              {formValues?.businessDetails?.businessType ===
                "REGISTERED_BUSINESS" && (
                <SummaryValue>
                  <SummaryHead>
                    CAC Registration Certificate Number
                    <span className="text-red-500">*</span>
                  </SummaryHead>
                  <SummaryText>
                    {" "}
                    {formValues?.businessDetails?.businessRegistrationType ??
                      ""}
                  </SummaryText>
                </SummaryValue>
              )}
            </div>

            <div className="flex gap-5">
              <button
                type="button"
                onClick={handleToStep1}
                className="flex px-10 my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm"
              >
                Edit
              </button>
              <button
                onClick={finalKYCSubmitHandler}
                className="flex my-1 items-center justify-center py-3 px-4 rounded bg-green-700 text-white text-sm"
              >
                Submit KYC
              </button>
            </div>
          </div>
        </>
      </SummaryDetails>

      <>
        {loadState && (
          <>
            <div className="w-full h-screen bg-black z-30 flex items-center justify-center overlay fixed top-0 left-0">
              <div className="bg-white rounded-xl h-auto py-10 px-10 w-1/3 ...">
                <div className="loader mx-auto w-60 flex iteem-center justify-center h-60 flex iteem-center justify-center">
                  <LoaderIcon />
                </div>
                <h4 className="font-semibold text-3xl text-center">
                  Please wait!
                </h4>
                <p className="text-md text-gray-600 text-center">
                  We’re verifying your KYC details.
                </p>
              </div>
            </div>
          </>
        )}

        {isBrowseModalOpen && (
          <div className="w-full h-screen bg-black z-30 flex items-center justify-center overlay fixed top-0 left-0">
            <div className="bg-white rounded-xl h-auto py-10 px-10 w-1/2 ...">
              <div className="mx-auto mb-3 flex items-center justify-center">
                <SuccessIcon />
              </div>
              <h4 className="font-semibold text-2xl text-center">
                Thank you for submitting your KYC with us!
              </h4>
              <p className="text-md px-10 text-gray-500 text-center">
                Your KYC is currently under review, we’ll get back to you in the
                next 24hrs. Kindly explore the dashboard with our test mode
                feature and see how your payment transactions processes.
              </p>
              <div className="grid grid-cols-2 gap-3 px-10 mt-5">
                <Link to="/support">
                  <button className="py-3 w-full px-8 text-primary-theme border border-primary-theme text-xs font-medium uppercase bg-red-100">
                    VIEW DOCUMENTATION
                  </button>
                </Link>
                <Link to="/">
                  <button className="py-3 w-full px-8 text-white bg-primary-theme border border-primary-theme text-xs font-medium uppercase bg-red-100">
                    EXPLORE DASHBOARD
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const SummaryDetails = styled.div``;
const SummaryValue = styled.div`
  height: inherit;
  min-height: 60px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin: 1rem auto;
`;
const SummaryHead = styled.h4`
  font-weight: 500;
`;
const SummaryText = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: grey;
  margin: 1rem auto;
  font-size: 18px;
  padding: 10px;
`;
