import React from "react";
import styled from "styled-components";
import ReactSelect, { components } from "react-select";
import { ErrorText } from "./TextInput";

export const Control = (props) => {
  return (
    <>
      <Label>{props.selectProps.label}</Label>
      <components.Control {...props} />
    </>
  );
};

export const SelectInputWithIcon = ({
  name,
  error,
  required,
  value,
  label,
  placeholder,
  icon,
  isRequired,
  ref,
  ...textInputProps
}) => {
  return (
    <Wrapper>
      {label && <Label htmlFor={name}>
        {label}
        {isRequired && <span className="text-red-500">*</span>}
        </Label>}
      <br />
      <InputContainer>
        {icon && (
          <img
            src={icon}
            alt="input-icon"
            title="input-icon"
            className="input-icon"
          />
        )}
        <Select
          id={name}
          name={name}
          placeholder={placeholder}
          {...textInputProps}
          components={{ Control }}
          classNamePrefix="react-select"
          defaultValue={value}
          required={required}
        />
      </InputContainer>

      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  padding: 5px;
  margin-bottom: 15px;
  .input-icon {
    margin-right: 5px;
  }
  .react-select__value-container {
    height: inherit;
    overflow-y: scroll;
  }
`;

const Select = styled(ReactSelect)`
  padding: 5px 10px;
  display: block;
  border-radius: 4px;
  border: transparent;
  background-color: transparent;
  outline: transparent;
  width: 100%;
`;
const Label = styled.label`
  font-size: 13px;
  font-weight: 700;
  transition: transform 200ms;
`;
