export const shortenLink = (url) => {
  if (!url) throw new Error("URL is required");

  // Generate a unique code by hashing the URL (base64 + substring)
  const generateCode = (longUrl) =>
    btoa(unescape(encodeURIComponent(longUrl))).substring(0, 8);

  const code = generateCode(url);
  const baseUrl = "https://pay.staging.wayapay.ng/";
  const base =
    "https://pay.staging.wayapay.ng/payment-link?customParam=PaymentLink&paymentLinkId";
  //window.location.origin}
  const shortUrl = `${baseUrl}/${code}`;

  // return { shortUrl, code };
  console.log("here here", shortUrl);
  console.log("here here2", code);
};

export const changeBytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

