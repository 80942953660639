import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Flex, Progress } from "antd";
import { CloseIcon, AndroidIcon, IOSIcon } from "../../../assets";
import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextInputWithIcon } from "../../form/TextInput";
import { ultaPayload } from "../components/constant";

const schema = yup
  .object({
    directorFirstName: yup.string().required("Director First Name is required"),
    directorLastName: yup.string().required("Director Last Name is required"),
    directorBvnNumber: yup
      .string()
      .matches(/.{11}/, {
        excludeEmptyString: true,
        message: "Must be 11 characters",
      })
      .max(11)
      .required("Director BVN is required"),
    directorNinNumber: yup
      .string()
      .matches(/.{11}/, {
        excludeEmptyString: true,
        message: "Must be 11 characters",
      })
      .max(11)
      .required("Director NIN is required"),
  })
  .required();

export const DirectorDetails = ({
  navigateForward,
  formValues,
  initialData,
}) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      directorFirstName: initialData?.directorFirstName ?? "",
      directorLastName: initialData?.directorLastName ?? "",
      directorBvnNumber: initialData?.directorBvnNumber ?? "",
      directorNinNumber: initialData?.directorNinNumber ?? "",
      directorNinImageUrl: initialData?.directorNinImageUrl ?? "",
      directorPhotoUrl: initialData?.directorPhotoUrl ?? "",
    },
    resolver: yupResolver(schema),
  });
  const dirPicRef = useRef();
  const dirNinRef = useRef();
  const [directorInfo, setDirectoInfo] = useState([]);

  const uploadDirectorPic = (e) => {
    e.preventDefault();
    const file = dirPicRef.current.files[0];
    if (file?.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (dirPicRef.current.files && dirPicRef.current.files.length) {
      // upload memart document
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "DIRECTORPHOTO");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");
            setDirectoInfo({
              ...directorInfo,
              directorPhotoUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };
  const uploadDirectorNIN = (e) => {
    e.preventDefault();
    const file = dirNinRef.current.files[0];
    if (file.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
    } else if (dirNinRef.current.files && dirNinRef.current.files.length) {
      // upload memart document
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "DIRECTORNIN");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );
      const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            toast.success("Uploaded successfully");
            setDirectoInfo({
              ...directorInfo,
              directorNinImageUrl: resp.data.data,
            });
          })
          .catch((err) => {
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const onSubmit = (data) => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const header = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        authorization: authorization,
        "CLIENT-ID": "WAYAQUICK",
        "CLIENT-TYPE": "CORPORATE",
      },
    };
    const payload = {
      ...directorInfo,
      ...formValues?.personalDetails,
      ...formValues?.contactVerification,
      ...formValues?.businessDetails,
      directorFirstName: data?.directorFirstName,
      directorLastName: data?.directorLastName,
      directorNinNumber: data?.directorNinNumber,
      directorBvnNumber: data?.directorBvnNumber,
    };

    const newPayload = {
      appLink: "",
      approvalStatus: "PENDING",
      businessAccountName:
        formValues?.businessDetails?.businessAccountName || "",
      businessAccountNumber:
        formValues?.businessDetails?.businessAccountNumber || "",
      businessAccountProvider:
        formValues?.businessDetails?.businessAccountProvider || "",
      businessAddressProveType: "UTILITY_BILL",
      businessAddressProveUr: "",
      businessDescription:
        formValues?.businessDetails?.businessDescription || "",
      businessEmail: formValues?.businessDetails?.businessEmail || "",
      businessIndustry: formValues?.businessDetails?.businessIndustry || "",
      businessLogoUrl: "",
      businessName: formValues?.businessDetails?.businessName || "",
      businessNameCertificateUrl: null,
      businessNationality: "",
      businessPhoneNumber:
        formValues?.businessDetails?.businessPhoneNumber || "",
      businessRegistrationNumber:
        formValues?.businessDetails?.businessRegistrationNumber || "",
      businessRegistrationType:
        formValues?.businessDetails?.businessRegistrationType || "",
      businessType: formValues?.businessDetails?.businessType || "",
      bvnApproved: false,
      bvnNumber: formValues?.contactVerification?.userBvnNumber || "",
      cacBnCertificateUrl: null,
      cacItCertificateUrl: null,
      city: formValues?.personalDetails?.city || "",
      country: formValues?.personalDetails?.country || "",
      createdAt: "",
      dateDeleted: null,
      deleted: false,
      deletedBy: null,
      directorFirstName: data?.directorFirstName,
      directorLastName: data?.directorLastName,
      directorNinNumber: data?.directorNinNumber,
      directorBvnNumber: data?.directorBvnNumber,
      directorNinImageUrl: directorInfo?.directorNinImageUrl,
      directorPhotoUrl: directorInfo?.directorPhotoUrl,
      draft: true,
      email: formValues?.personalDetails?.email || "",
      facebookUrl: "",
      firstName: formValues?.personalDetails?.firstName || "",
      identificationType: "NATIONAL_ID",
      identificationUrl: "",
      incorporationCertificateUrl: "",
      //businessDetails
      instagramUrl: formValues?.businessDetails?.instagramUrl || "",
      lastName: formValues?.personalDetails?.surname || "",
      lga: formValues?.personalDetails?.lga || "",
      memartCertificateUrl: "",
      merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
      ninApproved: false,
      ninNumber: formValues?.contactVerification?.userNinNumber || "",
      reasonForRejection: null,
      state: formValues?.personalDetails?.state || "",
      statusLastModifiedOn: null,
      statusModifiedBy: "",
      streetAddress: formValues?.personalDetails?.streetAddress || "",
      surname: formValues?.personalDetails?.surname || "",
      tin: "",
      twitterUrl: formValues?.businessDetails?.twitterUrl || "",
      updatedAt: "",
      usePaymentLink: formValues?.businessDetails?.usePaymentLink || "",
      userBvnNumber: formValues?.contactVerification?.userBvnNumber || "",
      userId: null,
      userNinNumber: formValues?.contactVerification?.userNinNumber || "",
      websiteLink: "",
    };
    const sendPayload = {
      ...payload,
      ...newPayload,
      draft: true,
      merchantId: JSON.parse(sessionStorage.getItem("merchantId")),
    };
    navigateForward(payload);
    axios
      .post(
        `${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`,
        sendPayload,
        header
      )
      .then((resp) => {
        toast.success("Data Stored successfully");
      })
      .catch((err) => {
        toast.error(err.response);
      });

    // navigateForward(payload);
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
          <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
            <h4 className="font-semibold text-lg text-gray-600">Director</h4>

            <Controller
              name="directorFirstName"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"Director First Name"}
                  isRequired
                  placeholder={"Janet"}
                  error={errors?.firstName?.message}
                />
              )}
            />
            <Controller
              name="directorLastName"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  label={"Director Last Name"}
                  isRequired
                  placeholder={"Doe"}
                  error={errors?.directorLastName?.message}
                />
              )}
            />

            <Controller
              name="directorBvnNumber"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  type={"number"}
                  isRequired
                  label={"Director BVN"}
                  onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                  placeholder={"22233344556"}
                  error={errors?.directorBvnNumber?.message}
                />
              )}
            />
            <Controller
              name="directorNinNumber"
              control={control}
              render={({ field }) => (
                <TextInputWithIcon
                  {...field}
                  type={"number"}
                  isRequired
                  label={"Director NIN"}
                  onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                  placeholder={"21234567890"}
                  error={errors?.directorNinNumber?.message}
                />
              )}
            />
            <div className="mt-6">
              <label className="text-sm font-medium">
                Director NIN <span className="text-red-500">*</span>
              </label>
              <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                <input
                  onChange={uploadDirectorNIN}
                  ref={dirNinRef}
                  type="file"
                  className="hidden"
                />
                <button
                  type="button"
                  className="text-xs px-3 py-2 rounded bg-white"
                  onClick={() => dirNinRef.current.click()}
                >
                  Upload a file
                </button>

                {directorInfo?.directorNinImageUrl && (
                  <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                    Document uploaded
                    <p>
                      <a
                        href={directorInfo?.directorNinImageUrl}
                        target="_blank"
                        className="cursor-pointer text-blue-500 text-xs mx-4"
                        rel="noreferrer noopener"
                      >
                        View
                      </a>
                      <span
                        onClick={() => {
                          setDirectoInfo({
                            ...directorInfo,
                            directorNinImageUrl: "",
                          });
                        }}
                        className="cursor-pointer text-red-500 text-xs mx-4"
                      >
                        X
                      </span>
                    </p>
                  </label>
                )}
              </div>
            </div>
            <div className="mt-6">
              <label className="text-sm font-medium">
                Director Photo <span className="text-red-500">*</span>
              </label>
              <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                <input
                  onChange={uploadDirectorPic}
                  ref={dirPicRef}
                  type="file"
                  className="hidden"
                />
                <button
                  type="button"
                  className="text-xs px-3 py-2 rounded bg-white"
                  onClick={() => dirPicRef.current.click()}
                >
                  Upload a file
                </button>

                {directorInfo?.directorPhotoUrl && (
                  <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">
                    Document uploaded
                    <p>
                      <a
                        href={directorInfo?.directorPhotoUrl}
                        target="_blank"
                        className="cursor-pointer text-blue-500 text-xs mx-4"
                        rel="noreferrer noopener"
                      >
                        View
                      </a>
                      <span
                        onClick={() => {
                          setDirectoInfo({
                            ...directorInfo,
                            directorPhotoUrl: "",
                          });
                        }}
                        className="cursor-pointer text-red-500 text-xs mx-4"
                      >
                        X
                      </span>
                    </p>
                  </label>
                )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm"
          >
            Save & Continue
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Form>

      <StepperInfo>
        <div className="w-full col-span-2 flex flex-col items-center">
          <Flex vertical gap="small">
            <Progress
              strokeColor="#ff6700"
              showInfo={false}
              strokeLinecap="butt"
              type="circle"
              percent={80}
            />
            <h6>4 of 5 complete</h6>
          </Flex>

          <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
            <button className="absolute -right-3 -top-3">
              <CloseIcon />
            </button>
            <h5 className="text-xl font-semibold">Download our App</h5>
            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">
              Download our mobile application for better experience and to
              receive custom notifications in real time.{" "}
            </p>
            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
              <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                <button className="mx-2">
                  <AndroidIcon />
                </button>
                <button className="mx-2">
                  <IOSIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </StepperInfo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;

const Form = styled.form`
  width: 70%;
`;

const StepperInfo = styled.div`
  width: 30%;
  margin-left: 10%;
`;
