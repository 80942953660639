import React from "react";
import styled from "styled-components";

export const TextInputWithIcon = ({
  label,
  placeholder,
  name,
  error,
  type,
  icon,
  pretext,
  isRequired,
  ...textInputProps
}) => {
  return (
    <Wrapper>
      {label && (
        <Label htmlFor={name}>
          {label}
          {isRequired && <span className="text-red-500">*</span>}
        </Label>
      )}
      <InputContainer>
        {icon && (
          <img
            src={icon}
            alt="input-icon"
            title="input-icon"
            className="input-icon"
          />
        )}
        {pretext && <span className="input-pretext">{pretext}</span>}

        <Input
          placeholder={placeholder}
          id={name}
          type={type}
          name={name}
          {...textInputProps}
        />
      </InputContainer>

      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  padding: 5px;
  margin-bottom: 15px;
  .input-icon {
    width: 20px;
    height: 20px;
  }
  .input-pretext {
    margin-left: 4px;
  }
`;
const Input = styled.input`
  padding: 5px 10px;
  display: block;
  border-radius: 4px;
  border: transparent;
  background-color: transparent;
  outline: transparent;
  width: 100%;
`;
const Label = styled.label`
  font-size: 13px;
  font-weight: 700;
  transition: transform 200ms;
`;

export const ErrorText = styled.span`
  display: block;
  color: red;
  margin-bottom: 15px;
`;
