import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import {
  FilterIcon,
  ExportIcon,
  ChevronDownIcon,
  UserPlusIcon,
  BackIcon,
} from "../assets";
import { Table } from "./index.js";
import CardThree from "./CardThree";
import axios from "axios";
import {
  getAllDispute,
  getDisputeStats,
  acceptDispute,
  rejectDispute,
} from "../services/requests/disputes";
import { formatShortDate } from "../utils/FormatDatetime";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { CreateNewDispute } from "./CreateNewDispute.jsx";
import Overlay from "./Overlay.jsx";
import addIcon from "../assets/add-icon.svg";
import config from "../config";
import { changeBytesToMB } from "../utils/shortenLink.js";
import uploadIcon from "../assets/upload-cloud.svg";
import checkIcon from "../assets/green-check.svg";
import binIcon from "../assets/bin.svg";

const Loader = () => {
  return (
    <div
      className="h-6 w-6 rounded-full animate-spin"
      style={{
        border: "3px solid #ffffff",
        borderRight: "3px solid transparent",
      }}
    ></div>
  );
};

const uploadedDate = new Date();

function DisputeInner() {
  const [disputes, setDisputes] = useState([]);
  const [disputesStats, setDisputesStats] = useState([]);
  const [allDispute, setAllDispute] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [disputeDetails, setDisputeDetails] = useState(null);
  const [disputeStatus, setDisputeStatus] = useState("");
  const [disputeType, setDisputeType] = useState("");
  const [disputeResolutionType, setDisputeResolutionType] = useState("");
  const [allFilteredData, setFilteredData] = useState(null);
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [approveTab, setapproveTab] = useState(true);
  const [disputeStats, setDisputeStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [popup, setPopup] = useState("");
  const [proofType, setProofType] = useState(null);
  const [proofNote, setProofNote] = useState(null);
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [refundedAmount, setRefundedAmount] = useState("");
  const [comment, setComment] = useState("");
  const [isDocUploaded, setIsDocUploaded] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [docName, setDocName] = useState("");
  const [uploadedDoc, setUploadedDoc] = useState();
    const [isLoading, setIsLoading] = useState(false);
  const [merchantDisputeProofUrl, setMerchantDisputeProofUrl] = useState("");
  const fileUpload = useRef();

  const showOverlayHandler = (name) => {
    setShowOverlay(true);
    setPopup(name);
  };

  useEffect(() => {
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    getAllDispute(merchantId).then((response) => {
      const tableRowData = response?.data?.content?.map((dispute) => ({
        id: dispute?.merchantCustomerDisputeId,
        status: dispute?.disputeResolutionStatus.split("_").join(" "),
        type: dispute?.disputeType,
        transaction: `NGN${dispute?.transactionAmount} on ${dispute?.transactionDate}`,
        customer: dispute?.merchantEmail,
        date: dispute?.disputeInitiationDate,
        dueDate: dispute?.merchantResponseDueDate,
      }));
      setDisputes(response?.data?.content);
      setAllDispute(tableRowData);
      setFilteredData(tableRowData);
    });
    getDisputeStats(merchantId).then((response) => {
      let b = {};
      response?.data?.disputeResolutionStatus.forEach((element) => {
        b[Object.values(element)[0]] = Object.values(element)[1];
      });
      setDisputeStats(b);
    });
  }, []);

  const tableColumnStructure = [
    { field: "id", headerName: "Dispute ID", width: 260 },
    { field: "status", headerName: "Dispute Status", width: 160 },
    { field: "type", headerName: "Dispute Type", width: 130 },
    { field: "transaction", headerName: "Transaction", width: 280 },
    { field: "customer", headerName: "Customer", width: 200 },
    { field: "date", headerName: "Date Created", width: 200 },
    { field: "dueDate", headerName: "Due When", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const data = disputes?.filter(
            (elem) => elem.merchantCustomerDisputeId === params.row.id
          );
          if (data?.length === 1) {
            setShowPopup(true);
            setapproveTab(true);
            setDisputeDetails(data[0]);
          }
        };
        return (
          <button
            className="text-sm py-3 px-4 rounded-lg bg-primary-theme text-white"
            onClick={onClick}
          >
            View More
          </button>
        );
      },
    },
  ];

  const handleSearchFilter = () => {
    let filteredList = allDispute;
    const newList = allDispute?.map((data) => data);
    filteredList = newList?.filter((data) => data?.id === filterSearchValue);
    const activeList = filterSearchValue ? filteredList : allDispute;
    const tableRowData = activeList?.map((dispute) => {
      return {
        id: dispute?.id,
        status: dispute?.status,
        type: dispute?.type,
        transaction: dispute?.transaction,
        customer: dispute?.customer,
        date: dispute?.date,
        dueDate: dispute?.dueDate,
      };
    });
    setFilteredData(tableRowData);
  };

  const handleFilter = () => {
    let filteredList = allDispute;
    const newList = allDispute?.map((data) => data);
    const filteredDateList = newList?.filter(
      (data) => formatShortDate(data?.date) === formatShortDate(startDate)
    );
    const filteredDisputeStatus = filteredDateList?.filter(
      (data) =>
        data.status.toLocaleLowerCase() === disputeStatus.toLocaleLowerCase()
    );
    filteredList = filteredDisputeStatus?.filter(
      (data) =>
        data.type.toLocaleLowerCase() === disputeType.toLocaleLowerCase()
    );
    //disputeResolutionType
    const tableRowData = filteredList?.map((dispute) => {
      return {
        id: dispute?.id,
        status: dispute?.status,
        type: dispute?.type,
        transaction: dispute?.transaction,
        customer: dispute?.customer,
        date: dispute?.date,
        dueDate: dispute?.dueDate,
      };
    });
    setFilteredData(tableRowData);
  };

  const ClearFilter = () => {
    setValue(() => "");
    setStartDate(() => "");
    setDisputeStatus(() => "");
    setDisputeType(() => "");
    setFilteredData(allDispute);
    hideFilterDropdown();
  };

  const showFilterDropdown = () => setFilterDropdown(true);
  const hideFilterDropdown = () => setFilterDropdown(false);

  const hideOverlay = () => {
    setShowPopup(false);
  };

  const activateTab = (e) => {
    const accept = document.querySelector("#accept");
    const reject = document.querySelector("#reject");
    if (e.target.id == "accept") {
      accept.classList.add("text-primary-theme", "border-primary-theme");
      accept.classList.remove("text-gray-500");
      reject.classList.remove("text-primary-theme", "border-primary-theme");
      reject.classList.add("text-gray-500");
      setapproveTab(true);
    } else {
      accept.classList.remove("text-primary-theme", "border-primary-theme");
      accept.classList.add("text-gray-500");
      reject.classList.add("text-primary-theme", "border-primary-theme");
      reject.classList.remove("text-gray-500");
      setapproveTab(false);
    }
  };
  // Acceptance
  const acceptDisputeHandler = (id) => {
    setLoading(true);
    const payload = {
      merchantAcceptanceComment: comment,
      newRefundAmount: refundedAmount
        ? refundedAmount
        : disputeDetails?.transactionAmount,
    };
    acceptDispute(id, payload)
      .then((response) => {
        if (!response.status) {
          toast(response.message.data[0]);
        } else {
          toast("Dispute accepted.");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast(error.response);
        setLoading(false);
      });
  };

  const deletePhoto = () => {
    setUploadedDoc();
    setDocName();
    setIsDocUploaded();
    setMerchantDisputeProofUrl("");
    setIsDocumentLoading(false);
  };
  const handleFileUpload = (e) => {
    setIsDocumentLoading(true);
    const [file] = e.target.files || [];
    if (file?.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
      setIsDocumentLoading(false);
    } else {
      setUploadedDoc(file);
      setDocName(file?.name);
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "merchantDisputeProofUrl");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );

      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            if (resp?.data?.status === true) {
              setIsDocumentLoading(false);
              toast.success("Uploaded successfully");
              setMerchantDisputeProofUrl(resp?.data?.data);
              setIsDocUploaded(true);
            } else {
              setIsDocumentLoading(false);
              setIsDocUploaded(false);
              toast.error("Could not upload");
            }
          })
          .catch((err) => {
            setIsDocumentLoading(false);
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const uploadPhoto = () => {
    fileUpload.current?.click();
  };

  // rejectance
  const rejectDisputeHandler = (id) => {
    setIsLoading(true);
    const createPayload = {
      documentType: proofType,
      disputeRejectReason: proofNote,
      files: merchantDisputeProofUrl,
      merchantCustomerDisputeId: id,
    };
    rejectDispute(id, createPayload)
      .then((response) => {
        if (!response.status) {
          toast(response.message);
        } else {
          toast("Dispute rejected Successfully.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast(error);
        setIsLoading(false);
        toast("Something went wrong");
      });
  };

  return (
    <>
      <ToastContainer />
      {showPopup && (
        <div className="w-full">
          <button
            className="mx-8 my-4 flex items-center text-sm gap-2"
            onClick={hideOverlay}
          >
            <BackIcon /> Back
          </button>
          <div className="grid grid-cols-3 border border-gray-200 h-auto px-8">
            <div className="col-span-1 h-auto bg-red-50">
              <h4 className="relative text-lg py-3 px-4">
                Details reason for date
                <span className="w-24 h-1 bg-primary-theme absolute left-4 bottom-3"></span>
              </h4>
              <p className="px-4 mt-6 capitalize">
                {disputeDetails?.reasonForDisputeInDetails}
              </p>
            </div>
            <div className="col-span-2 h-auto">
              <div className="w-full h-12 shadow flex items-center justify-center">
                <h5>Resolve transaction dispute</h5>
              </div>
              <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                <h5>Customer Name</h5>
                <h5 className="font-medium text-sm">
                  {disputeDetails?.customerFirstName}{" "}
                  {disputeDetails?.customerLastName}
                </h5>
              </div>
              <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                <h5>Customer Email</h5>
                <h5 className="font-medium text-sm">
                  {disputeDetails?.customerEmail}
                </h5>
              </div>
              <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                <h5>Created At</h5>
                <h5 className="font-medium text-sm">
                  {disputeDetails?.dateCreated}
                </h5>
              </div>
              <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                <h5>Dispute Type</h5>
                <h5 className="font-medium text-sm">
                  {disputeDetails?.disputeType}
                </h5>
              </div>
              <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                <h5>Dispute ID</h5>
                <h5 className="font-medium text-sm">
                  {disputeDetails?.merchantCustomerDisputeId}
                </h5>
              </div>
              {disputeDetails?.accepted && (
                <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                  <h5>Dispute Status</h5>
                  <h5 className="font-medium text-sm capitalize">
                    {disputeDetails?.accepted ? (
                      <span className="py-2 px-4 rounded-lg bg-green-500 text-white text-sm">
                        Accepted
                      </span>
                    ) : (
                      <span className="py-2 px-4 rounded-lg bg-primary-theme text-white text-sm">
                        Rejected
                      </span>
                    )}
                  </h5>
                </div>
              )}

              {disputeDetails?.disputeResolutionStatus === "UNDER_REVIEW" && (
                <div className="mx-4 h-14 border-b border-gray-300 flex items-center justify-between px-4">
                  <h5>Dispute Status</h5>
                  <h5 className="font-medium text-sm capitalize">
                    <span className="py-2 px-4 rounded-lg bg-primary-theme text-white text-sm">
                      Under Review
                    </span>
                  </h5>
                </div>
              )}

              {!disputeDetails?.accepted &&
                disputeDetails?.disputeResolutionStatus === "RESPONSE_NEEDED" && (
                  <>
                    <div className="mx-4 h-16 flex items-center justify-between px-4">
                      <button
                        onClick={activateTab}
                        id="accept"
                        className="text-primary-theme h-10 px-4 border-b border-primary-theme"
                      >
                        Accept Chargeback
                      </button>
                      <button
                        onClick={activateTab}
                        id="reject"
                        className="text-gray-500 h-10 px-4 border-b"
                      >
                        Reject Chargeback
                      </button>
                    </div>
                    {approveTab && (
                      <>
                        <div className="mx-4 h-auto pb-2 flex items-center justify-between px-4">
                          <h5>
                            By accepting this chargeback, you're instructing us
                            to process a refund that will be deducted from your
                            next settlement.
                          </h5>
                        </div>
                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                          <h5 className="font-semibold text-sm my-2">
                            How much should we refund?
                          </h5>
                          <input
                            type="number"
                            onChange={(e) => setRefundedAmount(e.target.value)}
                            defaultValue={disputeDetails?.transactionAmount}
                            className="w-full h-10 rounded border border-gray-300"
                          />
                        </div>
                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                          <Reason
                            onChange={(e) => setComment(e.target.value)}
                            className="text-sm border-b border-gray-300 font-thin w-full h-14 mt-4 focus:outline-none"
                            placeholder="Add a comment"
                          ></Reason>
                        </div>
                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                          <button
                            onClick={() =>
                              acceptDisputeHandler(
                                disputeDetails?.merchantCustomerDisputeId
                              )
                            }
                            className="bg-primary-theme flex items-center gap-2 text-white text-sm rounded py-2 px-4"
                          >
                            Accept Chargeback {loading ? <Loader /> : ""}
                          </button>
                        </div>
                      </>
                    )}
                    {!approveTab && (
                      <>
                        <div className="mx-4 h-auto pb-2 flex flex-col items-between justify-center px-4">
                          <h5 className="font-medium text-lg">
                            Upload document
                            <span className="text-red-500">*</span>
                          </h5>
                          <p className="text-sm text-gray-500 itlaic">
                            You now need to upload further evidence. This could
                            be a proof of delivery, an invoice or some user
                            correspondencies.
                          </p>
                        </div>
                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                          <h5 className="font-semibold text-sm my-2">
                            Type of proof
                          </h5>
                          <select
                            onChange={(e) => setProofType(e.target.value)}
                            className="w-full h-10 rounded border border-gray-300 text-sm px-3"
                          >
                            <option value="Signed Invoices (electronic or paper)">
                              Signed Invoices (electronic or paper)
                            </option>
                            <option value="Sales receipts (electronic or paper)">
                              Sales receipts (electronic or paper)
                            </option>
                            <option value="Delivery confirmation documents">
                              Delivery confirmation documents
                            </option>
                            <option value="Service usage times, dates, etc">
                              Service usage times, dates, etc
                            </option>
                            <option value="Proof that a digital product was downloaded by the customer">
                              Proof that a digital product was downloaded by the
                              customer
                            </option>
                          </select>
                        </div>
                        <UploadContainer>
                          <PhotoContainer>
                            <input
                              type="file"
                              accept=".jpg, .png, .jpeg"
                              ref={fileUpload}
                              onChange={handleFileUpload}
                              className="file-input"
                            />
                            {isDocUploaded ? (
                              <img
                                src={checkIcon}
                                alt="check-icon"
                                className="upload-icon"
                              />
                            ) : (
                              <img
                                src={uploadIcon}
                                alt="check-icon"
                                className="upload-icon"
                              />
                            )}
                            <p
                              className="doc-name"
                              style={{ marginLeft: "10px" }}
                            >
                              {isDocUploaded ? docName : "Upload Photo"}
                              <br />
                              {isDocUploaded ? (
                                <span className="doc-format">
                                  {formatShortDate(uploadedDate)} &#8226; &nbsp;
                                  {changeBytesToMB(uploadedDoc?.size)}
                                  {"MB"}
                                </span>
                              ) : (
                                <span className="doc-format">
                                  "JPG, PNG format Max. 5MB"
                                </span>
                              )}
                            </p>
                          </PhotoContainer>
                          {isDocUploaded ? (
                            <button onClick={deletePhoto}>
                              <img
                                src={binIcon}
                                alt="check-icon"
                                className="upload-icon"
                              />
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={uploadPhoto}
                              className="flex shadow gap-2 text-white h-10 items-center text-sm px-3 rounded bg-primary-theme mx-3"
                            >
                              {isDocumentLoading ? <Loader /> : "Upload"}
                            </button>
                          )}
                        </UploadContainer>
                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                          <Reason
                            onChange={(e) => setProofNote(e.target.value)}
                            className="text-sm border-b border-gray-300 font-thin w-full h-14 mt-4 focus:outline-none"
                            placeholder="Detail reason for rejection"
                          ></Reason>
                        </div>
                        <div className="mx-4 h-auto pb-4 flex flex-col items-start justify-between px-4">
                          <button
                           disabled={!isDocUploaded || !docName}
                            onClick={() =>
                              rejectDisputeHandler(
                                disputeDetails?.merchantCustomerDisputeId
                              )
                            }
                            className="bg-primary-theme text-white text-sm rounded py-2 px-4"
                          >
                            {loading ? <Loader /> : "Reject Chargeback"}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
      )}
      {!showPopup && (
        <>
          <div className="navbar px-8 flex justify-between items-center h-16 shadow mt-2">
            <div className="flex items-center gap-6">
              <div className="flex items-center relative">
                <button
                  className="flex items-center"
                  onClick={showFilterDropdown}
                >
                  <FilterIcon />
                  <span className="text-sm">Filter</span>
                </button>
                {filterDropdown && (
                  <div className="w-64 min-h-fit px-3 py-4 rounded bg-white shadow-lg border border-gray-200 absolute left-0 top-8 z-10">
                    <span
                      onClick={hideFilterDropdown}
                      className="text-primary-theme cursor-pointer absolute top-2 right-2 text-sm"
                    >
                      close
                    </span>
                    {/* <h5 className="font-semibold">Status</h5>
                                        <input value={value} placeholder="Search by reference ID" onChange={(e)=>setValue(e.target.value)} className="w-full h-10 text-sm px-2 rounded border border-gray-200" /> */}

                    <h5 className="font-semibold mt-2">Date</h5>
                    <div className="flex items-center gap-4">
                      <input
                        placeholder="Search by dispute ID"
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                        type="date"
                      />
                    </div>

                    <h5 className="font-semibold mt-2">Dispute Status</h5>
                    <div className="flex items-center gap-4">
                      <select
                        type=""
                        onChange={(e) => setDisputeStatus(e.target.value)}
                        className="w-full h-10 rounded border border-gray-200"
                      >
                        <option value="">Choose status</option>
                        <option value="UNDER REVIEW">UNDER REVIEW</option>
                        <option value="MERCHANT WON">MERCHANT WON</option>
                        <option value="CUSTOMER WON">CUSTOMER WON</option>
                      </select>
                    </div>

                    <h5 className="font-semibold mt-2">Dispute Type</h5>
                    <div className="flex items-center gap-4">
                      <select
                        type=""
                        onChange={(e) => setDisputeType(e.target.value)}
                        className="w-full h-10 rounded border border-gray-200"
                      >
                        <option value="">Choose Dispute Type</option>
                        <option value="CHARGEBACK">CHARGEBACK</option>
                        <option value="FRAUD">FRAUD</option>
                        <option value="COMPLAINT">COMPLAINT</option>
                      </select>
                    </div>

                    {/* <h5 className="font-semibold mt-2">Resolution</h5>
                    <div className="flex items-center gap-4">
                      <select
                        type=""
                        onChange={(e) => setDisputeResolutionType(e.target.value)}
                        className="w-full h-10 rounded border border-gray-200"
                      >
                        <option value="">Choose Resolution Type</option>
                        <option value="CHARGEBACK">AUTO</option>
                        <option value="FRAUD">ACCEPTED</option>
                        <option value="COMPLAINT">DECLINED</option>
                      </select>
                    </div> */}

                    <div className="flex items-center gap-4 mt-5">
                      <button
                        type="button"
                        onClick={ClearFilter}
                        className="py-3 w-full text-xs rounded shadow"
                      >
                        Clear filter
                      </button>
                      <SubmitBtn
                        type="submit"
                        disabled={!startDate || !disputeStatus || !disputeType}
                        onClick={handleFilter}
                        className="py-3 w-full rounded bg-primary-theme text-white text-xs"
                      >
                        Apply filter
                      </SubmitBtn>
                    </div>
                  </div>
                )}
              </div>
              <div className="1/5 flex gap-1 ...">
                <input
                  placeholder="Search by dispute ID"
                  value={filterSearchValue}
                  onChange={(e) => setFilterSearchValue(e.target.value)}
                  className="w-full h-10 text-sm px-2 rounded border border-gray-200"
                />
                <SubmitBtn
                  onClick={handleSearchFilter}
                  disabled={!filterSearchValue}
                  className="bg-primary-theme py-2 px-5 text-white text-sm"
                >
                  Search
                </SubmitBtn>
              </div>
            </div>
            <div className="1/5 flex gap-1 ...">
              <button
                onClick={() => showOverlayHandler("createNewDispute")}
                className="flex shadow gap-2 text-white h-10 items-center text-sm px-3 rounded bg-primary-theme mx-3"
              >
                <img src={addIcon} alt="check-icon" />
                Create New Dispute
              </button>

              {allFilteredData?.length > 0 ? (
                <CSVLink data={allFilteredData}>
                  <button className="flex shadow text-white h-10 items-center text-sm px-3 rounded bg-green-500">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1673 16.6666H5.00065C4.08018 16.6666 3.33398 15.9205 3.33398 15V5.83331C3.33398 4.91284 4.08018 4.16665 5.00065 4.16665H8.33398V5.83331H5.00065V15H14.1673V11.6666H15.834V15C15.834 15.9205 15.0878 16.6666 14.1673 16.6666ZM9.75065 11.4225L8.57565 10.2441L13.8198 4.99998H10.834V3.33331H16.6673V9.16665H15.0007V6.17915L9.75065 11.4225Z"
                        fill="white"
                      />
                    </svg>
                    Export Data
                  </button>
                </CSVLink>
              ) : (
                <button
                  onClick={() => toast("You can't export empty data.")}
                  className="flex shadow text-white h-10 items-center text-sm px-3 rounded bg-green-500"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.1673 16.6666H5.00065C4.08018 16.6666 3.33398 15.9205 3.33398 15V5.83331C3.33398 4.91284 4.08018 4.16665 5.00065 4.16665H8.33398V5.83331H5.00065V15H14.1673V11.6666H15.834V15C15.834 15.9205 15.0878 16.6666 14.1673 16.6666ZM9.75065 11.4225L8.57565 10.2441L13.8198 4.99998H10.834V3.33331H16.6673V9.16665H15.0007V6.17915L9.75065 11.4225Z"
                      fill="white"
                    />
                  </svg>
                  Export Data
                </button>
              )}
            </div>
          </div>
          <Grid spacing={2} container className="px-6 pt-5">
            <Grid item xs={12} sm={12} md={3}>
              <CardThree
                notCurrency="true"
                color="text-primary-theme"
                name="TOTAL DISPUTE"
                amount={allDispute?.length ?? "0"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <CardThree
                notCurrency="true"
                color="text-green-500"
                name="TOTAL WON DISPUTE"
                amount={disputeStats?.MERCHANT_WON ?? "0"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <CardThree
                notCurrency="true"
                color="text-gray-600"
                name="TOTAL LOST DISPUTE"
                amount={disputeStats?.CUSTOMER_WON ?? "0"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <CardThree
                notCurrency="true"
                color="text-primary-theme"
                name="TOTAL DISPUTE UNDER REVIEW"
                amount={disputeStats?.UNDER_REVIEW ?? "0"}
              />
            </Grid>
          </Grid>
          <Table
            tableColumnStructure={tableColumnStructure}
            tableRowData={allFilteredData}
            pageName="Transaction"
          />
        </>
      )}
      <div className="bg-gray-50">
        <Overlay showOverlay={showOverlay} setShowOverlay={setShowOverlay}>
          {popup === "createNewDispute" && (
            <CreateNewDispute setPopup={setPopup} />
          )}
        </Overlay>
      </div>
    </>
  );
}

export default DisputeInner;

const Reason = styled.textarea`
  &::placeholder {
    color: grey;
    font-size: 18px;
    font-weight: 500;
  }
`;

const SubmitBtn = styled.button`
  &[disabled] {
    opacity: 0.5;
  }
`;

const PhotoContainer = styled.div`
  display: flex;
  align-items: center;

  .file-input {
    display: none;
  }
`;
const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 2px auto 10px;
  .doc-name {
    font-weight: 700;
  }
  .doc-format {
    font-weight: 300;
  }
`;
