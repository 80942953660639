const allPermissions = [
  "VIEW_DASHBOARD",
  "MANAGE_DASHBOARD",
  "VIEW_KYC",
  "MANAGE_KYC",
  "TOGGLE_ENVIRONMENT",
  "VIEW_TRANSACTIONS",
  "MANAGE_TRANSACTIONS",
  "VIEW_SETTLEMENT",
  "MANAGE_SETTLEMENT",
  "VIEW_DISPUTES",
  "MANAGE_DISPUTES",
  "VIEW_PAYMENT_LINKS",
  "MANAGE_PAYMENT_LINKS",
  "VIEW_CUSTOMERS",
  "MANAGE_CUSTOMERS",
];




export const availableRoles = [
  {
    role: "ROLE_ADMIN_OWNER",
    permissions: [],
  },
  {
    role: "ROLE_ADMIN_SUPER",
     permissions: [],
  },

  {
    role: "ROLE_ADMIN_USER",
     permissions: [],
  },
  {
    role: "ROLE_ADMIN_OPERATIONS",
     permissions: [],
  },
  {
    role: "ROLE_ADMIN_FINANCE",
     permissions: [],
  },
  {
    role: "ROLE_ADMIN_CUSTOMER_SERVICE",
     permissions: [],
  },
  {
    role: "ROLE_ADMIN_MARKETING",
     permissions: [],
  },
  {
    role: "ROLE_ADMIN_COMMUNICATION",
     permissions: [],
  },
  {
    role: "ROLE_ADMIN_DEVELOPER",
     permissions: [],
  },
  {
    role: "ROLE_USER_OWNER",
     permissions: [
        "VIEW_DASHBOARD",
        "MANAGE_DASHBOARD",
        "VIEW_KYC",
        "MANAGE_KYC",
        "TOGGLE_ENVIRONMENT",
        "VIEW_TRANSACTIONS",
        "MANAGE_TRANSACTIONS",
        "VIEW_SETTLEMENT",
        "MANAGE_SETTLEMENT",
        "VIEW_DISPUTES",
        "MANAGE_DISPUTES",
        "VIEW_PAYMENT_LINKS",
        "MANAGE_PAYMENT_LINKS",
        "VIEW_CUSTOMERS",
        "MANAGE_CUSTOMERS",
     ],
  },
  {
    role: "ROLE_USER_SUPER",
     permissions: [],
  },
  {
    role: "ROLE_USER_ADMIN",
     permissions: [],
  },
  {
    role: "ROLE_USER_OPERATIONS",
    permissions: [
      "VIEW_DASHBOARD",
      "MANAGE_DASHBOARD",
      "VIEW_KYC",
      "MANAGE_KYC",
      "VIEW_TRANSACTIONS",
      "MANAGE_TRANSACTIONS",
      "VIEW_SETTLEMENT",
      "MANAGE_SETTLEMENT",
      "VIEW_DISPUTES",
      "MANAGE_DISPUTES",
      "VIEW_PAYMENT_LINKS",
      "VIEW_CUSTOMERS",
      "MANAGE_CUSTOMERS",
    ],
  },
  {
    role: "ROLE_USER_FINANCE",
     permissions: [
        'VIEW_PAYMENT_LINKS',
        'MANAGE_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS',
        'VIEW_DASHBOARD',
        'VIEW_TRANSACTIONS',
        'MANAGE_TRANSACTIONS',
        'VIEW_SETTLEMENT',
        'MANAGE_SETTLEMENT',
        'VIEW_DISPUTES',
        'MANAGE_DISPUTES',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS',
     ],
  },
  {
    role: "ROLE_USER_CUSTOMER_SERVICE",
     permissions: [
        'VIEW_KYC',
        'VIEW_DASHBOARD',
        'VIEW_TRANSACTIONS',
        'VIEW_SETTLEMENT',
        'VIEW_DISPUTES',
        'MANAGE_DISPUTES',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS',
     ],
  },
  {
    role: "ROLE_USER_MARKETING",
     permissions: [
        'MANAGE_DASHBOARD',
        'VIEW_KYC',
        'VIEW_DASHBOARD',
        'VIEW_TRANSACTIONS',
        'VIEW_DISPUTES',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS',
     ],
  },
  {
    role: "ROLE_USER_COMMUNICATION",
     permissions: [
        'VIEW_DASHBOARD',
        'VIEW_CUSTOMERS',
        'VIEW_DASHBOARD',
        'VIEW_TRANSACTIONS',
        'VIEW_DISPUTES',
        'MANAGE_DISPUTES',
        'VIEW_SETTLEMENT',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS'
     ],
  },
  {
    role: "ROLE_USER_DEVELOPER",
     permissions: [
        'MANAGE_KYC',
        'VIEW_DASHBOARD',
        'VIEW_KYC',
        'MANAGE_KYC',
        'VIEW_TRANSACTIONS',
        'VIEW_DISPUTES',
        'MANAGE_DISPUTES',
        'VIEW_SETTLEMENT',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS',
        'VIEW_DASHBOARD',
        'VIEW_KYC',
        'MANAGE_KYC',
        'VIEW_TRANSACTIONS',
        'VIEW_DISPUTES',
        'MANAGE_DISPUTES',
        'VIEW_SETTLEMENT',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS',
        'VIEW_DASHBOARD',
        'VIEW_KYC',
        'MANAGE_KYC',
        'VIEW_TRANSACTIONS',
        'VIEW_DISPUTES',
        'MANAGE_DISPUTES',
        'VIEW_SETTLEMENT',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS'
     ],
  },
  {
    role: "ROLE_USER_MERCHANT",
    permissions: [
        "VIEW_DASHBOARD",
        "MANAGE_DASHBOARD",
        "VIEW_KYC",
        "MANAGE_KYC",
        "TOGGLE_ENVIRONMENT",
        "VIEW_TRANSACTIONS",
        "MANAGE_TRANSACTIONS",
        "VIEW_SETTLEMENT",
        "MANAGE_SETTLEMENT",
        "VIEW_DISPUTES",
        "MANAGE_DISPUTES",
        "VIEW_PAYMENT_LINKS",
        "MANAGE_PAYMENT_LINKS",
        "VIEW_CUSTOMERS",
        "MANAGE_CUSTOMERS",
     ],
  },
  {
    role: "ROLE_USER_AGENT",
     permissions: [
        'VIEW_DISPUTES',
        'VIEW_SETTLEMENT',
        'VIEW_PAYMENT_LINKS',
        'VIEW_CUSTOMERS',
        'MANAGE_CUSTOMERS',

     ],
  },
  {
    role: "ROLE_USER_AGGREGATOR",
     permissions: [],
  },
  {
    role: "ROLE_ADMIN_APP SYSTEM",
     permissions: [],
  },
  {
    role: "TEST_CUSTOM_ROLE role for testers",
     permissions: [],
  },
  {
    role: "FIELD_MARKETING Field Marketers",
     permissions: [],
  },
  {
    role: "ROLE_USER_MARKETER BIZ_MERCHANT",
     permissions: [],
  },
];


