import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { TextInputWithIcon } from "../../form/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SelectInputWithIcon } from "../../form/SelectInput";
import { updateProfile } from "../../../services/requests/profile";
import { Flex, Progress } from "antd";
import {
  CloseIcon,
  AndroidIcon,
  IOSIcon,
} from "../../../assets";
import { toast } from "react-toastify";
import FetchApi from "../../../utils/FetchApi";

const schema = yup
  .object({
    dob: yup.string().required("Date of birth is required"),
    gender: yup.object().required("Gender is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup
      .string()
      .matches(/.{10,}/, {
        excludeEmptyString: true,
        message: "Must be 10 characters",
      })
      .max(10)
      .required("Phone is required"),
    country: yup.string().required("Country is required"),
    state: yup.string().required("State is required"),
    lga: yup.string().required("LGA is required"),
    city: yup.string().required("City is required"),
    streetAddress: yup.string().required("Address is required"),
  })
  .required();

export const ProfileInformation = ({ navigateForward, initialData }) => {
  const [userData, setUserData] = useState({});
  const [savedKyc, setSavedKYC] = useState();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      firstName: initialData?.firstName ?? "",
      lastName: initialData?.surname ?? "",
      dob: initialData?.dateOfBirth ?? "",
      email: initialData?.email ?? "",
      phone: initialData?.phoneNumber ?? "",
      gender: initialData?.gender ?? "",
      country: initialData?.country ?? "",
      state: initialData?.state ?? "",
      lga: initialData?.lga ?? "",
      city: initialData?.city ?? "",
      streetAddress: initialData?.streetAddress ?? "",
    },
    resolver: yupResolver(schema),
  });


//   const getAccountKycStatus = () => {
//     console.log('got here?')
//     const authorization = JSON.parse(sessionStorage.getItem("token"));
//     const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));        
//     const response = FetchApi({ endpoint: `/identity-manager-service/api/v1/waya-merchant/business-kyc/${merchantId}`, authorization: authorization, method: "GET" });
//     response.then(response=>{
//         toast.success("Fetch Successful");
//         setSavedKYC(response)
  
//     }).catch(err=>{
//         toast.error(err.response.data.details[0]);
//     });
// }


  useEffect(() => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const userInfo = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(userInfo);
    // getAccountKycStatus();
  }, []);

  const onSubmit = (data) => {
    const payload = {
      firstName: userData?.firstName,
      surname: userData?.surname,
      dateOfBirth: data?.dob,
      gender: data?.gender?.value,
      email: data?.email,
      phoneNumber: "234" + data?.phone,
      country: data?.country,
      state: data?.state,
      lga: data?.lga,
      city: data?.city,
      streetAddress: data?.streetAddress,
    };
    const sendPayload = {
      ...payload,
      profileId: userData?.profileSubscription?.profileId,
    };
    navigateForward(payload);
    updateProfile(userData?.id, sendPayload)
      .then((response) => {
        if (response.status) {
          sessionStorage.setItem("userData", JSON.stringify(userData));
          toast.success("Profile Updated Successfully");
        }
      })
      .catch((error) => {
        toast.error("An error occured while updating profile");
      });
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              label={"First Name"}
              value={userData?.firstName}
              isRequired
              disabled
              placeholder={"Janet"}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              label={"Last Name"}
              value={userData?.surname}
              isRequired
              disabled
              placeholder={"Doe"}
            />
          )}
        />
        <Controller
          name="dob"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              type={"date"}
              isRequired
              label={"Date of Birth"}
              error={errors?.dob?.message}
            />
          )}
        />
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <SelectInputWithIcon
              {...field}
              label={"Choose Gender"}
              isRequired
              placeholder={"Select Gender"}
              options={[
                { value: "female", label: "Female" },
                { value: "male", label: "Male" },
              ]}
              error={errors?.gender?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              type={"email"}
              isRequired
              label={"Email Address"}
              placeholder={"example@example.com"}
              error={errors?.email?.message}
            />
          )}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              type={"number"}
              isRequired
              label={"Phone Number"}
              placeholder={"8012345678"}
              onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
              pretext={"+234"}
              error={errors?.phone?.message}
            />
          )}
        />
        <h5 className="text-md mt-4 font-semibold">Address</h5>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              label={"Country"}
              isRequired
              placeholder={"Nigeria"}
              error={errors?.country?.message}
            />
          )}
        />
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              label={"State"}
              isRequired
              placeholder={"Oyo"}
              error={errors?.state?.message}
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              label={"City"}
              isRequired
              placeholder={"Ikeja"}
              error={errors?.city?.message}
            />
          )}
        />
        <Controller
          name="lga"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              label={"Local Government Area"}
              isRequired
              placeholder={"Ondo West"}
              error={errors?.lga?.message}
            />
          )}
        />
        <Controller
          name="streetAddress"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              isRequired
              label={"Street Address"}
              placeholder={"No 1 bourdillon Rd Ikoyi"}
              error={errors?.streetAddress?.message}
            />
          )}
        />
        <button
          type="submit"
          //   onClick={saveContactInfoHandler}
          className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm"
        >
          Save & Continue
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z"
              fill="white"
            />
          </svg>
        </button>
      </Form>

      <StepperInfo>
        <div className="w-full col-span-2 flex flex-col items-center">
          <Flex vertical gap="small">
            <Progress
              strokeColor="#ff6700"
              showInfo={false}
              strokeLinecap="butt"
              type="circle"
              percent={20}
            />
            <h6>1 of 5 complete</h6>
          </Flex>

          <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
            <button className="absolute -right-3 -top-3">
              <CloseIcon />
            </button>
            <h5 className="text-xl font-semibold">Download our App</h5>
            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">
              Download our mobile application for better experience and to
              receive custom notifications in real time.{" "}
            </p>
            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
              <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                <button className="mx-2">
                  <AndroidIcon />
                </button>
                <button className="mx-2">
                  <IOSIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </StepperInfo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;

const Form = styled.form`
  width: 70%;
`;

const StepperInfo = styled.div`
  width: 30%;
  margin-left: 10%;
`;
