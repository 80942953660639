import React, { useRef, useState } from "react";
import styled from "styled-components";
import { numberedSteps } from "./components/ActiveScreenHeader.jsx";
import { ActiveScreen } from "./ActiveScreen.jsx";
import { ActiveStepper } from "./components/ActiveStepper.jsx";

export const KYCForm = () => {
  const [activeStep, setActiveStep] = useState(numberedSteps.STEP_ONE);
  const [completed, setCompleted] = React.useState({});

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const navigateByStep = (step) => {
    setActiveStep(step);
  };

  const handleToStep1 = () => {
    setActiveStep(numberedSteps.STEP_ONE);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    window.scrollTo(0, 0);
  };

  return (
    <Wrapper>
      <Container>
        <ActiveStepper navigateByStep={navigateByStep} activeStep={activeStep}/>
        <ActiveScreen activeStep={activeStep} handleComplete={handleComplete} handleToStep1={handleToStep1}/>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Container = styled.div`
  width: 90%;
  margin: 2rem auto;
  justify-content: space-between;
`;
