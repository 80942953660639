import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { AddIcon, CogIcon, UserPlusIcon, BackIcon } from "../assets";
import { Table } from "./index.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import {
  getAllPermissions,
  getRole,
  deleteRole,
  createCustomRoleRequest,
  getAvailableRoles,
  changeRole,
  getAllUsers,
  getAllMerchantTeamMembers,
} from "../services/requests/roles";
import Overlay from "./Overlay";
import UserInviteForm from "./Roles/UserInviteForm";
import SuccessInvite from "./Roles/SuccessInvite";
import DeleteInvitedUser from "./Roles/DeleteInvitedUser";
import UpdateUserInvite from "./Roles/UpdateUserInvite";
// import ChangeOwner from "./Roles/ChangeOwner";
import ChangeOwnerNew from "./Roles/ChangeOwnerNew";
import CreateCustomRole from "./Roles/CreateCustomRole";
import { CreateNewTeamMember } from "./Roles/CreateNewTeamMember.jsx";
import styled from "styled-components";
import { formatShortDate } from "../utils/FormatDatetime.js";
import timeIcon from "../assets/time.svg";
import { Search } from "./form/Search.jsx";
import { useDebounced } from "../hooks/useDebounced.js";
import { getCurrentMerchant } from "../services/requests/customers.js";

function RoleAccessInner() {
  const [allUser, setAllUser] = useState([]);
  const [whatRoleCannotDo, setWhatRoleCannotDo] = useState([]);
  const [users, setUsers] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [popup, setPopup] = useState("inviteUser");
  const [changeUserId, setChangeUserId] = useState(0);
  const [activeUserName, setActiveUserName] = useState("");
  const [showPermission, setShowPermission] = useState(false);
  const [activeRole, setActiveRole] = useState({
    id: 0,
    roleName: "",
    users: [],
    permissions: [],
  });
  const [merchantUserId, setMerchantUserId] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedDataToDelete, setselectedDataToDelete] = useState(null);
  const [roleToDelete, setRoleToDelete] = useState();
  const [newRolePayload, setNewRolePayload] = useState({
    id: "",
    roleName: "",
    roleDescription: "",
  });
  const [permissions, setPermissions] = useState([]);
  const [changeOwnerPayload, setChangeOwnerPayload] = useState({
    name: "Ladejobi Blessing",
  });
  const [changeRolePayload, setChangeRolePayload] = useState();
  const [teamMemberSearchValue, setTeamMemberSearchValue] = useState("");

  const tableColumnStructure = [
    { field: "name", headerName: "Full Name", width: 200 },
    { field: "id", headerName: "User ID", width: 120 },
    { field: "role", headerName: "Role", width: 200 },
    { field: "accessTypes", headerName: "Access Type", width: 200 },
    { field: "status", headerName: "Status", width: 200 },
    { field: "lastLogin", headerName: "Last Login", width: 230 },
    {
      field: "action",
      headerName: "Actions",
      width: 220,
      renderCell: (params) => {
        // console.log('params', params);
        const updateRolePopup = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          showOverlayHandler("updateRole");
          const selectedRole = availableRoles.filter(
            (role) => role.label === params.row.role
          );
          setChangeRolePayload({
            roleId: selectedRole[0]?.value,
          });
          setActiveUserName(params.row.fullName);
          setChangeUserId(params.row.id);

          // const data = allTrxn.filter(elem => elem.refNo === params.row.id);
          // if (data.length === 1) {
          //     setShowPopup(true);
          //     setTransactionDetails(data[0]);
          //     console.log(data);
          // }
        };
        const changeRolePopup = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          // setSelectedData(params.row);
          showOverlayHandler("changeOwner");
        };
        const showDeletePopup = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setselectedDataToDelete(params.row);
          showOverlayHandler("deleteUser");
        };

        return (
          <>
            {params.row.role === "OWNER" ? (
              <button
                onClick={changeRolePopup}
                className="text-sm py-2 px-3 rounded-lg bg-primary-theme_ text-black border border-gray-200"
              >
                Change Business Owner
              </button>
            ) : (
              <div className="flex items-center gap-2">
                <button
                  onClick={updateRolePopup}
                  className="text-sm py-2 px-3 rounded-lg bg-primary-theme_ text-black border border-gray-200"
                >
                  Change Role
                </button>
                <button
                  className="text-sm py-2 px-3 rounded-lg bg-primary-theme_ text-black border border-gray-200"
                  onClick={showDeletePopup}
                >
                  Remove
                </button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getCurrentMerchant().then((data) => {
      setMerchantUserId(data?.data?.userId);
    });
  }, []);

  const handleTeamMemberSearch = () => {
    if (teamMemberSearchValue) {
      const filteredUser = users?.filter((data) =>
        data?.fullName
          .toLocaleLowerCase()
          .includes(teamMemberSearchValue.toLocaleLowerCase())
      );
      setUsers(filteredUser);
    } else {
      getAllTeamMembers();
    }
  };

  let teamMemberDebounce = useDebounced(teamMemberSearchValue, 1000);

  useEffect(() => {
    handleTeamMemberSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMemberDebounce]);
  const getAllTeamMembers = () => {
    getAllMerchantTeamMembers(merchantUserId)
      .then((response) => {
        const newUserStructure = response.map((user) => ({
          id: user?.id,
          name: user?.name + "\n" + user?.emailAddress,
          fullName: user?.name,
          // email: user?.emailAddress,
          lastLogin: formatShortDate(user?.createdAt),
          role: user?.roles?.length > 0 ? user?.roles[0] : "N/A",
          accessTypes:
            user?.accessTypes?.length > 0 ? user?.accessTypes[0] : "N/A",
          status: user?.status ? user?.status : "N/A",
        }));
        setUsers(newUserStructure);
      })
      .catch((error) => {
        console.log("Error occured");
      });
  };
  useEffect(() => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
    let referenceCode;
    if (JSON.parse(sessionStorage.getItem("otherInfo"))) {
      referenceCode = JSON.parse(
        sessionStorage.getItem("otherInfo")
      ).referenceCode;
    }
    setPermissions([]);
    getAllTeamMembers();
    getAllRoles();
  }, [merchantUserId]);

  let sortedDataDESC = users ? [...users] : null;
  const tableRowData = sortedDataDESC;

  const getAllRoles = () => {
    getAvailableRoles()
      .then((response) => {
        setAllRoles(response);
        const roleResponse = response?.map((elem, index) => ({
          label: elem.role,
          value: elem.id,
        }));

        const testResponse = [
          { label: "test transaction", value: "test transaction" },
        ];
        setActiveRoleHandler(response[0].id);
        if (roleResponse.length < 1) {
          setAvailableRoles(testResponse);
        } else {
          setAvailableRoles(roleResponse);
        }
      })
      .catch((error) => {
        console.log("Error occured");
      });
  };

  const createCustomRole = (e) => {
    e.preventDefault();
    if (newRolePayload.roleName && newRolePayload.roleDescription) {
      const payload = {
        description: newRolePayload.roleDescription,
        id: newRolePayload?.id ? newRolePayload?.id : 0,
        permissions: permissions,
        role: newRolePayload.roleName,
      };
      createCustomRoleRequest(payload)
        .then((response) => {
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          // setPopup("successful");
        })
        .catch((error) => {
          toast.error("Unable to invite");
        });
    } else {
      toast.error("All fields are required!");
    }
  };

  const updateUserRoleHandler = () => {
    const payload = {
      roleId: changeRolePayload.roleId,
      userId: changeUserId,
    };

    if (payload.roleId && payload.userId) {
      changeRole(payload)
        .then((response) => {
          toast.success(response.message);
          setPopup("successful");
        })
        .catch((error) => {
          toast.error("Internal error occured while getting permimssion ID");
        });
    } else {
      toast.error("All fields are required!");
    }
  };

  const deleteInvitedUserHandler = () => {
    alert(JSON.stringify(selectedDataToDelete));
    if (selectedDataToDelete.id) {
      deleteRole(selectedDataToDelete.id)
        .then((response) => {
          toast.success(response.message);
          setPopup("successful");
        })
        .catch((error) => {
          toast.error("Internal error occured while getting permimssion ID");
        });
    } else {
      toast.error("All fields are required!");
    }
  };

  const changeOwnerHandler = () => {
    alert(JSON.stringify(changeOwnerPayload));
  };

  const showOverlayHandler = (name) => {
    setShowOverlay(true);
    setPopup(name);
  };
  const showPermissionHandler = () => {
    setShowPermission(true);
  };
  const goBackToUsersHandler = () => {
    setShowPermission(false);
  };

  const handleUpdateUserSelectChange = (e, name) => {
    setChangeRolePayload({
      ...changeRolePayload,
      [name]: e.value,
    });
  };

  const handleUpdateOwnerSelectChange = (e, name) => {
    setChangeOwnerPayload({
      ...changeOwnerPayload,
      [name]: e.value,
    });
  };
  const handleNewRoleInputChange = (e) => {
    const _name = e.target.name;
    setNewRolePayload({
      ...newRolePayload,
      [_name]: e.target.value,
    });
  };

  const setActiveRoleHandler = async (index) => {
    getRole(index)
      .then(async (response) => {
        setActiveRole({
          id: response.id,
          roleName: response.role,
          users: response.users,
          permissions: response.permissions,
        });
        const _permissions = await getAllPermissions();
        // console.log(response.permissions)

        let _whatRoleCannotDo = [];
        _permissions.forEach((permission) => {
          const matchedPermission = response.permissions.filter(
            (p) => p.name === permission.name
          );
          if (matchedPermission?.length) {
            // console.log();
          } else {
            _whatRoleCannotDo.push(permission.name);
          }
        });

        setWhatRoleCannotDo(_whatRoleCannotDo);
      })
      .catch((error) => {
        console.log("Error occured");
        toast.error("Error occured");
      });
  };

  return (
    <>
      <ToastContainer />
      {!showPermission && (
        <div className="bg-gray-50">
          {/* start of popups */}
          <Overlay showOverlay={showOverlay} setShowOverlay={setShowOverlay}>
            {popup === "inviteUser" && (
              <UserInviteForm
                availableRoles={availableRoles}
                setPopup={setPopup}
                setShowOverlay={setShowOverlay}
              />
            )}
            {popup === "createNewTeamMember" && (
              <CreateNewTeamMember
                availableRoles={availableRoles}
                setPopup={setPopup}
              />
            )}
            {popup === "successful" && <SuccessInvite />}
            {popup === "deleteUser" && (
              <DeleteInvitedUser
                selectedDataToDelete={selectedDataToDelete}
                setPopup={setPopup}
                deleteInvitedUserHandler={deleteInvitedUserHandler}
              />
            )}
            {popup === "updateRole" && (
              <UpdateUserInvite
                availableRoles={availableRoles}
                changeUserId={changeUserId}
                activeUserName={activeUserName}
                setPopup={setPopup}
                // updateUserRoleHandler={updateUserRoleHandler}
                // changeRolePayload={changeRolePayload}
                // handleUpdateUserSelectChange={handleUpdateUserSelectChange}
              />
            )}
            {popup === "changeOwner" && (
              <ChangeOwnerNew
                handleUpdateOwnerSelectChange={handleUpdateOwnerSelectChange}
                changeOwnerHandler={changeOwnerHandler}
                allInvitedUsers={users}
                changeOwnerPayload={changeOwnerPayload}
              />
            )}
          </Overlay>
          {/* End of popups */}
          <div className="px-8 pt-6 pb-2">
            <Link to="/setting/refferal" className="flex items-center gap-2">
              <BackIcon />
              Back
            </Link>
          </div>

          <div className="bg-white">
            <div className="flex px-8 pt-8 items-center justify-between gap-2">
              <h4 className="font-semibold text-xl">
                Team members-{tableRowData.length}
              </h4>
              <div className="flex items-center gap-2">
                <button
                  onClick={showPermissionHandler}
                  className="flex items-center gap-2 bg-gray-100 text-sm py-3 px-4 rounded-lg"
                >
                  <CogIcon /> Manage role
                </button>
                <button
                  onClick={() => showOverlayHandler("inviteUser")}
                  className="flex items-center gap-2 bg-primary-theme text-white text-sm py-3 px-4 rounded-lg"
                >
                  <UserPlusIcon /> Invite Someone
                </button>
                {/* <button
                  onClick={() => showOverlayHandler("createNewTeamMember")}
                  className="flex items-center gap-2 bg-primary-theme text-white text-sm py-3 px-4 rounded-lg"
                >
                  <UserPlusIcon /> Create new User
                </button> */}
              </div>
            </div>
            {/* <div className="bg-blue-500 text-white p-4">
              Default Tailwind Color Test
            </div>

            <div className="bg-red-500 text-white p-4">Test Tailwind</div> */}
            <SearchContainer>
              <Search
                placeholder={"Search Team Member By Name"}
                value={teamMemberSearchValue}
                onChange={(e) => setTeamMemberSearchValue(e.target.value)}
              />
              {/* Todo: Uncomment if needed */}
              {/* <div className="flex items-center gap-2 mt-4">
                <button
                  onClick={showPermissionHandler}
                  className="flex items-center gap-2 bg-gray-100 text-sm py-3 px-4 rounded-lg"
                >
                  <CogIcon /> Filter By Role
                </button>
                <button
                  onClick={showPermissionHandler}
                  className="flex items-center gap-2 bg-gray-100 text-sm py-3 px-4 rounded-lg"
                >
                  <img
                    src={timeIcon}
                    alt="time"
                    className="time-icon"
                    title="filter"
                  />
                  Filter By Last Login
                </button>
              </div> */}
            </SearchContainer>
            <Table
              height={"500px"}
              tableColumnStructure={tableColumnStructure}
              tableRowData={
                tableRowData && tableRowData.length > 0 ? tableRowData : null
              }
              pageName="Users"
            />
          </div>
        </div>
      )}
      {showPermission && (
        <>
          <div className="bg-gray-50">
            {/* start of popups */}
            <Overlay showOverlay={showOverlay} setShowOverlay={setShowOverlay}>
              {popup === "createCustomRole" && (
                <CreateCustomRole
                  handleNewRoleInputChange={handleNewRoleInputChange}
                  permissions={permissions}
                  setPermissions={setPermissions}
                  createCustomRole={createCustomRole}
                  newRolePayload={newRolePayload}
                  setNewRolePayload={setNewRolePayload}
                />
              )}
            </Overlay>

            {/* End of popups */}
            <div className="px-8 pt-6 pb-2">
              <p
                onClick={goBackToUsersHandler}
                className="flex cursor-pointer items-center gap-2"
              >
                <BackIcon />
                Back
              </p>
            </div>

            <div className="bg-white p-8">
              <div className="border border-gray-200 flex items-start rounded-lg">
                <div
                  style={{ minWidth: "180px" }}
                  className="py-8 h-screen border-r border-gray-200"
                >
                  <h5 className="text-lg font-semibold px-4 text-left">
                    All Roles
                  </h5>
                  {allRoles?.length > 0 && (
                    <div className="pt-8 h-96 overflow-y-scroll">
                      {allRoles?.map((data) => {
                        if (data?.role) {
                          if (activeRole?.id === data?.id) {
                            // Active role
                            return (
                              <p
                                onClick={() => setActiveRoleHandler(data?.id)}
                                className="text-gray-500 cursor-pointer px-4 text-primary-theme text-sm h-12 flex items-center bg-red-50"
                              >
                                {data?.role}
                              </p>
                            );
                          } else {
                            // Not active role
                            return (
                              <p
                                onClick={() => setActiveRoleHandler(data?.id)}
                                className="text-gray-500 cursor-pointer border border-gray-100 px-4 text-sm h-12 flex items-center bg-white"
                              >
                                {data?.role}
                              </p>
                            );
                          }
                        }
                      })}
                    </div>
                  )}

                  {/* <h5 className="text-lg font-semibold px-4 text-left">Custom Roles</h5> */}
                  <div className="pt-0">
                    {/* {
                                                customRolesList.map((role, index) => {
                                                    if(activeRole===index){
                                                        // Active role
                                                        return (
                                                            <p onClick={()=> setActiveRole(index)} className="text-gray-500 cursor-pointer px-4 text-primary-theme text-sm h-12 flex items-center bg-red-50">{role}</p>
                                                        )
                                                    }else{
                                                        // Not active role
                                                        return(
                                                            <p onClick={()=> setActiveRole(index)} className="text-gray-500 cursor-pointer border border-gray-100 px-4 text-sm h-12 flex items-center bg-white">{role}</p>
                                                        )
                                                    }
                                                })   
                                            } */}
                    <p
                      onClick={() => showOverlayHandler("createCustomRole")}
                      className="text-gray-500 cursor-pointer border border-gray-100 px-4 text-xs h-12 flex items-center bg-white gap-2"
                    >
                      <AddIcon /> Add Role
                    </p>
                  </div>
                </div>
                <div className="w-full px-4 py-8 h-screen">
                  <HeaderSection>
                    <HeadContent>
                      <h3 className="header-text">
                        {activeRole?.roleName.toLocaleLowerCase()}
                      </h3>
                    </HeadContent>
                    <p className="head-description">
                      {"This role grants users the permissions listed below."}
                    </p>
                  </HeaderSection>

                  {/* <h5 className="text-lg font-semibold px-4 text-left capitalize">
                    {activeRole.roleName} 
                  </h5>
                  <p className="text-md px-4">
                    This role grants users the permissions listed below.
                  </p> */}
                  <div className="px-4 py-2 ">
                    <p className="border border-gray-100 rounded p-2 text-sm text-gray-500">
                      <b>
                        {activeRole?.users?.length} Member(s) with this role:
                      </b>
                      <RoleMembers>
                        {activeRole?.users?.map((member) => (
                          <span className="text-gray-400 pl-2">
                            {member.fullName},
                          </span>
                        ))}
                      </RoleMembers>
                    </p>

                    <RoleContainer className="grid grid-cols-2 gap-4 py-4">
                      <RolesAccessed className="border border-green-200 rounded">
                        <p className="text-green-500 bg-green-50 h-12 w-full flex items-center text-sm px-2 capitalize">
                          {activeRole.roleName} Can
                        </p>
                        {/* permission list */}
                        <RoleCan>
                          {activeRole?.permissions?.map((elem, index) => (
                            <p
                              className={`${
                                index % 2 == 0
                                  ? "text-gray-500 bg-white"
                                  : "text-gray-500 bg-gray-50"
                              } h-12 w-full flex items-center text-sm px-2`}
                            >
                              {elem.name.split("_").join(" ")}
                            </p>
                          ))}
                        </RoleCan>
                      </RolesAccessed>
                      <RolesAccessed className="border border-gray-200 rounded">
                        <p className="text-red-500 bg-red-50 h-12 w-full flex items-center text-sm px-2">
                          {activeRole.roleName} Cannot
                        </p>
                        {/* not permitted todo */}
                        <RoleCannot className="h-96 overflow-y-scroll">
                          {whatRoleCannotDo.map((elem, index) => (
                            <p
                              className={`${
                                index % 2 == 0
                                  ? "text-gray-500 bg-white"
                                  : "text-gray-500 bg-gray-50"
                              } h-12 w-full flex items-center text-sm px-2`}
                            >
                              {elem}
                            </p>
                          ))}
                        </RoleCannot>
                      </RolesAccessed>
                    </RoleContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RoleAccessInner;

const HeaderSection = styled.h3`
  padding: 0 10px;
  .header-text {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
  }
  .head-description {
    font-size: 16px;
    color: #1d2739;
    margin: 0;
  }
`;
const HeadContent = styled.div`
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  width: 40%;
  padding: 0 2rem;
`;

const RoleCan = styled.div`
  height: 320px;
  max-height: 350px !important;
  overflow-y: scroll;
`;

const RoleCannot = styled.div`
  height: 320px;
  max-height: 350px !important;
  overflow-y: scroll;
`;
const RoleContainer = styled.div`
  border: 1px solid transparent;
  height: 420px;
  max-height: 425px !important;
  overflow: hidden !important;
`;

const RoleMembers = styled.div`
  max-height: 200px !important;
  overflow-y: scroll;
`;
const RolesAccessed = styled.div`
  height: 390px;
  max-height: 400px !important;
  overflow: hidden !important;
`;
