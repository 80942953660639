import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { WayaPayLogo, WayaPosIcon } from "../assets/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PrivacyIcon,
  HomeIcon,
  TransactionIcon,
  SettlementIcon,
  CustomerIcon,
  SubscriptionIcon,
  PaymentLinkIcon,
  DisputeIcon,
  NotificationIcon,
  WayabankIcon,
  WayagramIcon,
  LogoutIcon,
  GetstartedIcon,
  SupportIcon,
  ActivityLogIcon,
  SettingIcon,
  CopyToClipboardIcon,
  CloseIcon,
} from "../assets";
import axios from "axios";
import config from "../config.js";
import PinImg from "../assets/secure-pin.png";
import { ApikeysContext } from "../context/ApikeysContext";
import newLogo from "../assets/newLogo.png";

import {
  sendOTPToEmail,
  sendOTPToPhone,
  createPin,
} from "../services/requests/auth";
import FetchApi from "../utils/FetchApi.js";
import { RoleAccessSideBar } from "./RoleAccessSideBar.jsx";

function Sidebar({ sidebarState, toggleSidebar }) {
  const { approvalStatus } = useContext(ApikeysContext);
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState("");
  const [merchantId, setMerchantId] = useState(null);
  const [businessId, setBusinessId] = useState("");
  const [pinPopup, setPinPopup] = useState(false);
  const [isTeamMember, setIsTeamMember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pinOTP, setPinOTP] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const [tab, setTab] = useState(1);
  const [pin, setPin] = useState("");
  const [pin2, setPin2] = useState("");
  const [medium, setMedium] = useState("");
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const snackbar = useRef();
  useEffect(() => {
    const bussinessName = sessionStorage.getItem("businessName");
    const isTeamMember = JSON.parse(sessionStorage.getItem("isTeamMember"));
    const customerBusinessName =
      JSON.parse(sessionStorage.getItem("userData")).firstName +
      " " +
      JSON.parse(sessionStorage.getItem("userData")).surname;
    const merchantId = sessionStorage.getItem("merchantId");
    let userData = sessionStorage.getItem("userData");
    let pinCreated;
    if (userData) {
      pinCreated = JSON.parse(userData).pinCreated;
    }

    const merchantBusinessName = isTeamMember
      ? customerBusinessName
      : bussinessName;
    console.log("here", merchantBusinessName);
    setBusinessName(merchantBusinessName);
    if (merchantId?.length > 0) {
      setMerchantId(JSON.parse(merchantId));
    }

    if (!pinCreated) {
      setPinPopup(true);
    }
    const userInfo = userData ? JSON.parse(userData) : "";
    setIsTeamMember(userInfo?.teamMember);
  }, []);

  useEffect(() => {
    const authorization = JSON.parse(sessionStorage.getItem("token"));
    const id = JSON.parse(sessionStorage.getItem("userData")).id;
    const response = FetchApi({
      endpoint: `/auth-service/api/v1/profile/${id}`,
      authorization: authorization,
      method: "GET",
    });
    response
      .then((resp) => {
        const businesLoginId = resp?.loginId ?? "";
        setBusinessId(businesLoginId);
        sessionStorage.setItem("otherInfo", JSON.stringify(resp));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(sidebarState)

  const logoutHandler = () => {
    sessionStorage.clear();
    window.location.reload();
    // window.location.href = `${config.BASE_URL_AUTH}/login?from=web&token=true`;
  };

  const animateSnackbar = () => {
    snackbar.current.classList.add("show-snackbar");
    setTimeout(() => {
      snackbar.current.classList.remove("show-snackbar");
    }, 3000);
  };
  const copyMerchantId = () => {
    navigator.clipboard.writeText(merchantId);
    toast("Copied");
    // animateSnackbar()
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const SendPINOTP = () => {
    const _email = JSON.parse(sessionStorage.getItem("userData")).email;
    const _phoneNumber = JSON.parse(
      sessionStorage.getItem("userData")
    ).phoneNumber;
    // const otherDetails = JSON.parse(sessionStorage.getItem("otherInfo"));
    // // const otherDetails = JSON.parse(sessionStorage.getItem("otherInfo"));
    // const businesId = otherDetails?.loginId;
    // console.log("id", businesId, otherDetails);
    // console.log("got here", medium);
    if (medium === "email") {
      if (validateEmail(_email)) {
        sendOTPToEmail(_email, businessId)
          .then((response) => {
            console.log("gothere", response);
            if (response?.status === true) {
              toast.success(response?.message);
              // toast('OTP sent to your email');
              setTab(2);
            } else {
              toast.error(response?.message);
            }
          })
          .catch((error) => {
            toast("Error occured: ");
          });
      } else {
        toast.error("Invalid email address");
      }
    } else if (medium === "phone") {
      sendOTPToPhone(_phoneNumber, businessId)
        .then((response) => {
          console.log("gothere", response);
          if (response?.status === true) {
            toast.success(response?.message);
            // toast('OTP sent to your email');
            setTab(2);
          } else {
            toast.error(response?.message);
          }
        })
        .catch((error) => {
          toast.error("Error occured: ");
        });
    } else {
      toast("Kindly select medium to receive OTP ");
    }
  };
  const SetupPin = () => {
    setLoading(true);
    const _email = JSON.parse(sessionStorage.getItem("userData")).email;
    const _phoneNumber = JSON.parse(
      sessionStorage.getItem("userData")
    ).phoneNumber;
    const businessId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
    const authorization = JSON.parse(sessionStorage.getItem("token"));

    if (pin === pin2) {
      if (pin && pinOTP && (_email || _phoneNumber)) {
        if (pin.length === 4) {
          const changePwdData = {
            pin: pin,
            otp: pinOTP,
            phoneOrEmail: _email || _phoneNumber,
            businessId,
          };
          createPin(changePwdData)
            .then((response) => {
              if (response.status === true) {
                toast(response?.message);
                let userData = JSON.parse(sessionStorage.getItem("userData"));
                userData.pinCreated = true;
                sessionStorage.setItem("userData", JSON.stringify(userData));
              } else {
                toast.error(response?.message);
                toast(response?.message);
              }
              window.location.reload();
            })
            .catch((err) => {
              toast("Error occured");
            });
        } else {
          toast.error("Pin length is wrong");
        }
      } else {
        toast.error("OTP/Phone-number/Email not found");
      }
    } else {
      toast.error("Pin must be the same");
    }
  };
  return (
    <>
      {pinPopup && isTeamMember === false && (
        <div
          className="w-full h-screen bg-gray-800 bg-opacity-50 fixed top-0 left-0 backdrop-filter backdrop-blur-sm flex justify-center items-center"
          style={{ zIndex: 100 }}
        >
          <div
            style={{ minWidth: "420px" }}
            className="w-2/5 h-auto bg-white rounded-lg -mt-20 py-10 relative"
          >
            <span>
              <img
                src={PinImg}
                alt="pin change avatar"
                className="w-32 h-32 rounded-full flex mx-auto"
              />
            </span>
            <h4 className="font-semibold text-2xl flex justify-center mt-5 w-full">
              Create Secure Pin
            </h4>
            <p className="font-medium text-lg text-center text-gray-400">
              Keep your account safe with a 4 digit pin
            </p>

            {tab === 1 && (
              <div className="w-full px-24">
                <p className="font-medium text-lg text-left text-gray-800 mt-8">
                  How do you want to receive OTP?
                </p>
                <select
                  onChange={(e) => setMedium(e.target.value)}
                  placeholder="Confirm your pin"
                  className="w-full px-3 my-5 h-10 block rounded border border-gray-300"
                >
                  <option>Select otp option</option>
                  <option value="phone">Using my phone number</option>
                  <option value="email">Using my Email address</option>
                </select>
                <button
                  type="submit"
                  onClick={SendPINOTP}
                  className="py-2 rounded w-full bg-primary-theme text-white fle justify-center items-center"
                >
                  Get OTP
                </button>
              </div>
            )}

            {tab === 2 && (
              <div className="w-full px-24">
                <input
                  type="number"
                  onChange={(e) => setPinOTP(e.target.value)}
                  value={pinOTP}
                  placeholder="Enter your OTP"
                  className="w-full px-3 my-5 h-10 block rounded border border-gray-300"
                />

                <input
                  type="number"
                  onChange={(e) => setPin(e.target.value)}
                  value={pin}
                  placeholder="Enter your pin"
                  className="w-full px-3 my-5 h-10 block rounded border border-gray-300"
                />

                <input
                  type="number"
                  onChange={(e) => setPin2(e.target.value)}
                  value={pin2}
                  placeholder="Confirm your pin"
                  className="w-full px-3 my-5 h-10 block rounded border border-gray-300"
                />

                <button
                  type="button"
                  onClick={SetupPin}
                  className="py-2 rounded w-full bg-primary-theme text-white fle justify-center items-center"
                >
                  Set Pin
                </button>

                <p
                  onClick={() => setTab(1)}
                  className="my-2 text-sm text-primary-theme cursor-pointer"
                >
                  Go back
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={`${sidebarState} z-30 sidebar bg-white shadow z-20 sticky left-0 top-0 lg:block lg:sticky top-0 border-r border-gray-100`}
      >
        <ToastContainer />
        <div className="sidebar-navbar pt-2 px-3">
          <div className=" flex flex-col rounded bg-gray-50 items-start shadow p-3 relative box-content">
            <span
              onClick={toggleSidebar}
              className="absolute cursor-pointer flex lg:hidden right-0 top-2 z-30"
            >
              <CloseIcon />
            </span>
            <img src={newLogo} width="125px" height="32px" alt="" />
            {
              //} <WayaPayLogo />
            }
            <h5 className="font-medium mt-2 text-lg">{businessName}</h5>
            <p className="text-gray-500 flex items-center gap-2 text-xs">
              Merchant ID: {merchantId}
              <span onClick={copyMerchantId} className="cursor-pointer">
                <CopyToClipboardIcon />
              </span>
            </p>
          </div>
        </div>

        {/* <p className="text-primary-theme px-6 mt-10 text-md font-semibold text-center">Switch Wayapay Product</p> */}

        {/* <div className="platform-toggle mt-12 px-6">
                        <div className="w-full h-10 flex justify-between items-center relative bg-gray-100 rounded-full">
                            <Link to="/wayapay-web" className="block rounded-tl-full rounded-bl-full bg-primary-theme h-10 items-center justify-center flex text-white w-1/2 font-semibold block left-3 top-3 text-xs ...">Wayapay Web</Link>
                            <Link to="/wayapay-pos" className="block rounded-tr-full rounded-br-full h-10 items-center justify-center flex text-gray-600 w-1/2 font-semibold block right-3 top-3 text-xs ...">Wayapay POS</Link>
                        </div>
                    </div> */}

        <ul className="mt-6 sidebar-nav-container border-b border-gray-100">
          <li>
            <NavLink
              to="/"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
            >
              <GetstartedIcon />
              Get Started
            </NavLink>
          </li>
          {/* 
          {(!approvalStatus ||
            approvalStatus === "DRAFT" ||
            approvalStatus === "REJECTED") && (
            <li className="">
              <NavLink
                to="/submit-kyc"
                className={`${(navData) =>
                  navData.isActive
                    ? "active  text-primary-theme"
                    : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
              >
                <PrivacyIcon />
                Submit KYC
              </NavLink>
            </li>
          )} */}
          <RoleAccessSideBar />

          <li className="">
            <NavLink
              to="/setting"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
            >
              <SettingIcon />
              Settings
            </NavLink>
          </li>

          <li className="">
            <NavLink
              to="/support"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
            >
              <SupportIcon />
              Support
            </NavLink>
          </li>

          <li className="">
            <NavLink
              to="/activity-log"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
            >
              <ActivityLogIcon />
              Activity Log
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="https://wayabank.ng"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
            >
              <WayabankIcon />
              Bank SIte [WAYABANK]
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="https://app.wayapos.ng/dashboard"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 my-5 `}
            >
              <WayaPosIcon />
              Merchant/Agent POS Site [WAYAPOS]
            </NavLink>
          </li>
          <li className="">
            <NavLink
              to="https://app.wayagram.ng/wayagram"
              className={`${(navData) =>
                navData.isActive
                  ? "active  text-primary-theme"
                  : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}
            >
              <WayagramIcon />
              Social & Marketplace Site [WAYAGRAM]
            </NavLink>
          </li>
        </ul>
        <ul className="mt-1">
          {/* <li className="">
                        <a href="https://wayabank.ng" target="_blank" className={`${(navData) => navData.isActive ? "active  text-primary-theme" : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}>
                            <WayabankIcon />
                            Wayabank
                        </a>
                    </li>

                    <li className="">
                        <a href="https://wayapos.ng" target="_blank" className={`${(navData) => navData.isActive ? "active  text-primary-theme" : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}>
                            <TransactionIcon />
                            Wayapos
                        </a>
                    </li>

                    <li className="">
                        <a href="https://wayagram.ng" target="_blank" className={`${(navData) => navData.isActive ? "active  text-primary-theme" : "text-gray-500"} w-full text-sm font-medium gap-2 flex items-center h-10 px-8 `}>
                            <WayagramIcon />
                            Wayagram
                        </a>
                    </li> */}

          <li className="">
            <Link
              to="/#logout"
              onClick={logoutHandler}
              className="w-full text-sm font-medium gap-2 flex items-center h-10 px-8 text-primary-theme"
            >
              <LogoutIcon />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
