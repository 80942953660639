import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { TextInputWithIcon } from "./form/TextInput";
import { SelectInputWithIcon } from "./form/SelectInput";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import {
  getCurrentMerchant,
  getMerchantLoginId,
} from "../services/requests/customers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextAreaWithIcon } from "./form/TextArea";
import uploadIcon from "../assets/upload-cloud.svg";
import checkIcon from "../assets/green-check.svg";
import binIcon from "../assets/bin.svg";
import { formatShortDate } from "../utils/FormatDatetime";
import { changeBytesToMB } from "../utils/shortenLink";
import { raiseDisputeComplaint } from "../services/requests/disputes";
import axios from "axios";
import config from "../config";

const Loader = () => {
  return (
    <div
      className="h-6 w-6 rounded-full animate-spin"
      style={{
        border: "3px solid #ffffff",
        borderRight: "3px solid transparent",
      }}
    ></div>
  );
};

const disputeCategories = [
  {
    label: "Chargeback",
    value: "CHARGEBACK",
  },
  // {
  //   label: "Fraud",
  //   value: "FRAUD",
  // },
  {
    label: "Others",
    value: "OTHERS",
  },
  {
    label: "Auth Complaints",
    value: "AUTHCOMPLAINT",
  },
];
const uploadedDate = new Date();
const schema = yup
  .object({
    disputeCategory: yup.object().required("Dispute Category is required"),
    disputeDescription: yup
      .string()
      .required("Dispute Description is required"),
    disputeAmount: yup.string().required("Dispute Amount is required"),
    transactionCharge: yup.string().required("Transaction Charge  is required"),
    transactionDate: yup.string().required("Transaction Charge  is required"),
  })
  .required();

export const CreateNewDispute = ({ setPopup, setShowOverlay }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      disputeCategory: "",
      disputeDescription: "",
      disputeAmount: "",
      transactionCharge: "",
      transactionDate: "",
    },
    resolver: yupResolver(schema),
  });

  const [currentMerchant, setCurrentMerchant] = useState();
  const [merchantUserId, setMerchantUserId] = useState();
  const [merchantLoginId, setMerchantLoginId] = useState();
  const [isDocUploaded, setIsDocUploaded] = useState(false);
  const [disputeProofUrl, setDisputeProofUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [docName, setDocName] = useState("");
  const [uploadedDoc, setUploadedDoc] = useState();
  const fileUpload = useRef();

  useEffect(() => {
    getCurrentMerchant().then((data) => {
      setCurrentMerchant(data?.data?.merchantId);
      setMerchantUserId(data?.data?.userId);
    });
  }, []);

  useEffect(() => {
    getMerchantLoginId(merchantUserId)
      .then((data) => {
        setMerchantLoginId(data?.data?.loginId);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }, [merchantUserId]);

  const handleFileUpload = (e) => {
    setIsDocumentLoading(true);
    const [file] = e.target.files || [];
    if (file?.size > 1536000) {
      toast.error("Image too large. Can't support more than 1.5MB");
      setIsDocumentLoading(false);
    } else {
      setUploadedDoc(file);
      setDocName(file?.name);
      const authorization = JSON.parse(sessionStorage.getItem("token"));
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: authorization,
          "CLIENT-ID": "WAYAQUICK",
          "CLIENT-TYPE": "CORPORATE",
        },
      };
      let payload = new FormData();
      payload.append("file", file);
      payload.append("fileName", "disputeProof");
      payload.append(
        "userId",
        JSON.parse(sessionStorage.getItem("userData")).id
      );

      setTimeout(() => {
        axios
          .post(
            `${config.BASE_URL}/file-resource-service/upload/others`,
            payload,
            header
          )
          .then((resp) => {
            if (resp?.data?.status === true) {
              setIsDocumentLoading(false);
              toast.success("Uploaded successfully");
              setDisputeProofUrl(resp?.data?.data);
              setIsDocUploaded(true);
            } else {
              setIsDocumentLoading(false);
              setIsDocUploaded(false);
              toast.error("Could not upload");
            }
          })
          .catch((err) => {
            setIsDocumentLoading(false);
            toast.error(err.response);
          });
      }, 500);
    }
  };

  const uploadPhoto = () => {
    fileUpload.current?.click();
  };
  const deletePhoto = () => {
    setUploadedDoc();
    setDocName();
    setIsDocUploaded();
    setDisputeProofUrl('');
    setIsDocumentLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const selectedCategory = data?.disputeCategory?.value;
    const createPayload = {
      merchantId: currentMerchant,
      disputeType: selectedCategory,
      reasonForDisputeInDetails: data?.disputeDescription,
      files: disputeProofUrl,
      disputeAmount: data?.disputeAmount,
      transactionCharge: data?.transactionCharge,
      transactionDate: data?.transactionDate,
    };

    raiseDisputeComplaint(createPayload)
      .then((response) => {
        if (!response.status) {
          toast(response.message);
        } else {
          toast("Dispute Raise Successfully.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast(error);
        setIsLoading(false);
        toast("Something went wrong");
      });
  };

  return (
    <Wrapper>
      <HeadContainer>
        <p className="role-header-text">Create New Dispute </p>
      </HeadContainer>
      <p className="text-sm text-center text-gray-400 w-3/5 mx-auto">
        Please enter information to raise a dispute
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="disputeCategory"
          control={control}
          render={({ field }) => (
            <SelectInputWithIcon
              {...field}
              label={"Dispute Category"}
              //   isMulti
              placeholder={"Select Dispute Category"}
              //   className="basic-multi-select"
              options={disputeCategories?.map((data) => ({
                label: data.label,
                value: data.value,
              }))}
              error={errors?.role?.message}
            />
          )}
        />

        <Controller
          name="disputeDescription"
          control={control}
          render={({ field }) => (
            <TextAreaWithIcon
              {...field}
              label={"Description"}
              placeholder={
                "I created this dispute cause I was charged back a certain fee and i would like you resolve this"
              }
              error={errors?.disputeDescription?.message}
            />
          )}
        />
        <Controller
          name="disputeAmount"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              type={"number"}
              label={"Dispute Amount"}
              placeholder={"500"}
              pretext={"NGN"}
              error={errors?.disputeAmount?.message}
            />
          )}
        />
        <Controller
          name="transactionCharge"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              type={"number"}
              label={"Transaction Charge"}
              placeholder={"500"}
              pretext={"NGN"}
              error={errors?.transactionCharge?.message}
            />
          )}
        />
        <Controller
          name="transactionDate"
          control={control}
          render={({ field }) => (
            <TextInputWithIcon
              {...field}
              type={"date"}
              isRequired
              label={"Transaction Date"}
              error={errors?.transactionDate?.message}
            />
          )}
        />
        <UploadContainer>
          <PhotoContainer>
            <input
              type="file"
              accept=".jpg, .png, .jpeg"
              ref={fileUpload}
              onChange={handleFileUpload}
              className="file-input"
            />
            {isDocUploaded ? (
              <img src={checkIcon} alt="check-icon" className="upload-icon" />
            ) : (
              <img src={uploadIcon} alt="check-icon" className="upload-icon" />
            )}
            <p className="doc-name" style={{ marginLeft: "10px" }}>
              {isDocUploaded ? docName : "Upload Photo"}
              <br />
              {isDocUploaded ? (
                <span className="doc-format">
                  {formatShortDate(uploadedDate)} &#8226; &nbsp;
                  {changeBytesToMB(uploadedDoc?.size)}
                  {"MB"}
                </span>
              ) : (
                <span className="doc-format">"JPG, PNG format Max. 5MB"</span>
              )}
            </p>
          </PhotoContainer>
          {isDocUploaded ? (
            <button onClick={deletePhoto}>
              <img src={binIcon} alt="check-icon" className="upload-icon" />
            </button>
          ) : (
            <button
              type="button"
              onClick={uploadPhoto}
              className="flex shadow gap-2 text-white h-10 items-center text-sm px-3 rounded bg-primary-theme mx-3"
            >
              {isDocumentLoading ? <Loader /> : "Upload"}
            </button>
          )}
        </UploadContainer>
        <button
          disabled={!isDocUploaded}
          type="submit"
          className="h-12 my-4 py-3 w-full flex items-center justify-center bg-primary-theme text-sm text-white rounded"
        >
          {isLoading ? <Loader /> : " Create New Dispute"}
        </button>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-top: 1rem;
  }
`;

const Form = styled.form`
  padding: 1rem;
`;

const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
  .file-input {
    display: none;
  }
`;
const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .doc-name {
    font-weight: 700;
  }
  .doc-format {
    font-weight: 300;
  }
`;
